import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import Wrapper from '@/components/wrapper/Wrapper';
import { useLocation } from 'react-router-dom';
import parse from "html-react-parser";

import TestimonialCarousel from "@/components/testimonial-carousel/TestimonialCarousel";

const ServiceDetail = () => {
  const { state: service } = useLocation();
  const icon = `${import.meta.env.VITE_IMG_URL}/${service?.icon}`;
  console.log("service detail", service);

  return (
    <Wrapper>
      <div>
        <h1 className='section-heading'>Service Details</h1>
        <Table className="mt-4 mb-5">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg max-[560px]:text-xs">
              <TableHead>Icon</TableHead>
              <TableHead>Service Id</TableHead>
              <TableHead>Name</TableHead>
              {service.status && <TableHead>Status</TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow className="max-[560px]:text-xs">
              <TableCell className="font-medium">
                <div className="w-12 cursor-pointer h-12 max-[560px]:h-10 max-[560px]:w-10 rounded-full bg-slate-500">
                  <img className="w-full h-full rounded-full" src={icon} alt="" />
                </div>
              </TableCell>
              <TableCell className="font-medium">{service._id}</TableCell>
              <TableCell className="font-medium">{service.name}</TableCell>
              {service.status && <TableCell><span className={`px-2 py-[2px] rounded-md ${service.status === "pending" ? "bg-orange-400 text-white" : service.status === "quotation-sent" ? "bg-yellow-500 text-white" : service.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{service.status}</span></TableCell>}
            </TableRow>
          </TableBody>
        </Table>

        <p className="max-[560px]:text-sm">{service?.description && parse(service?.description)}</p>

        <TestimonialCarousel />

      </div >
    </Wrapper >
  )
}

export default ServiceDetail