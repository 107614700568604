import { BsTelephoneFill } from 'react-icons/bs'
import { CgSpinner } from 'react-icons/cg'
import PhoneInput from 'react-phone-input-2'
import { Input } from '../ui/input'

const PhoneComp = ({ phone, setPhone, message, onSignup, loading }) => {
    return (
        <>
            <div className="bg-[#1E429F] text-white  w-fit mx-auto p-4 rounded-full">
                <BsTelephoneFill size={30} />
            </div>
            <label
                htmlFor=""
                className="font-bold text-xl text-white max-[770px]:text-black text-center"
            >
                Enter your phone number
            </label>
            <Input className="max-[770px]:bg-[#F9FAFB]" type="number" placeholder="Enter Phone" value={phone} onChange={(e)=>setPhone(e.target.value)} />
            {message && <p className="text-red-500 text-center text-sm -my-2">{message}</p>}
            <button
                onClick={onSignup}
                className="bg-transparent max-[770px]:bg-[#1A56DB] border-2 border-white w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
            >
                {loading && (
                    <CgSpinner size={20} className="mt-1 animate-spin" />
                )}
                <span>Send code via SMS</span>
            </button>
        </>
    )
}

export default PhoneComp