import useGetApiReq from '@/hooks/useGetApiReq';
import { saveDoc } from '@/utils/download';
import React, { useEffect, useState } from 'react'

const SubSubFolder = ({subfolder,setFolderIdAndType,folderIdAndType,isDownload}) => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const [subFolderDocs, setSubFolderDocs] = useState([]);
    const [isSubFolderOpen, setIsSubFolderOpen] = useState(false);
    const [subFolderId, setSubFolderId] = useState("");

    const handleSubFolderOpen = (type, id, name) => {
        setFolderIdAndType({ folderType: type, fId: id, folderName: name });
        setIsSubFolderOpen(!isSubFolderOpen);
        setSubFolderId(id);
    }


    const { res: docRes, fetchData: fetchDocData, isLoading: isDocLoading } = useGetApiReq();

    const getDoc = (doc) => {
        if (isDownload) {
            fetchDocData(`/partner/get-doc?url=${doc}`);
        }
    }

    useEffect(() => {
        if (docRes?.status === 200 || docRes?.status === 201) {
            console.log("Document", docRes);
            saveDoc(docRes?.data?.preSignedUrl);
        }
    }, [docRes])

    const getSubFolders = () => {
        fetchSubFolderData(`/partner/get-subfolders-and-docs/${folderIdAndType.fId}?flag=sub-folder`);
    }

    useEffect(() => {
        if (folderIdAndType.fId === subfolder?._id) {
            getSubFolders();
        }
    }, [folderIdAndType])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("sub 'sub' FolderRes", subFolderRes);
            // setAllSubFolders(subFolderRes?.data?.subfolders);
            setSubFolderDocs(subFolderRes?.data?.updateDocs);
        }
    }, [subFolderRes])

    return (
        <div className='w-full m-2' key={subfolder?._id}>
            <div onClick={() => handleSubFolderOpen("sub-folder", subfolder?._id, subfolder?.name)} className={`bg-gray-200 cursor-pointer w-full ml-4 py-[2px] font-medium text-sm px-3 border ${subfolder?._id === folderIdAndType.fId &&"border-blue-500" }`}>📁 {subfolder?.name}</div>
            {isSubFolderOpen &&
                subFolderDocs?.map((doc) => (
                    <div className='w-full m-2' key={doc?._id}>
                        <div onClick={() => getDoc(doc?.doc)} className='bg-gray-200 cursor-pointer w-full ml-6 text-sm py-[2px] px-3'>📄 {doc?.doc}</div>
                    </div>
                ))}
        </div>
    )
}

export default SubSubFolder