import OtpComp from "@/components/otp-comp/OtpComp";
import { Input } from "@/components/ui/input";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { CgSpinner } from "react-icons/cg";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ForgotPasswordComp = ({ phone }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [info, setinfo] = useState({
        newPassword: "",
        confirmPassword: "",
        phone
    });
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowNewPassword, setIsShowNewPassword] = useState(false);

    const [otp, setOtp] = useState("");

    const navigate = useNavigate();

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setinfo({ ...info, [name]: value });
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!info.newPassword || !info.confirmPassword) {
            toast.error("All fields are required");
            return;
        }

        if (info.newPassword !== info.confirmPassword) {
            toast.error("New password and confirm password are not the same");
            return;
        }

        fetchData("/client/reset-password", { ...info, enteredOTP: otp });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setinfo({
                newPassword: "",
                confirmPassword: ""
            });
            setOtp("");
            navigate("/");
        }
    }, [res])

    return (
        <div className="p-4">
            <h1 className="text-center text-white max-[770px]:text-black font-medium text-2xl max-[770px]:text-xl">Reset Password</h1>
            <form onSubmit={handleOnSubmit} className="max-w-96 w-full mx-auto grid gap-2 py-4">
                <OtpComp
                    otp={otp}
                    setOtp={setOtp}
                />
                <div className="relative">
                    <label htmlFor="newPassword" className="text-white max-[770px]:text-black">New Password</label>
                    <Input type={isShowNewPassword ? "text" : "password"} onChange={handleOnChange} value={info.newPassword} placeholder="Enter New Password" className="mt-1 col-span-3" name="newPassword" id="newPassword" />
                    {isShowNewPassword ?
                        <FaEyeSlash onClick={() => setIsShowNewPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        : <FaEye onClick={() => setIsShowNewPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                    }
                </div>
                <div className="mt-3 relative">
                    <label htmlFor="confirmPassword" className="text-white max-[770px]:text-black">Confirm Password</label>
                    <Input type={isShowPassword ? "text" : "password"} onChange={handleOnChange} value={info.confirmPassword} placeholder="Enter Confirm Password" className="mt-1 col-span-3" name="confirmPassword" id="confirmPassword" />
                    {isShowPassword ?
                        <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                    }
                </div>

                <div className="flex justify-center items-center mt-6">
                    <button
                        className="bg-transparent border-2 max-[770px]:bg-[#1A56DB] border-white w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                    >
                        {isLoading && (
                            <CgSpinner size={20} className="mt-1 animate-spin" />
                        )}
                        <span>Submit</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ForgotPasswordComp