import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react'
import SubFolder from '../subfolder/SubFolder';
import { saveDoc } from '@/utils/download';

const Folder = ({ folder, folderIdAndType, setFolderIdAndType, isDownload = false }) => {
    const { res: subFolderRes, fetchData: fetchSubFolderData, isLoading: isSubFolderLoading } = useGetApiReq();

    const [isFolderOpen, setIsFolderOpen] = useState(false);


    const [allSubFolders, setAllSubFolders] = useState([]);
    const [updateDocs, setUpdateDocs] = useState([]);
    const [isAddSubFolderModalOpen, setIsAddSubFolderModalOpen] = useState(false);


    const handleFolderOpen = (type, id, name) => {
        setFolderIdAndType(() => ({ folderType: type, fId: id, folderName: name }));
        setIsFolderOpen(() => !isFolderOpen);
    }


    const getSubFolders = () => {
        fetchSubFolderData(`/partner/get-subfolders-and-docs/${folderIdAndType.fId}?flag=folder`);
    }

    useEffect(() => {
        if (folderIdAndType.fId === folder?._id) {
            getSubFolders();
        }
    }, [folderIdAndType])


    useEffect(() => {
        if (subFolderRes?.status === 200 || subFolderRes?.status === 201) {
            console.log("subFolderRes", subFolderRes);
            setAllSubFolders(subFolderRes?.data?.subfolders);
            setUpdateDocs(subFolderRes?.data?.updateDocs);
        }
    }, [subFolderRes])


    const { res: docRes, fetchData: fetchDocData, isLoading: isDocLoading } = useGetApiReq();

    const getDoc = (doc) => {
        if (isDownload) {
            fetchDocData(`/partner/get-doc?url=${doc}`);
        }
    }

    useEffect(() => {
        if (docRes?.status === 200 || docRes?.status === 201) {
            console.log("Document", docRes);
            saveDoc(docRes?.data?.preSignedUrl);
        }
    }, [docRes])


    return (
        <div className='w-full m-2'>
            <div className='w-full'>
                <div className={`bg-gray-200 cursor-pointer m-2 w-full font-semibold px-3 flex justify-between gap-2 py-[2px] border ${folder?._id === folderIdAndType.fId && "border-blue-500"}`}>
                    <div onClick={() => handleFolderOpen("folder", folder?._id, folder?.name)}>📁 {folder?.name}</div>
                </div>
                {isFolderOpen &&
                    <div>
                        {allSubFolders?.map((subfolder) => (
                            <SubFolder
                                key={subfolder?._id}
                                setFolderIdAndType={setFolderIdAndType}
                                subfolder={subfolder}
                                folderIdAndType={folderIdAndType}
                                isDownload={isDownload}
                            />
                        ))}
                        {updateDocs?.map((doc) => (
                            <div className='w-full m-2' key={doc?._id}>
                                <div onClick={() => getDoc(doc?.doc)} className='bg-gray-200 cursor-pointer w-full ml-4 text-sm py-[2px] px-3'>📄 {doc?.doc}</div>
                            </div>
                        ))}
                    </div>

                }
            </div>
        </div>
    )
}

export default Folder