import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Textarea } from "@/components/ui/textarea"
import usePostApiReq from '@/hooks/usePostApiReq'


const ApproveServiceModal = ({ isApproveServiceModalOpen, setIsApproveServiceModalOpen, id,getServiceRequests,serviceId,type }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    console.log("service id ",serviceId);

    const [approveServiceInfo, setApproveServiceInfo] = useState({
        price: "",
        note: "",
        clientId: id,
        serviceId,
        type
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setApproveServiceInfo({ ...approveServiceInfo, [name]: value });
    };


    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!approveServiceInfo.note || !approveServiceInfo.price) {
            toast.error("All fields are required");
            return;
        }

        fetchData("/admin/quote-price-message", { ...approveServiceInfo });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsApproveServiceModalOpen(false);
            getServiceRequests();
        }
    }, [res])


    return (
        <Dialog open={isApproveServiceModalOpen} onOpenChange={() => setIsApproveServiceModalOpen(!isApproveServiceModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Approve Service</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="price">
                            Price
                        </Label>
                        <Input type="number" onChange={handleOnChange} value={approveServiceInfo.price} name="price" id="price" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="note">
                            Note
                        </Label>
                        <Textarea onChange={handleOnChange} value={approveServiceInfo.note} name="note" id="note" className="col-span-3" />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Approve"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default ApproveServiceModal