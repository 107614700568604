import { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { auth } from "@/firebase.config";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useNavigate } from "react-router";
import OtpComp from "@/components/otp-comp/OtpComp";
import PhoneComp from "@/components/phone-comp/PhoneComp";
import ForgotPasswordComp from "@/client/components/forgot-password-comp/ForgotPasswordComp";
import useGetApiReq from "@/hooks/useGetApiReq";

const ForgotPassword = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [otp, setOtp] = useState("");
    const [phone, setPhone] = useState("");
    const [user, setUser] = useState(null);
    const [message, setMessage] = useState("");

    const navigate = useNavigate();

    function onSignup() {
        if (!phone) {
            setMessage("Enter phone");
            return;
        }

        fetchData(`/client/get-otp?phone=${phone}&flag=reset`)
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setUser(true);
            console.log("res", res);
        }
    }, [res])


    return (
        <section className="bg-[#1C64F2] max-[770px]:bg-white flex items-center justify-center h-screen">
            <div className="w-full">
                {/* <Toaster toastOptions={{ duration: 4000 }} /> */}
                {user ? (
                    <ForgotPasswordComp phone={phone} />
                ) : (
                    <div className="w-80 flex flex-col gap-4 mx-auto rounded-lg p-4">
                        <h1 className="text-center leading-normal text-white max-[770px]:text-black font-medium text-3xl max-[770px]:text-[22px] mb-6">
                            <span className="uppercase">Forgot Password</span>
                        </h1>

                        <PhoneComp
                            onSignup={onSignup}
                            loading={isLoading}
                            phone={phone}
                            setPhone={setPhone}
                            message={message}
                        />
                    </div>
                )}
            </div>
        </section>
    )
}

export default ForgotPassword