import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import usePostApiReq from '@/hooks/usePostApiReq'
import { CitySelect, StateSelect } from '@davzon/react-country-state-city/dist/cjs'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

const AddClientModal = ({ isAddClientModalOpen, setIsAddClientModalOpen, getClients }) => {
    const { res, fetchData, isLoading } = usePostApiReq()

    const [signupInfo, setSignupInfo] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        type: "partner",
    });

    const [addressInfo, setAddressInfo] = useState({
        state: "",
        city: "",
        pinCode: "",
        addressLine: "",
    });

    const [stateid, setstateid] = useState(0);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setSignupInfo({ ...signupInfo, [name]: value });
    };

    const handleAddressOnChange = (e) => {
        const { name, value } = e.target;
        setAddressInfo({ ...addressInfo, [name]: value });
    };

    console.log("signupInfo", signupInfo);
    console.log("address info", addressInfo);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!signupInfo.firstName || !signupInfo.lastName || !signupInfo.phone || !signupInfo.email || !addressInfo.state || !addressInfo.city || !addressInfo.pinCode || !addressInfo.addressLine) {
            toast.error("All fields are required");
            return;
        }

        if (signupInfo.phone.length !== 10) {
            toast.error("Phone length must be equal to 10 digits");
            return;
        }

        if (addressInfo.pinCode.length !== 6) {
            toast.error("PinCode length must be equal to 6 digits");
            return;
        }

        fetchData("/partner/add-client", { ...signupInfo, ...addressInfo })

    };

    console.log("res", res);
    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddClientModalOpen(false);
            getClients();
        }
    }, [res])


    return (
        <Dialog open={isAddClientModalOpen} onOpenChange={() => setIsAddClientModalOpen(!isAddClientModalOpen)}>
            <DialogContent className="sm:max-w-[525px] h-[80vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add Client</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="firstName">
                            First Name
                        </Label>
                        <Input placeholder="Enter First Name" onChange={handleOnChange} value={signupInfo.firstName} name="firstName" id="firstName" className="col-span-3" />
                    </div>
                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="lastName">
                            Last Name
                        </Label>
                        <Input placeholder="Enter Last Name" onChange={handleOnChange} value={signupInfo.lastName} name="lastName" id="lastName" className="col-span-3" />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="phone">
                            Phone
                        </Label>
                        <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={signupInfo.phone} name="phone" id="phone" className="col-span-3" />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="email">
                            Email
                        </Label>
                        <Input type="email" placeholder="Enter Email" onChange={handleOnChange} value={signupInfo.email} name="email" id="email" className="col-span-3" />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="state">
                            State
                        </Label>
                        {/* <Input placeholder="Enter State" onChange={handleOnChange} value={addressInfo.state} name="state" id="state" className="col-span-3" /> */}
                        <StateSelect
                            countryid={101}
                            onChange={(e) => {
                                setstateid(e.id);
                                setAddressInfo({ ...addressInfo, state: e.name })
                                console.log("state selected", e);
                            }}
                            placeHolder="Select State"
                        />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="city">
                            City
                        </Label>
                        {/* <Input placeholder="Enter City" onChange={handleOnChange} value={addressInfo.city} name="city" id="city" className="col-span-3" /> */}
                        <CitySelect
                            countryid={101}
                            stateid={stateid}
                            onChange={(e) => {
                                setAddressInfo({ ...addressInfo, city: e.name })
                                console.log("city selected", e);
                            }}
                            placeHolder="Select City"
                        />
                    </div>
                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="pinCode">
                            Pincode
                        </Label>
                        <Input type="number" placeholder="Enter Pincode" onChange={handleAddressOnChange} value={addressInfo.pinCode} name="pinCode" id="pinCode" className="col-span-3" />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="addressLine">
                            Address Line
                        </Label>
                        <Input placeholder="Enter Address Line" onChange={handleAddressOnChange} value={addressInfo.addressLine} name="addressLine" id="addressLine" className="col-span-3" />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddClientModal