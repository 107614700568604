import { openCloseSideBar } from "@/store/slices/sidebarSlice";
import { MdClose, MdMenu } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { useEffect, useState } from "react";
import useGetApiReq from "@/hooks/useGetApiReq";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LogoutAlert from "../logout-alert/LogoutAlert";
import client from "@/assets/client.avif"
import logo from "@/assets/corporate-rasta-logo.png"

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import NotificationComp from "../../client/components/notification-comp/NotificationComp";
import { FaEdit, FaHome } from "react-icons/fa";
import Profile from "@/client/components/profile/Profile";

const Header = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const [logoutAlertModalOpen, setLogoutAlertModalOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen } = useSelector((state) => state.sidebar);
  const { user } = useSelector((state) => state.user);


  const handleLogout = async () => {
    fetchData("/client/logout");
  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("my requests", res.data)
      toast.success(res?.data?.message);
      navigate("/");
    }
  }, [res]);

  const handleOpen = () => {
    dispatch(openCloseSideBar());
  };

  return (
    <>
      <div className="flex justify-between items-center p-4 px-10 bg-gradient-to-r from-[#3F83F8] to-[#254D92] text-white z-20 sticky top-0 max-[770px]:hidden">
        <div className="w-16 h-16 cursor-pointer" onClick={() => navigate("/client/dashboard")}>
          <img src={logo} alt="logo" className="w-full h-full" />
        </div>
        <div className="flex gap-3 items-center relative">
          <Profile isProfileOpen={isProfileOpen} setIsProfileOpen={setIsProfileOpen}>
            <div onClick={() => setIsProfileOpen(true)} className="flex cursor-pointer items-center gap-2 mr-4 text-nowrap">
              <Avatar>
                <AvatarImage src={client} />
                <AvatarFallback>Client</AvatarFallback>
              </Avatar>
              Hello, {user?.name?.first}
            </div>
          </Profile >
          <a className="w-full flex justify-between items-center" target="_blank" rel="noreferrer" href="https://corporateraastaconsulting.com">
            <span className="min-[770px]:hidden font-bold">Home</span>
            <FaHome size={24} cursor={"pointer"} />
          </a>

          <NotificationComp />

          <div className="flex gap-3">
            {/* {isOpen ? <MdClose onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />
              : <MdMenu onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />} */}
            <MdLogout size={25} cursor={"pointer"} onClick={() => setLogoutAlertModalOpen(true)} />
          </div>
        </div>
      </div >

      <div className="flex justify-between items-center p-4 py-2 bg-white text-black z-20 sticky top-0 min-[770px]:hidden">
        <div className="flex gap-3">
          {isOpen ? <MdClose onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] cursor-pointer" />
            : <MdMenu onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] cursor-pointer" />}
        </div>
        <div className="flex items-center gap-2 font-semibold">
          Hello, {user?.name?.first}
        </div>
        <div className="w-12 h-12 cursor-pointer" onClick={() => navigate("/client/dashboard")}>
          <img src={logo} alt="logo" className="w-full h-full" />
        </div>
      </div >

      <div className={`w-full min-[770px]:hidden h-[100vh] p-6 bg-white fixed top-0 left-0 z-20 flex flex-col justify-between ${!isOpen ? "-translate-x-full transition-all" : "translate-x-0 transition-all"}`}>
        <div>
          <div className="flex justify-between gap-3">
            <MdClose onClick={handleOpen} size={25} className="min-[770px]:hidden h-[100vh-100px] cursor-pointer" />
            <FaEdit size={25} />
          </div>
          <Profile>
            <div onClick={() => setIsProfileOpen(true)} className="flex cursor-pointer flex-col items-center justify-center gap-2 mt-5">
              <img src={client} alt="logo" className="w-20 h-20 rounded-full" />
              <span className="text-lg font-bold">{user?.name?.first}</span>
            </div>
          </Profile>
        </div>
        <div className="border-t-2 border-[#E5E7EB] pt-4 mb-28">
          <a className="w-full flex justify-between items-center mb-4" target="_blank" rel="noreferrer" href="https://corporateraastaconsulting.com">
            <span className="min-[770px]:hidden font-bold">Home</span>
            <FaHome size={24} cursor={"pointer"} />
          </a>
          <NotificationComp />
          <div className="flex justify-between items-center mt-4 font-bold">
            Logout
            <MdLogout size={25} cursor={"pointer"} onClick={() => setLogoutAlertModalOpen(true)} />
          </div>
        </div>
      </div>

      <LogoutAlert
        setLogoutAlertModalOpen={setLogoutAlertModalOpen}
        logoutAlertModalOpen={logoutAlertModalOpen}
        handleLogout={handleLogout}
      />

    </>
  );
};

export default Header;