import AdminWrapper from "@/admin/components/addmin-wrapper/AdminWrapper"
import DataNotFound from "@/components/data-not-found/DataNotFound"
import { FaEye } from "react-icons/fa"
import { useLocation, useNavigate } from "react-router-dom"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"

const AdminClients = () => {
    const { state: clients } = useLocation();
    const navigate = useNavigate();

    if (!clients) {
        navigate(-1);
    }

    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Clients</h1>
                </div>

                <Table className="">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Name</TableHead>
                            <TableHead>Phone</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Address</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {clients?.map((client) => (
                            <TableRow key={client?._id} className="">
                                <TableCell>{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
                                <TableCell>{client?.phone}</TableCell>
                                <TableCell>{client.email}</TableCell>
                                <TableCell>{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</TableCell>
                                <TableCell>
                                    <FaEye onClick={() => navigate(`/admin/clients/${client?._id}`)} size={24} cursor={"pointer"} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {clients.length === 0 &&
                    <DataNotFound name={"Clients"} />
                }

            </div>
        </AdminWrapper>
    )
}

export default AdminClients