import {
    AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger,
} from "@/components/ui/alert-dialog"
import { Button } from "@/components/ui/button"
import { FaCheckCircle } from 'react-icons/fa'


const SuccessModal = ({ isOpen, setIsOpen }) => {
    return (
        <AlertDialog className="max-w-72 w-full" open={isOpen} onOpenChange={() => setIsOpen(!isOpen)} >
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>
                        <FaCheckCircle size={80} className="mx-auto" color="green" />
                    </AlertDialogTitle>
                    <AlertDialogDescription className="text-lg text-center">
                        Service buy request sent.
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <div className="flex justify-center mt-4">
                    <Button onClick={()=> setIsOpen(false)}>Continue</Button>
                </div>
            </AlertDialogContent>
        </AlertDialog>

    )
}

export default SuccessModal