import CarouselComp from "@/components/carousel/CarouselComp";
import Spinner from "@/components/spinner/Spinner";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import usePostApiReq from "@/hooks/usePostApiReq";
import { saveUser } from "@/store/slices/userSlice";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Rectangle2 from "@/assets/Rectangle 2.png";
import partnerLogin from "@/assets/partner-login.png";
import { readCookie } from "@/utils/readCookie";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Login = () => {
    const { res, fetchData, isLoading } = usePostApiReq()

    const [loginInfo, setLoginInfo] = useState({
        email: "",
        password: "",
    });
    const [isShowPassword, setIsShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setLoginInfo({ ...loginInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!loginInfo.email || !loginInfo.password) {
            toast.error("All fields are required");
            return;
        }

        fetchData("/partner/login", { ...loginInfo });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            navigate("/partner/dashboard");
            dispatch(saveUser({ user: res?.data?.user }));
        }
    }, [res])

    useEffect(() => {
        const user = readCookie("user");

        if (user && user?._id) {
            navigate("/partner/dashboard");
        }
    }, [])


    return (
        <section className="login-signup">
            <div className="login-signup-left">
                <h1 className="signup-login-heading">
                    Partner Login
                </h1>
                <form onSubmit={handleOnSubmit} className="form" action="">
                    <div className="grid grid-cols-1 items-center gap-4">
                        <Label htmlFor="email">
                            Email
                        </Label>
                        <Input type="email" placeholder="Enter Email" onChange={handleOnChange} value={loginInfo.email} name="email" id="email" className="col-span-3" />
                    </div>

                    <div className="grid grid-cols-1 items-center gap-4 relative">
                        <Label htmlFor="password">
                            Password
                        </Label>
                        <Input type={isShowPassword ? "text" : "password"} placeholder="Enter Password" onChange={handleOnChange} value={loginInfo.password} name="password" id="password" className="col-span-3" />
                        {isShowPassword ?
                            <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        }
                    </div>

                    <div className="flex justify-end">
                        <Link to={"/partner/forgot-password"} className="font-bold max-[435px]:text-sm">Forgot Password?</Link>
                    </div>

                    <div className="btn-container">
                        <button className="btn">{isLoading ? <Spinner /> : "Login"}</button>
                    </div>
                    <div className="flex gap-3 mt-3 justify-center">
                        <button type="button" onClick={() => navigate("/admin/login")} className="text-blue-500">Admin Login</button>
                        <button type="button" onClick={() => navigate("/")} className="text-blue-500">Client Login</button>
                    </div>
                </form>
            </div>
            <div className="login-signup-right">
                <div className="login-signup-right-inner">
                    <img className="absolute z-10 w-[60%] right-[18%] bottom-[18%]" src={partnerLogin} alt="partnerLogin" />
                    <img className="absolute w-[70%] bottom-0 right-0" src={Rectangle2} alt="Rectangle2" />
                </div>
            </div>
        </section>
    );
};

export default Login;