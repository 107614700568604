import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'


const AddPriceModal = ({ isAddPriceModalOpen, setIsAddPriceModalOpen, id, getChats, serviceId }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [priceInfo, setPriceInfo] = useState({
        price: "",
        note: "",
        clientId: id,
        serviceId,
        type:"self"
    })

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setPriceInfo({ ...priceInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!priceInfo.price || !priceInfo.note) {
            toast.error("All fields are required");
            return;
        }


        fetchData("/admin/quote-price-message", { ...priceInfo });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddPriceModalOpen(false)
            getChats();
        }
    }, [res])


    return (
        <Dialog open={isAddPriceModalOpen} onOpenChange={() => setIsAddPriceModalOpen(!isAddPriceModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Add Price</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="price">
                            Price
                        </Label>
                        <Input placeholder="Enter Price" onChange={handleOnChange} value={priceInfo.price} name="price" id="price" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="note">
                            Note
                        </Label>
                        <Textarea placeholder="Enter Note" onChange={handleOnChange} value={priceInfo.note} name="note" id="note" className="col-span-3" />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddPriceModal