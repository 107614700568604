import { openCloseSideBar } from "@/store/slices/sidebarSlice";
import { MdClose, MdMenu } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { useEffect, useState } from "react";
import useGetApiReq from "@/hooks/useGetApiReq";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import LogoutAlert from "@/components/logout-alert/LogoutAlert";
import NotificationComp from "../notification-comp/NotificationComp";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import partner from "@/assets/partner.avif"
import logo from "@/assets/corporate-rasta-logo.png"

const Header = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const [logoutAlertModalOpen, setLogoutAlertModalOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isOpen } = useSelector((state) => state.sidebar);
  const { user } = useSelector((state) => state.user);
 


  const handleLogout = async () => {
    fetchData("/partner/logout");
  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      toast.success(res?.data?.message);
      navigate("/partner/login");
    }
  }, [res]);

  const handleOpen = () => {
    dispatch(openCloseSideBar());
  };

  return (
    <>
      <div className="flex justify-between items-center p-4 px-10 bg-gradient-to-r from-[#3F83F8] to-[#254D92] text-white z-20 sticky top-0">
      <div className="w-16 h-16 cursor-pointer" onClick={()=> navigate("/partner/dashboard")}>
          <img src={logo} alt="logo" className="w-full h-full" />
        </div>

        <div className="flex gap-3 items-center">
          <div className="flex items-center gap-2 mr-4">
            <Avatar>
              <AvatarImage src={partner} />
              <AvatarFallback>Partner</AvatarFallback>
            </Avatar>
            Hello, {user?.name?.first}
          </div>

          <NotificationComp />

          {isOpen ? <MdClose onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />
            : <MdMenu onClick={handleOpen} size={25} className="min-[1000px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />
          }

          <MdLogout size={25} cursor={"pointer"} onClick={() => setLogoutAlertModalOpen(true)} />
        </div>
      </div>

      <LogoutAlert
        setLogoutAlertModalOpen={setLogoutAlertModalOpen}
        logoutAlertModalOpen={logoutAlertModalOpen}
        handleLogout={handleLogout}
      />
    </>
  );
};

export default Header;