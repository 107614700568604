import DataNotFound from "@/components/data-not-found/DataNotFound"
import Spinner from "@/components/spinner/Spinner"
import useGetApiReq from "@/hooks/useGetApiReq";
import { useEffect, useState } from "react";
import ChatItemComp from "../chatItem/ChatItem";

const Chatlist = ({ setSelected, selected, setServiceId,chatList,isLoading,setType }) => {

   

    const handleClick = (event) => {
        event.preventDefault();
        const clientId = event.currentTarget.getAttribute("data-clientid");
        const serviceId = event.currentTarget.getAttribute("data-serviceid");
        const type = event.currentTarget.getAttribute("data-type");
        setSelected(clientId);
        setServiceId(serviceId);
        setType(type);
    }


    return (
        <div className="w-96 border-2 h-[calc(100vh-135px)] sticky top-[72px] overflow-y-auto">
            {chatList.length === 0 && isLoading &&
                <Spinner size={30} />
            }

            {chatList.length === 0 && !isLoading &&
                <DataNotFound name={"Chats"} />
            }
            {chatList.length > 0 &&
                chatList.map((item, i) => (
                    <ChatItemComp
                        key={i}
                        item={item}
                        selected={selected}
                        handleClick={handleClick}
                        i={i}
                    />
                ))}
        </div>
    )
}

export default Chatlist