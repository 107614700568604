import DocumentView from '@/client/components/document-view/DocumentView'
import { TableCell, TableRow } from '@/components/ui/table'
import useGetApiReq from '@/hooks/useGetApiReq'
import { saveDoc } from '@/utils/download'
import axios from 'axios'
import { format } from 'date-fns'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { FaArrowAltCircleDown, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'

const ServiceUpdateComp = ({ update }) => {
    const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);
    console.log("update", update);

    const { res, fetchData, isLoading } = useGetApiReq();

    const getDoc = () => {
        fetchData(`/partner/get-doc?url=${update?.doc}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("Document", res);
            saveDoc(res?.data?.preSignedUrl);
        }
    }, [res])

    return (
        <>
            <TableRow>
                <TableCell>{`${update?.clientId?.name?.first} ${update?.clientId?.name?.last}`}</TableCell>
                {/* <TableCell className="w-40">
                    <span className={`px-2 py-[2px] rounded-md ${update?.status === "pending" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{update?.status}</span>
                </TableCell> */}
                <TableCell>{update?.createdAt && format(new Date(update?.createdAt), "dd/MM/yyyy")}</TableCell>
                <TableCell>
                    <button disabled={update?.doc ? false : true} className="cursor-pointer disabled:cursor-not-allowed" onClick={() => getDoc()}>
                    <FaArrowAltCircleDown size={22} cursor={"pointer"} />
                    </button>
                </TableCell>
                <TableCell>{update?.description && parse(update?.description)}</TableCell>
                {/* <TableCell className="flex gap-3 items-center">
                    <FaEdit size={22} cursor={"pointer"} />
                    <MdDelete size={22} cursor={"pointer"} />
                </TableCell> */}
            </TableRow>

            <DocumentView
                document={update?.doc}
                setIsDocumentViewModalOpen={setIsDocumentViewModalOpen}
                isDocumentViewModalOpen={isDocumentViewModalOpen}
            />
        </>
    )
}

export default ServiceUpdateComp