import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import Partner from '@/admin/components/partner/Partner'
import ServiceRequestComp from '@/admin/components/service-request-comp/ServiceRequestComp'
import img from "@/assets/admin/admin.png"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import useGetApiReq from '@/hooks/useGetApiReq'
import Spinner from '@/components/spinner/Spinner'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import ReactPaginate from 'react-paginate'
import { Button } from '@/components/ui/button'
import RequestService from '@/admin/components/request-service/RequestService'
import parse from "html-react-parser"
import DeleteClientModal from '@/admin/components/delete-clientModal/DeleteClientModal'
import Rectangle17 from "@/assets/Rectangle 17.png";

const ClientDetails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: res2, fetchData: fetchData2, isLoading: isLoading2 } = useGetApiReq();

    const navigate = useNavigate();
    const params = useParams();

    const [services, setServices] = useState([]);
    const [requests, setRequests] = useState([]);
    const [basicDetails, setBasicDetails] = useState("");
    const [status, setStatus] = useState("all");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isRequestServiceModalOpen, setIsRequestServiceModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const limit = import.meta.env.VITE_LIMIT;


    const getClientDetail = useCallback(async () => {
        fetchData(`/admin/get-client-details/${params?.clientId}?page=${page}&limit=${limit}`);
    }, [params.clientId, fetchData, page]);

    useEffect(() => {
        getClientDetail();
    }, [page]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("client details", res?.data);
            setBasicDetails(res?.data?.clientInfo?.basicDetails);
            setServices(res?.data?.clientInfo?.clientServices);
            setRequests(res?.data?.clientInfo?.requests);
            const count = Math.ceil(res?.data?.clientInfo.reqCount / limit)
            setPageCount(count);
        }
    }, [res]);


    const handleStatusChange = async () => {
        setRequests([]);
        if (status === "all") {
            getClientDetail();
        }
        else {
            fetchData2(`/admin/get-client-req-by-status/${params?.clientId}?status=${status}&page=${page}&limit=${limit}`);
        }
    };

    useEffect(() => {
        handleStatusChange();
    }, [status, page, params.clientId]);

    useEffect(() => {
        if (res2?.status === 200 || res2?.status === 201) {
            console.log("res2", res2);
            setRequests(res2?.data?.requests);
            const count = Math.ceil(res2?.data?.count / limit)
            setPageCount(count);
        }
    }, [res2])


    return (
        <AdminWrapper>
            <>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Basic Details</h1>
                    <div className="flex gap-2 items-center">
                        <Button onClick={() => navigate(`/admin/clients/${params?.clientId}/drive`)}>Drive</Button>
                        <Button onClick={() => setIsDeleteModalOpen(true)}>Delete Client</Button>
                        <Button onClick={() => setIsRequestServiceModalOpen(true)}>Add Service</Button>
                    </div>
                </div>
                <div className='flex justify-between flex-wrap items-center gap-5 mt-4'>
                    <div className="border-2 rounded-md p-4 flex items-start bg-[#F3F4F6] gap-5">
                        <div className="flex cursor-pointer flex-col gap-2">
                            <div className="w-32 h-36 rounded-lg">
                                <img className="w-full h-full" src={Rectangle17} alt="Rectangle17" />
                            </div>
                            <h3 className="text-2xl text-[#374151] text-center font-semibold">{basicDetails?.name?.first}</h3>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Full Name</p>
                                <p>{`${basicDetails?.name?.first} ${basicDetails?.name?.last}`}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Phone No</p>
                                <p>{basicDetails?.phone || "-"}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Email</p>
                                <p>{basicDetails?.email || "-"}</p>
                            </div>
                        </div>
                        <div className="flex flex-col gap-2">
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Status</p>
                                <p>
                                    <span className={`px-2 py-[2px] rounded-md ${basicDetails?.status ? "bg-green-400 text-white" : "bg-red-500 text-white"}`}>{basicDetails.status ? "Active" : "Blocked"}</span>
                                </p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Type</p>
                                <p>{basicDetails?.type || "-"}</p>
                            </div>
                            <div className="flex flex-col">
                                <p className="text-[#6B7280] font-bold">Address</p>
                                <p className="max-w-[200px]">{`${basicDetails?.address?.addressLine || "-"}, ${basicDetails?.address?.city || "-"}, ${basicDetails?.address?.state || "-"}, ${basicDetails?.address?.pinCode || "-"}`}</p>
                            </div>
                        </div>
                    </div>

                    {basicDetails.type === "partner" &&
                        <div>
                            <h2 className='text-lg font-medium mb-2'>Partner Details</h2>
                            <Partner partner={basicDetails.partnerId} />
                        </div>}
                </div>


                <div className='flex gap-x-20 justify-between items-center mb-3 mt-14 gap-3'>
                    <div className='flex gap-20'>
                        <h2 className="text-xl font-semibold">Requests</h2>
                        <Select onValueChange={(value) => setStatus(value)} value={status}>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="all">All</SelectItem>
                                <SelectItem value="pending">Pending</SelectItem>
                                <SelectItem value="accepted">Accepted</SelectItem>
                                <SelectItem value="quotation-sent">Quotation Sent</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    {requests.length > 0 &&
                        <button onClick={() => navigate(`/admin/clients/${params?.clientId}/requests`, { state: requests })} className='bg-[#1A56DB] hover:bg-[#104ac6] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }

                </div>

                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Service Name</TableHead>
                            <TableHead>Service Cost</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {requests?.map((request, index) => {
                            if (index <= 4) {
                                return (
                                    <ServiceRequestComp
                                        key={request._id}
                                        serviceRequest={request}
                                        getServiceRequests={getClientDetail}
                                    />
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {requests.length === 0 && (isLoading || isLoading2) &&
                    <Spinner size={30} />
                }

                {requests.length === 0 && !isLoading && !isLoading2 &&
                    <DataNotFound name={"Requests"} />
                }

                {pageCount > 0 &&
                    <div className="mt-10 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}


                <div className='flex justify-between items-center gap-3 mb-3 mt-14'>
                    <h2 className="text-xl font-semibold">Purchased Services</h2>
                    {services.length > 0 &&
                        <button onClick={() => navigate(`/admin/clients/${params?.clientId}/purchased-services`, { state: services })} className='bg-[#1A56DB] hover:bg-[#104ac6] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>

                <Table className="mt-5">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Category</TableHead>
                            <TableHead className="w-40">Payment Status</TableHead>
                            <TableHead>Description</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {services?.map((service, index) => {
                            if (index <= 4) {
                                return (
                                    <TableRow key={service?._id}>
                                        <TableCell>
                                            <div onClick={() => navigate(`/admin/clients/${params.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                                                <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} alt="service" />
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <h3 onClick={() => navigate(`/admin/clients/${params.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="text-lg cursor-pointer font-semibold">{service?.serviceId?.name}</h3>
                                        </TableCell>
                                        <TableCell>{service?.serviceId?.categoryId?.name}</TableCell>
                                        <TableCell className="w-40 text-center">
                                            <span className={`px-4 py-[2px] rounded-md ${service?.paymentStatus === "due" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{service?.paymentStatus}</span>
                                        </TableCell>
                                        <TableCell className="w-80 line-clamp-2">{service?.serviceId?.description && parse(service?.serviceId?.description)}</TableCell>
                                    </TableRow>
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {services.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {services.length === 0 && !isLoading &&
                    <DataNotFound name={"Services"} />
                }


                {isRequestServiceModalOpen &&
                    <RequestService
                        isRequestServiceModalOpen={isRequestServiceModalOpen}
                        setIsRequestServiceModalOpen={setIsRequestServiceModalOpen}
                        clientId={basicDetails?._id}
                        getClientDetail={getClientDetail}
                    />}

                {isDeleteModalOpen &&
                    <DeleteClientModal
                        status={basicDetails?.status}
                        isDeleteModalOpen={isDeleteModalOpen}
                        setIsDeleteModalOpen={setIsDeleteModalOpen}
                        getClientDetail={getClientDetail}
                    />
                }
            </>
        </AdminWrapper>
    )
}

export default ClientDetails