// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyC94tebEbFu14QOUXbVMdaZjXWwbxCV7hg",
    authDomain: "corporate-rasta.firebaseapp.com",
    projectId: "corporate-rasta",
    storageBucket: "corporate-rasta.appspot.com",
    messagingSenderId: "92247147190",
    appId: "1:92247147190:web:3887cf0a60e067efbd9c17"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);