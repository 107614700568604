import Category from '@/client/components/category/Category';
import Service from '@/client/components/service/Service'
import SuccessModal from '@/client/components/successmodal/SuccessModal';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import Spinner from '@/components/spinner/Spinner';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import Wrapper from '@/components/wrapper/Wrapper'
import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react'
import { IoIosSearch } from 'react-icons/io';
import ReactPaginate from 'react-paginate';

const Dashboard = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [allCategories, setAllCategories] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const limit = import.meta.env.VITE_LIMIT;

    const getCategories = async () => {
        fetchData(`/client/get-categories?page=${page}&limit=${limit}&search=${searchInput}`);
    }

    useEffect(() => {
        getCategories();
    }, [page,searchInput])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("category", res?.data);
            setAllCategories(res?.data?.categories);
            const count = Math.ceil(res?.data?.count / limit)
            setPageCount(count);
        }
    }, [res])

    return (
        <Wrapper>
            <div>
                <div className='section-heading-container'>
                    <h1 className='section-heading'>Categories</h1>
                    <div className='flex items-center max-[560px]:w-full'>
                        <IoIosSearch className='-mr-6 z-10' />
                        <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Category" className="pl-8 max-[560px]:w-full" />
                    </div>
                </div>
                {allCategories.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allCategories.length === 0 && !isLoading &&
                    <DataNotFound name={"Categories"} />
                }
                <div className='grid grid-cols-2 md:grid-cols-4 gap-6 mt-5'>
                    {allCategories?.map((category) => {
                        return (
                            <Category
                                key={category._id}
                                category={category}
                            />
                        )
                    })}
                </div>
                {pageCount > 0 &&
                    <div className="mb-2 mt-10">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div>
        </Wrapper>
    )
}

export default Dashboard