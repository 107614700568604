import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const SmsModal = ({ setIsSmsModalOpen, isSmsModalOpen, clients }) => {
  const { res, fetchData, isLoading } = usePostApiReq();

  const [message, setMessage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
  };

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!message) {
      toast.error("All fields are required");
      return;
    }

    // fetchData("/admin/request-service", { message, clients });

  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsSmsModalOpen(false);
    }
  }, [res])


  return (
    <Dialog open={isSmsModalOpen} onOpenChange={() => setIsSmsModalOpen(!isSmsModalOpen)}>
      <DialogContent className="sm:max-w-[550px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Send SMS</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
          <div className="input-container">
            <Label htmlFor="message">
              Message
            </Label>
            <Textarea placeholder="Enter Message" onChange={handleOnChange} value={message} name="message" id="message" className="col-span-3" />
          </div>
          <DialogFooter>
            <Button type="submit">Send</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default SmsModal