import { axiosInstance } from "@/utils/axiosInstance";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";

const initialState = {
    flag: JSON.parse(localStorage.getItem("chat-flag")),
};

export const isAnyActiveChat = createAsyncThunk("/chat/is-any-active-chat", async () => {
    try {
        const { data } = await axiosInstance.get("/client/is-any-active-chat");
        return data;
    } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message);
    }


});

const chatSlice = createSlice({
    name: "chat",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(isAnyActiveChat.fulfilled, (state, action) => {
            state.flag = action.payload.flag;
            localStorage.setItem("chat-flag", JSON.stringify(action.payload.flag));
        });
    },
});

export const { handleChatListModal } = chatSlice.actions;
export default chatSlice.reducer;