import OtpInput from "otp-input-react";
import { BsFillShieldLockFill } from 'react-icons/bs'
import { CgSpinner } from 'react-icons/cg'

const OtpComp = ({ otp, setOtp }) => {
    return (
        <>
            <div className="bg-[#1E429F] text-white w-fit mx-auto p-4 rounded-full">
                <BsFillShieldLockFill size={30} />
            </div>
            <label
                htmlFor="otp"
                className="text-white max-[770px]:text-black text-left"
            >
                Enter your OTP
            </label>
            <OtpInput
                value={otp}
                onChange={setOtp}
                OTPLength={6}
                otpType="number"
                disabled={false}
                autoFocus
                className="opt-container"
            ></OtpInput>
        </>
    )
}

export default OtpComp