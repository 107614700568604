import { Link, useLocation } from 'react-router-dom'

const NotFound = () => {
    const {pathname} = useLocation();

  return (
    <div className='flex justify-center flex-col items-center h-screen'>
        <p>{pathname} route is not fonund </p>
        <Link className='text-white ml-1 bg-blue-500 rounded-md px-2 mt-2' to={-1}>Back</Link>
    </div>
  )
}

export default NotFound