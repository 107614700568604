import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

import useDeleteApiReq from "@/hooks/useDeleteApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const DeleteClientModal = ({ setIsDeleteModalOpen, isDeleteModalOpen, getClientDetail, status }) => {
    const { res, fetchData, isLoading } = useDeleteApiReq();

    const [option, setOption] = useState("block");
    const params = useParams();
    const navigate = useNavigate();

    const handleDelete = async (option) => {
        setOption(option);
        fetchData(`/admin/client/${params?.clientId}?option=${option}`);
    }

    console.log("status", status);
    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            toast.success(res?.data?.message);
            setIsDeleteModalOpen(false);
            if (option === "block") {
                getClientDetail();
            }
            else {
                navigate(-1)
            }
        }
    }, [res]);

    return (
        <Dialog open={isDeleteModalOpen} onOpenChange={() => setIsDeleteModalOpen(!isDeleteModalOpen)}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Delete Client</DialogTitle>
                    <DialogDescription>
                        This will  permanently delete the client.
                    </DialogDescription>
                </DialogHeader>
                <div className="flex gap-2 items-center mt-4 justify-end">
                    {status && <Button variant="destructive" onClick={() => handleDelete("block")}>Block</Button>}
                    <Button onClick={() => handleDelete("delete")}>Delete</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default DeleteClientModal