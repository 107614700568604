import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { useLocation, useNavigate } from 'react-router-dom';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import PartnerWrapper from "@/partner/components/wrapper/Wrapper";
import ServiceUpdateComp from "@/partner/components/service-update-comp/ServiceUpdateComp";

const Updates = () => {
    const { state: serviceUpdates } = useLocation();
    const navigate = useNavigate();

    if (!serviceUpdates) {
        navigate(-1);
    }

    return (
        <PartnerWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Updates</h1>
                </div>

                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            {/* <TableHead>Client Name</TableHead> */}
                            <TableHead>Status</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Document</TableHead>
                            <TableHead>Description</TableHead>
                            {/* <TableHead>Actions</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>

                        {serviceUpdates?.length === 0 &&
                            <DataNotFound name={"Service Updates"} />
                        }

                        {serviceUpdates?.map((update) => (
                            <ServiceUpdateComp
                                key={update?._id}
                                update={update}
                            />
                        ))}
                    </TableBody>
                </Table>
            </div>
        </PartnerWrapper>
    )
}

export default Updates