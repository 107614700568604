import AddServiceUpdateModal from '@/admin/components/add-serviceUpdateModal/AddServiceUpdateModal'
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import useGetApiReq from '@/hooks/useGetApiReq'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import parse from 'html-react-parser'
import ServiceUpdateComp from '@/admin/components/service-update-comp/ServiceUpdateComp'


const ServiceUpdate = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [allServiceUpdates, setAllServiceUpdates] = useState([]);
    const [isAddServiceUpdateModalOpen, setIsAddServiceUpdateModalOpen] = useState(false);

    const getServiceUpdates = async () => {
        fetchData("/admin/get-all-service-updates");
    }

    useEffect(() => {
        getServiceUpdates();
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllServiceUpdates(res?.data?.updates);
        }
    }, [res])

    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Service Update</h1>
                    <Button onClick={() => setIsAddServiceUpdateModalOpen(true)}>Add Service Update</Button>
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Client Name</TableHead>
                            {/* <TableHead>Status</TableHead> */}
                            <TableHead>Date</TableHead>
                            <TableHead>Document</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allServiceUpdates?.map((update) => (
                            <ServiceUpdateComp
                                key={update?._id}
                                update={update}
                                getServiceUpdates={getServiceUpdates}
                            />
                        ))}
                    </TableBody>
                </Table>

                {allServiceUpdates.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allServiceUpdates.length === 0 && !isLoading &&
                    <DataNotFound name={"Service Updates"} />
                }

                {isAddServiceUpdateModalOpen &&
                    <AddServiceUpdateModal
                        isAddServiceUpdateModalOpen={isAddServiceUpdateModalOpen}
                        setIsAddServiceUpdateModalOpen={setIsAddServiceUpdateModalOpen}
                        getServiceUpdates={getServiceUpdates}
                    />}

            </div>
        </AdminWrapper>
    )
}

export default ServiceUpdate