import AddSubAdminModal from '@/admin/components/add-subAdminModal/AddSubAdminModal';
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import { Button } from '@/components/ui/button'
import { useEffect, useState } from 'react'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import useGetApiReq from '@/hooks/useGetApiReq';
import Admin from '@/admin/components/admin/Admin';
import Spinner from '@/components/spinner/Spinner';
import DataNotFound from '@/components/data-not-found/DataNotFound';

const SubAdmins = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddSubAdminModalOpen, setIsAddSubAdminModalOpen] = useState(false);
    const [allSubAdmins, setAllSubAdmins] = useState([]);

    const getSubAdmins = async () => {
        fetchData("/admin/all-subadmins");
    };

    useEffect(() => {
        getSubAdmins();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("sub admins", res.data)
            setAllSubAdmins(res?.data?.admins);
        }
    }, [res]);

    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Sub Admins</h1>
                    <Button onClick={() => setIsAddSubAdminModalOpen(true)}>Add SubAdmin</Button>
                </div>

                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Name</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Phone</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allSubAdmins?.map((admin) => (
                            <Admin
                                key={admin?._id}
                                admin={admin}
                                getSubAdmins={getSubAdmins}
                            />
                        ))}
                    </TableBody>
                </Table>

                {allSubAdmins.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allSubAdmins.length === 0 && !isLoading &&
                    <DataNotFound name={"Admins/SubAdmins"} />
                }

                {isAddSubAdminModalOpen &&
                    <AddSubAdminModal
                        isAddSubAdminModalOpen={isAddSubAdminModalOpen}
                        setIsAddSubAdminModalOpen={setIsAddSubAdminModalOpen}
                        getSubAdmins={getSubAdmins}
                    />}

            </div>
        </AdminWrapper>
    )
}

export default SubAdmins