import { Input } from '@/components/ui/input';
import { useState } from 'react';
import { IoIosArrowDown } from "react-icons/io";
import { MdKeyboardArrowDown } from 'react-icons/md';

const ClientSelectSearchBox = ({ clients, setServiceUpdateInfo, name }) => {
    const [searchTerm, setSearchTerm] = useState(name || '');
    const [isOpen, setIsOpen] = useState(false);

    const filteredOptions = clients.filter((client) =>
        client?.fullName?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleInputChange = (e) => {
        setSearchTerm(e.target.value);
        setIsOpen(true);
    };

    const handleOptionClick = (client) => {
        setSearchTerm(client?.name?.first);
        setServiceUpdateInfo((prev) => ({ ...prev, clientId: client?._id }));
        setIsOpen(false);
    };

    return (
        <div className="w-full relative">
            <div className='relative flex justify-between'>
                <Input
                    type="text"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Search..."
                />
                <MdKeyboardArrowDown onClick={() => setIsOpen(!isOpen)} className="absolute top-2 right-2 text-gray-500" size={22} />
            </div>
            {isOpen && (
                <div className="mt-1 w-full bg-white border border-gray-300 max-h-[200px] overflow-y-auto shadow-md">
                    {filteredOptions.map((client, index) => (
                        <div
                            key={index}
                            className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => handleOptionClick(client)}
                        >
                            {client?.fullName}
                        </div>
                    ))}
                    {filteredOptions.length === 0 && <p className='px-2 py-1'>No clients available.</p>}
                </div>
            )}
        </div>
    );
};

export default ClientSelectSearchBox;
