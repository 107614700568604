import { useState } from "react"
import { Dialog } from "@/components/ui/dialog";
import { useNavigate } from "react-router-dom";
import { TableCell, TableRow } from "@/components/ui/table";
import parse from "html-react-parser"
import { FaEdit } from "react-icons/fa";
import AddServiceModal from "../add-serviceModal/AddServiceModal";

const Service = ({ service, getServices, edit = true, showDesc = true }) => {
    const icon = `${import.meta.env.VITE_IMG_URL}/${service?.icon}`;
    const navigate = useNavigate();
    const [isUpdateServiceModalOpen, setIsUpdateServiceModalOpen] = useState(false);

    return (
        <>
            <TableRow className="">
                <TableCell>
                    <div onClick={() => navigate(`/admin/categories/${service?._id}`, { state: service })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                        <img className="w-full h-full rounded-full" src={icon} alt="" />
                    </div>
                </TableCell>
                <TableCell>
                    <h3 onClick={() => navigate(`/admin/categories/${service?._id}`, { state: service })} className="text-lg cursor-pointer font-semibold">{service?.name}</h3>
                </TableCell>
                <TableCell>{service?.categoryId?.name}</TableCell>
                {edit &&
                    <TableCell>
                        <FaEdit onClick={() => setIsUpdateServiceModalOpen(true)} className='text-xl cursor-pointer' />
                    </TableCell>
                }
                {showDesc && <TableCell className="w-80 line-clamp-2">{service?.description && parse(service?.description)}</TableCell>}
            </TableRow>

            {isUpdateServiceModalOpen &&
                <AddServiceModal
                    isAddServiceModalOpen={isUpdateServiceModalOpen}
                    setIsAddServiceModalOpen={setIsUpdateServiceModalOpen}
                    getServices={getServices}
                    service={service}
                />
            }
        </>
    )
}

export default Service