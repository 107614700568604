import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import useGetApiReq from '@/hooks/useGetApiReq'
import DocumentComp from '@/partner/components/document/DocumentComp'
import ServiceUpdateComp from '@/partner/components/service-update-comp/ServiceUpdateComp'
import PartnerWrapper from '@/partner/components/wrapper/Wrapper'
import { useEffect, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { useNavigate, useParams } from 'react-router-dom'
import AddDocumentModal from '@/partner/components/add-documentModal/AddDocumentModal'
import parse from "html-react-parser"

const ClientServiceDetails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [client, setClient] = useState("");
    const [service, setService] = useState("");
    const [updates, setUpdates] = useState([]);
    const [documents, setDocuments] = useState([]);
    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);

    const params = useParams();
    const icon = `${import.meta.env.VITE_IMG_URL}/${service?.icon}`;

    const getServiceDetail = async () => {
        fetchData(`/partner/purchased-service-details/${params?.serviceId}`);
    };

    useEffect(() => {
        getServiceDetail();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("service detail", res?.data)
            setService(res?.data?.info?.serviceInfo);
            setClient(res?.data?.info?.clientInfo);
            setUpdates(res?.data?.info?.updates);
            setDocuments(res?.data?.info?.documents);
        }
    }, [res]);

    const navigate = useNavigate();

    return (
        <PartnerWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Service Details</h1>
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Category</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                    <TableRow className="">
                            <TableCell>
                                <div className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                                    <img className="w-full h-full rounded-full" src={icon} alt="" />
                                </div>
                            </TableCell>
                            <TableCell>
                                <h3 className="text-lg cursor-pointer font-semibold">{service?.name}</h3>
                            </TableCell>
                            <TableCell>{service?.categoryId?.name}</TableCell>
                        </TableRow>
                        {/* <Service service={service} /> */}
                    </TableBody>
                </Table>
                <p className="max-[560px]:text-sm">{service?.description && parse(service?.description)}</p>


                <h2 className="text-xl font-semibold mb-3 mt-14">Client Details</h2>

                <Table className="">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Name</TableHead>
                            <TableHead>Phone</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Address</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
                            <TableCell>{client?.phone}</TableCell>
                            <TableCell>{client.email}</TableCell>
                            <TableCell>{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</TableCell>
                            <TableCell>
                                <FaEye onClick={() => navigate(`/partner/clients/${client?._id}`)} size={24} cursor={"pointer"} />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>


                <div className='flex justify-between items-center gap-3 mb-3 mt-14'>
                    <h2 className="text-xl font-semibold">Service Updates</h2>
                    {updates.length > 0 &&
                        <button onClick={() => navigate(`/partner/clients/${params?.clientId}/service/${params?.serviceId}/updates`, { state: updates })} className='bg-[#1A56DB] hover:bg-[#104ac6] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Client Name</TableHead>
                            {/* <TableHead>Status</TableHead> */}
                            <TableHead>Date</TableHead>
                            <TableHead>Document</TableHead>
                            <TableHead>Description</TableHead>
                            {/* <TableHead>Actions</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {updates?.map((update, index) => {
                            if (index <= 4) {
                                return (
                                    <ServiceUpdateComp
                                        key={update?._id}
                                        update={update}
                                    />
                                )
                            }
                        })}
                    </TableBody>
                </Table>

                {updates.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {updates.length === 0 && !isLoading &&
                    <DataNotFound name={"Service Updates"} />
                }

                <div className='flex justify-between items-center gap-4 mb-3 mt-14'>
                    <div className='flex items-center gap-3'>
                        <h2 className="text-xl font-semibold">Service Documents</h2>
                        {documents.length > 0 &&
                            <button onClick={() => navigate(`/partner/clients/${params?.clientId}/service/${params?.serviceId}/documents`, { state: documents })} className='bg-[#1A56DB] hover:bg-[#104ac6] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1'>
                                View All
                            </button>
                        }
                    </div>
                    <Button onClick={() => setIsAddDocumentModalOpen(true)}>Add Document</Button>
                </div>

                {documents.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {documents.length === 0 && !isLoading &&
                    <DataNotFound name={"Documents"} />
                }

                <div className="grid max-[640px]:place-items-center grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                    {documents?.map((document, index) => {
                        if (index <= 4) {
                            return (
                                <DocumentComp
                                    key={document._id}
                                    myDocument={document}
                                />
                            )
                        }
                    })}
                </div>


                {isAddDocumentModalOpen &&
                    <AddDocumentModal
                        isAddDocumentModalOpen={isAddDocumentModalOpen}
                        setIsAddDocumentModalOpen={setIsAddDocumentModalOpen}
                        getDocuments={getServiceDetail}
                        serviceId={service?._id}
                    />}
            </div>
        </PartnerWrapper>
    )
}

export default ClientServiceDetails