import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useEffect, useState } from 'react'
import PartnerDataModal from '../partner-dataModal/PartnerDataModal'

const PartnerFilterModal = ({ isFilterModalOpen, setIsFilterModalOpen }) => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [filterInfo, setFilterInfo] = useState({
        startDate: "",
        endDate: "",
    })

    const handleReset = async () => {
        setFilterInfo({
            startDate: "",
            endDate: "",
        })
    };

    const [partnerData, setPartnerData] = useState([]);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFilterInfo({ ...filterInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        fetchData(`/admin/get-partners-data?startDate=${filterInfo.startDate}&endDate=${filterInfo.endDate}`);
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("res", res);
            if (res?.data?.partners) {
                setIsDataModalOpen(true);
                setPartnerData(res?.data?.partners);
            }
        }
    }, [res])

    return (
        <>
            <Dialog open={isFilterModalOpen} onOpenChange={() => setIsFilterModalOpen(!isFilterModalOpen)}>
                <DialogContent className="sm:max-w-[525px] max-h-[90vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Filter Partners Data</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                        <div className="input-container">
                            <Label htmlFor="startDate">
                                Partner Joining Start Date
                            </Label>
                            <Input type="date" onChange={handleOnChange} value={filterInfo.startDate} name="startDate" id="startDate" className="col-span-3" />
                        </div>
                        <div className="input-container">
                            <Label htmlFor="endDate">
                                Partner Joining End Date
                            </Label>
                            <Input type="date" onChange={handleOnChange} value={filterInfo.endDate} name="endDate" id="endDate" className="col-span-3" />
                        </div>
                        <DialogFooter>
                            <Button onClick={handleReset} type="button">
                                Reset
                            </Button>
                            <Button type="submit">
                                {isLoading ? <Spinner /> : "Get Data"}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>
            {isDataModalOpen &&
                <PartnerDataModal
                    isDataModalOpen={isDataModalOpen}
                    setIsDataModalOpen={setIsDataModalOpen}
                    data={partnerData}
                />
            }
        </>
    )
}

export default PartnerFilterModal