import { readCookie } from '@/utils/readCookie';
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const ClientProtectedRoute = () => {
    const { pathname } = useLocation();
    const [isReturn, setIsReturn] = useState(true);

    const user = readCookie("user");
    const navigate = useNavigate();


    useEffect(() => {
        if (pathname.includes("/client") && user && user?._id) {
            setIsReturn(false);
        } else {
            setIsReturn(true);
            navigate("/");
        }
    }, [pathname, user]);



    if (!isReturn) {
        return <Outlet />;
    }

}

export default ClientProtectedRoute