import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import {
    CitySelect,
    StateSelect,
} from "@davzon/react-country-state-city";
import "@davzon/react-country-state-city/dist/react-country-state-city.css";

import Rectangle2 from "@/assets/Rectangle 2.png";
import signupImg from "@/assets/signup.png";
import OtpModal from "@/client/components/otp-Modal/OtpModal";
import Spinner from "@/components/spinner/Spinner";
import useGetApiReq from "@/hooks/useGetApiReq";
import toast from "react-hot-toast";
import { FaEye, FaEyeSlash } from "react-icons/fa";


const Signup = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [signupInfo, setSignupInfo] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        // email: "",
        password: "",
        type: "self",
    });

    const [addressInfo, setAddressInfo] = useState({
        state: "",
        city: "",
        pinCode: "",
        addressLine: "",
    });
    const [isVerifyOtpModalOpen, setIsVerifyOtpModalOpen] = useState(false);
    const [stateid, setstateid] = useState(0);
    const [isShowPassword, setIsShowPassword] = useState(false);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setSignupInfo({ ...signupInfo, [name]: value });
    };

    const handleAddressOnChange = (e) => {
        const { name, value } = e.target;
        setAddressInfo({ ...addressInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (!signupInfo.firstName || !signupInfo.lastName || !signupInfo.phone || !signupInfo.password) {
            toast.error("All fields are required");
            return;
        }

        if (signupInfo.phone.length !== 10) {
            toast.error("Phone length must be equal to 10 digits");
            return;
        }

        // if (addressInfo.pinCode.length !== 6) {
        //     toast.error("PinCode length must be equal to 6 digits");
        //     return;
        // }

        fetchData(`/client/get-otp?phone=${signupInfo.phone}&flag=new-user`)

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsVerifyOtpModalOpen(true);
            console.log("res", res);
        }
    }, [res])


    return (
        <>
            <section className="login-signup">
                <div className="login-signup-left">
                    <h1 className="signup-login-heading">
                        Client Signup
                    </h1>
                    <form onSubmit={handleOnSubmit} className="form">
                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="firstName">
                                First Name
                            </Label>
                            <Input placeholder="Enter First Name" onChange={handleOnChange} value={signupInfo.firstName} name="firstName" id="firstName" className="col-span-3" />
                        </div>
                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="lastName">
                                Last Name
                            </Label>
                            <Input placeholder="Enter Last Name" onChange={handleOnChange} value={signupInfo.lastName} name="lastName" id="lastName" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="phone">
                                Phone
                            </Label>
                            <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={signupInfo.phone} name="phone" id="phone" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4 relative">
                        <Label htmlFor="password">
                            Password
                        </Label>
                        <Input type={isShowPassword ? "text" : "password"} placeholder="Enter Password" onChange={handleOnChange} value={signupInfo.password} name="password" id="password" className="col-span-3" />
                        {isShowPassword ?
                            <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        }
                    </div>

                        {/* <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="email">
                                Email
                            </Label>
                            <Input type="email" placeholder="Enter Email" onChange={handleOnChange} value={signupInfo.email} name="email" id="email" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="state">
                                State
                            </Label>
                            <StateSelect
                                countryid={101}
                                onChange={(e) => {
                                    setstateid(e.id);
                                    setAddressInfo({ ...addressInfo, state: e.name })
                                    console.log("state selected", e);
                                }}
                                placeHolder="Select State"
                            />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="city">
                                City
                            </Label>
                            <CitySelect
                                countryid={101}
                                stateid={stateid}
                                onChange={(e) => {
                                    setAddressInfo({ ...addressInfo, city: e.name })
                                    console.log("city selected", e);
                                }}
                                placeHolder="Select City"
                            />
                        </div>
                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="pinCode">
                                Pincode
                            </Label>
                            <Input type="number" placeholder="Enter Pincode" onChange={handleAddressOnChange} value={addressInfo.pinCode} name="pinCode" id="pinCode" className="col-span-3" />
                        </div>

                        <div className="grid grid-cols-1 items-center gap-4">
                            <Label htmlFor="addressLine">
                                Address Line
                            </Label>
                            <Input placeholder="Enter Address Line" onChange={handleAddressOnChange} value={addressInfo.addressLine} name="addressLine" id="addressLine" className="col-span-3" />
                        </div> */}

                        <div className="btn-container">
                            <button className="btn">{isLoading ? <Spinner /> : "Signup"}</button>
                        </div>
                        <p className="text-center mt-2 max-[435px]:text-sm">Already have account? <button type="button" onClick={() => navigate("/")} className="text-blue-500">Login</button></p>
                    </form>
                </div>
                <div className="login-signup-right">
                    <div className="login-signup-right-inner">
                        <img className="absolute z-10 w-[60%] right-[18%] bottom-[18%]" src={signupImg} alt="signupImg" />
                        <img className="absolute w-[70%] bottom-0 right-0" src={Rectangle2} alt="Rectangle2" />
                    </div>
                </div>
            </section>
            {isVerifyOtpModalOpen &&
                <OtpModal
                    isVerifyOtpModalOpen={isVerifyOtpModalOpen}
                    setIsVerifyOtpModalOpen={setIsVerifyOtpModalOpen}
                    signupInfo={{ ...signupInfo }}
                    setSignupInfo={setSignupInfo}
                    handleOnChange={handleOnChange}
                    resendOtp={handleOnSubmit}
                />
            }
        </>
    );
};

export default Signup;