import React from 'react'

const LimitComp = ({ setLimit,limit }) => {
    return (
        <select onChange={(e) => setLimit(Number(e.target.value))} value={limit} className="bg-gray-100 border-2 border-gray-400 mx-1 rounded-md px-1 py-[2px]" name="" id="">
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
        </select>
    )
}

export default LimitComp