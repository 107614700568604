import AdminWrapper from "@/admin/components/addmin-wrapper/AdminWrapper"
import Chatlist from "@/admin/components/chatlist/Chatlist"
import MessageList from "@/admin/components/message-list-comp/MessageListComp"
import useGetApiReq from "@/hooks/useGetApiReq"
import { useEffect, useState } from "react"
import "react-chat-elements/dist/main.css"
import { useLocation } from "react-router-dom"
import noChats from "@/assets/no-chats.jpg"

const Chats = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const { state } = useLocation();

    const [selected, setSelected] = useState(state?.clientId || "");
    const [serviceId, setServiceId] = useState(state?.serviceId || "");
    const [chatList, setChatList] = useState([]);
    const [type, setType] = useState(state?.type || "non-internal");

    const getChats = async () => {
        fetchData("/admin/get-open-chats");
    }

    useEffect(() => {
        getChats();
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("chats", res?.data?.dataSource);
            setChatList(res?.data?.dataSource);
            // setSelected(res?.data?.dataSource[0]?.clientId);
            // setServiceId(res?.data?.dataSource[0]?.serviceId);
        }
    }, [res])

    return (
        <AdminWrapper>
            <div className="flex w-full relative">
                {chatList.length === 0 ?
                    <div className='flex flex-col max-[770px]:justify-center items-center'>
                        <img className='w-[60%]' src={noChats} alt="noChats" />
                        {/* <p className='mt-2'>Choose a service to chat or enquire</p> */}
                    </div>
                    :
                    <>
                        <Chatlist setType={setType} chatList={chatList} isLoading={isLoading} serviceId={serviceId} setServiceId={setServiceId} setSelected={setSelected} selected={selected} />
                        <MessageList type={type} id={serviceId} selected={selected} getChats={getChats} />
                    </>
                }
            </div>
        </AdminWrapper>
    )
}

export default Chats