import OtpComp from '@/components/otp-comp/OtpComp'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { BsFillShieldLockFill } from 'react-icons/bs'
import { CgSpinner } from 'react-icons/cg'
import { useNavigate, useParams } from 'react-router-dom'
import OtpInput from "otp-input-react";
import { FaEdit } from 'react-icons/fa'
import { Label } from '@/components/ui/label'
import { Input } from '@/components/ui/input'
import { AlertDialog, AlertDialogContent, AlertDialogFooter } from '@/components/ui/alert-dialog'

const OtpModal = ({ isVerifyOtpModalOpen, setIsVerifyOtpModalOpen, signupInfo, setSignupInfo, handleOnChange, resendOtp }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const navigate = useNavigate();

    const [otp, setOtp] = useState("");
    const [isPhoneEdit, setIsPhoneEdit] = useState(false);

    // const handleOnChange = (e) => {
    //     const { name, value } = e.target;
    //     setDocumentInfo({ ...documentInfo, [name]: value });
    // };

    const handleOnSubmit = async (e) => {
        e.preventDefault();

        if (otp.length !== 6) {
            toast.error("Otp length must be equal to 6 digits");
            return;
        }

        fetchData("/client/signup", { ...signupInfo, enteredOtp: otp })

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("res", res);
            navigate("/");
        }
    }, [res])


    return (
        <Dialog open={isVerifyOtpModalOpen} onOpenChange={() => setIsVerifyOtpModalOpen(!isVerifyOtpModalOpen)}>
            <DialogContent onInteractOutside={(e) => e.preventDefault()} className="sm:max-w-[425px] max-h-[90vh] overflow-y-auto">
                {/* <DialogHeader>
                    <DialogTitle>Otp</DialogTitle>
                </DialogHeader> */}
                <form onSubmit={handleOnSubmit} className="py-4 otp-modal-form">
                    <div className="bg-[#1E429F] text-white w-fit mx-auto p-4 rounded-full">
                        <BsFillShieldLockFill size={30} />
                    </div>
                    <label
                        htmlFor="otp"
                        className="font-bold text-lg text-black w-full block mt-2 mb-4 text-center"
                    >
                        Enter your OTP
                    </label>

                    {isPhoneEdit &&
                        <div className="grid grid-cols-1 items-center gap-4 mb-4">
                            <Label htmlFor="phone">
                                Phone
                            </Label>
                            <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={signupInfo.phone} name="phone" id="phone" className="col-span-3" />
                        </div>
                    }

                    <OtpInput
                        value={otp}
                        onChange={setOtp}
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        autoFocus
                        className="opt-container mb-4"
                    ></OtpInput>
                    <DialogFooter>
                        <div className="flex justify-between items-center w-full">
                            <div className='flex gap-1'>
                                <Button type="button" variant="outline" onClick={() => setIsPhoneEdit(true)} className={`flex items-center gap-2 cursor-pointer ${isPhoneEdit?"hidden":""}`}>
                                    <span>Edit Phone</span>
                                    <FaEdit size={23} />
                                </Button>
                                <Button onClick={resendOtp} type="button">Resend Otp</Button>
                            </div>
                            <Button type="submit">
                                {isLoading ? <Spinner /> : "Submit"}
                            </Button>
                        </div>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default OtpModal