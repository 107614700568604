
const TestimonalCard = ({ testimonial }) => {
    return (
        <div className="testimonial-card">
            <div className="flex gap-2">
                <img className="w-16 h-16 rounded-full" src={testimonial?.src} alt="" />
                <div>
                    <h2 className="text-lg font-bold">{testimonial?.name}</h2>
                    <p className="text-[#303030]">{testimonial?.position}</p>
                </div>
            </div>
            <div className="testimonial-card-bottom">
                <p className='max-[560px]:text-sm'>{testimonial?.desc}</p>
            </div>
        </div>
    )
}

export default TestimonalCard