import { MessageBox } from "react-chat-elements";
import ChatInput from "../chat-input/ChatInput";
import useGetApiReq from "@/hooks/useGetApiReq";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataNotFound from "@/components/data-not-found/DataNotFound";
import Spinner from "@/components/spinner/Spinner";
import parse from "html-react-parser";
import noMessages from "@/assets/no-messages.jpg"

const MessageListComp = ({ selected, id, getChats, type }) => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const [messageList, setMessageList] = useState([]);
  const [serviceId, setServiceId] = useState("");

  const navigate = useNavigate();

  const getChatMessages = useCallback(async () => {
    if (type === "internal") {
      fetchData(`/admin/get-chats/${selected}?type=${type}`);
    }
    else if (type === "non-internal" && id) {
      fetchData(`/admin/get-chats/${selected}?serviceId=${id}&type=${type}`);
    }
  }, [selected, id, type]);

  useEffect(() => {
    // if (selected || id) {
    getChatMessages();
    // }
  }, [selected, getChatMessages, id, type]);

  const handleAcceptPrice = () => {
    // alert("hey btn");
  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setServiceId(res?.data?.service?._id);
      getChats();
      const data = res?.data?.dataSource.map((item) => {
        if (item?.buttonFlag === "accept-btn") {
          return {
            ...item,
            type: "meetingLink",
            actionButtons: [
              {
                onClickButton: handleAcceptPrice,
                Component: () => (
                  <button className="bg-green-400 text-white px-2 py-[2px] rounded-md">
                    Pay Now
                  </button>
                ),
              },
            ],
          };
        }
        return item;
      });
      setMessageList(data);
    }
  }, [res]);

  const message = messageList.find((item) => item.pin === true);

  console.log("messageList admin side", messageList);

  return (
    <div className="flex flex-col gap-2 sticky top-[72px] h-[calc(100vh-135px)] w-full border-2 message">
      {messageList.length > 0 && message && (
        <div className="w-full bg-gray-100 p-2 sticky top-0 z-10 flex justify-between items-start gap-6">
          {message && <p>{parse(message?.text)}</p>}
          <button
            onClick={() => navigate(`/admin/categories/${serviceId}`)}
            className="bg-black px-2 rounded-md text-white py-[2px]"
          >
            View Service
          </button>
        </div>
      )}
      {messageList.length === 0 && isLoading && <Spinner size={30} />}

      {id && messageList.length === 0 && !isLoading && (
        <DataNotFound name={"Messages"} />
      )}

      {!id && messageList.length === 0 && !isLoading && (
        <div className='flex flex-col items-center'>
            <img className='w-[50%]' src={noMessages} alt="noMessages" />
            <p className='mt-2'>Select chat to view messages</p>
          </div>
      )}

      <div className="w-full overflow-y-auto h-full relative mt-2">
        {messageList.length > 0 &&
          messageList?.map((item, i) => (
            <MessageBox
              key={i}
              position={item.position}
              title={item.title}
              type={item.type}
              text={parse(item.text)}
              actionButtons={item?.actionButtons}
            />
          ))}
      </div>
      {selected &&
        <ChatInput
          serviceId={serviceId}
          selected={selected}
          getChats={getChatMessages}
          type={type}
        />
      }
    </div>
  );
};

export default MessageListComp;
