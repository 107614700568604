import Spinner from '@/components/spinner/Spinner';
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import usePostApiReq from '@/hooks/usePostApiReq';
import React, { useEffect, useState } from 'react'

const ChatInput = ({ getChats, serviceId, type }) => {
    const [message, setMessage] = useState("");

    const { res, fetchData, isLoading } = usePostApiReq();


    const sendMessage = async () => {
        fetchData("/client/send-message", { msg: message, serviceId, type });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setMessage("");
            getChats();
        }
    }, [res])

    const handleOnChange = (e) => {
        const { value } = e.target;
        console.log("message", value);
        setMessage(value);
    }

    return (
        <div className='flex items-center gap-2 px-2 py-2 z-10 sticky bottom-0 w-full'>
            <Input placeholder="Enter Message" onChange={handleOnChange} value={message} name="message" id="message" className="col-span-3" />
            <Button className="bg-[#1A56DB] hover:bg-[#1350d5]" onClick={sendMessage}>
                {isLoading ? <Spinner /> : "Send"}
            </Button>
        </div>
    )
}

export default ChatInput