import Service from '@/client/components/service/Service'
import SuccessModal from '@/client/components/successmodal/SuccessModal';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import Spinner from '@/components/spinner/Spinner';
import { Input } from '@/components/ui/input';
import { Table, TableBody, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import Wrapper from '@/components/wrapper/Wrapper'
import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react'
import { IoIosSearch } from 'react-icons/io';
import { useParams } from 'react-router-dom';

const Services = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const params = useParams()

    const getServices = async () => {
        fetchData(`/client/all-services?categoryId=${params?.categoryId}&search=${searchInput}`);
    }

    useEffect(() => {
        getServices();
    }, [params?.categoryId, searchInput]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            // const data = res?.data?.services?.filter((item) => (item.status !== "accepted" && item.status !== "purchased"))
            console.log("data", res);
            setAllServices(res?.data?.services);
        }
    }, [res])

    return (
        <Wrapper>
            <div>
                <div className='section-heading-container'>
                    <h1 className='section-heading'>Services</h1>
                    <div className='flex items-center max-[560px]:w-full'>
                        <IoIosSearch className='-mr-6 z-10' />
                        <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Service" className="pl-8 max-[560px]:w-full" />
                    </div>
                </div>
                <Table className="mt-5 max-[560px]:hidden">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Category</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allServices?.map((service) => (
                            <Service
                                key={service._id}
                                service={service}
                                getServices={getServices}
                            />
                        ))}
                    </TableBody>
                </Table>

                <div className='flex flex-col gap-3 mt-4 min-[560px]:hidden'>
                    {allServices?.map((service) => (
                        <Service
                            key={service._id}
                            service={service}
                            getServices={getServices}
                        />
                    ))}
                </div>
                {allServices.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allServices.length === 0 && !isLoading &&
                    <DataNotFound name={"Services"} />
                }

                <SuccessModal
                    isOpen={isSuccessModalOpen}
                    setIsOpen={setIsSuccessModalOpen}
                />
            </div>
        </Wrapper>
    )
}

export default Services