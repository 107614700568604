import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import usePatchApiReq from '@/hooks/usePatchApiReq'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { CgSpinner } from 'react-icons/cg'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AddCategoryModal = ({ isAddCategoryModalOpen, setIsAddCategoryModalOpen, getCategories, category }) => {
    const { res, fetchData, isLoading } = usePostApiReq()
    const { res:categoryUpdateRes, fetchData:fetchCategoryUpdate, isLoading:isCategoryUpdateLoading } = usePatchApiReq()

    const [categoryInfo, setCategoryInfo] = useState({
        name: category?.name || "",
        icon: category?.icon || "",
        previewIcon: `${import.meta.env.VITE_IMG_URL}/${category?.icon}` ||"",
    })
    const [description, setDescription] = useState(category?.description || "");
    const [isPreview, setIsPreview] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCategoryInfo({ ...categoryInfo, [name]: value });
    };

    const getImage = (event) => {
        event.preventDefault();
        console.log("event: ", event);
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setIsPreview(true);
                setCategoryInfo({
                    ...categoryInfo,
                    previewIcon: this.result,
                    icon: uploadedImage,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        console.log("in submit fun");
        if (!description || !categoryInfo.icon || !categoryInfo.name) {
            toast.error("All fields are required");
            return;
        }

        const formdata = new FormData();
        formdata.append("name", categoryInfo.name);
        formdata.append("icon", categoryInfo.icon);
        formdata.append("description", description);

        if (category) {
            fetchCategoryUpdate(`/admin/edit-category/${category?._id}`, formdata);
        }
        else {
            fetchData("/admin/add-category", formdata);
        }

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddCategoryModalOpen(false)
            getCategories();
        }
    }, [res])

    useEffect(() => {
        if (categoryUpdateRes?.status === 200 || categoryUpdateRes?.status === 201) {
            setIsAddCategoryModalOpen(false)
            getCategories();
        }
    }, [categoryUpdateRes])


    return (
        <Dialog open={isAddCategoryModalOpen} onOpenChange={() => setIsAddCategoryModalOpen(!isAddCategoryModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>{category ? "Update" : "Add"} Category</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="icon">
                            Icon
                        </Label>
                        {categoryInfo.previewIcon &&
                            <Avatar>
                                <AvatarImage src={categoryInfo.previewIcon} alt="category" />
                                <AvatarFallback>category</AvatarFallback>
                            </Avatar>
                        }
                        <Input type="file" accept=".jpg,.jpeg" onChange={(e) => getImage(e)} name="icon" id="icon" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input onChange={handleOnChange} value={categoryInfo.name} name="name" id="name" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="description">
                            Description
                        </Label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : category ? "Update" : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddCategoryModal