import { readCookie } from '@/utils/readCookie';
import { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

const AdminProtectedRoute = () => {
    const { pathname } = useLocation();
    const [isReturn, setIsReturn] = useState(true);

    const user = readCookie("user");
    const permissions = user?.permissions;
    const navigate = useNavigate();
    const value = pathname.split("/admin/")[1];
    const updatedValue = value.split("/")[0];


    useEffect(() => {
        if (pathname.includes("/admin") && updatedValue && permissions && permissions[updatedValue] !== "none") {
            setIsReturn(false);
        } else {
            setIsReturn(true);
            navigate("/admin/login");
        }
    }, [pathname, permissions]);



    if (!isReturn) {
        return <Outlet />;
    }

}

export default AdminProtectedRoute