import { Button } from "@/components/ui/button"
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog"
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { useRef } from "react";
import { useDownloadExcel } from 'react-export-table-to-excel';

const PartnerDataModal = ({ isDataModalOpen, setIsDataModalOpen, data }) => {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    filename: "Partners Data table",
    sheet: "Partners Data",
    currentTableRef: tableRef?.current
  })

  return (
    <Dialog open={isDataModalOpen} onOpenChange={() => setIsDataModalOpen(!isDataModalOpen)}>
      <DialogContent className="sm:max-w-[825px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Partners Data</DialogTitle>
        </DialogHeader>
        {data?.length === 0 &&
          <p className="">No partners found.</p>
        }
        {data?.length > 0 &&
          <div className="grid gap-4 py-4">
            <Table ref={tableRef}>
              <TableHeader>
                <TableRow className="text-lg">
                  <TableHead className="w-28">Name</TableHead>
                  <TableHead>Phone</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Address</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.map((info) => (
                  <TableRow key={info?._id}>
                    <TableCell className="w-28">{`${info?.name?.first} ${info?.name?.last}`}</TableCell>
                    <TableCell>{info?.phone}</TableCell>
                    <TableCell>{info.email}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-md text-white ${info?.status ? "bg-green-500" : "bg-red-500"}`}>{info?.status?"Active":"InActive"}</span>
                    </TableCell>
                    <TableCell>{`${info?.address?.addressLine} ${info?.address?.city}, ${info?.address?.state}, ${info?.address?.pinCode}`}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <DialogFooter>
              <Button onClick={onDownload} type="button">Export to Excel</Button>
            </DialogFooter>
          </div>}
      </DialogContent>
    </Dialog>
  )
}

export default PartnerDataModal