import { Button } from "@/components/ui/button"
import { useState } from "react"
import BuyNowService from "../buy-now-service/BuyNowService";
import { useNavigate } from "react-router-dom";
import { TableCell, TableRow } from "@/components/ui/table";
import parse from "html-react-parser"

const Service = ({ service, getServices }) => {
    const [isBuyNowServiceModalOpen, setIsBuyNowServiceModalOpen] = useState(false);

    const navigate = useNavigate();
    const icon = `${import.meta.env.VITE_IMG_URL}/${service?.icon}`;


    return (
        <>
            <TableRow className="max-[560px]:hidden">
                <TableCell>
                    <div onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                        <img className="w-full h-full rounded-full" src={icon} alt="" />
                    </div>
                </TableCell>
                <TableCell>
                    <h3 onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="text-lg cursor-pointer font-semibold">{service?.name}</h3>
                </TableCell>
                <TableCell>{service?.categoryId?.name}</TableCell>
                <TableCell className="w-60 line-clamp-2">{service.description && parse(service.description)}</TableCell>
                <TableCell>
                    {service.status === "pending" &&
                        <Button className="bg-[#1A56DB] hover:bg-[#0b47c7]" disabled={true}>Awaiting Quotation...</Button>
                    }
                    {service.status === "quotation-sent" &&
                        <Button className="bg-[#1A56DB] hover:bg-[#0b47c7]" onClick={() => navigate("/client/chats", { state: { serviceId: service?._id } })}>Accept</Button>
                    }
                    {/* {service.status === "accepted" &&
                        <Button disabled={true} onClick={() => navigate("/client/chats", { state: { serviceId: service?._id } })}>Pay Now</Button>
                    } */}
                    {(service.status === "available" || service.status === "accepted") &&
                        <Button className="bg-[#1A56DB] hover:bg-[#0b47c7]" onClick={() => setIsBuyNowServiceModalOpen(true)}>Buy Now</Button>
                    }
                </TableCell>
            </TableRow>

            <div className="border-2 min-[560px]:hidden rounded-2xl flex flex-wrap gap-y-5 gap-0 border-[#E5E7EB] bg-[#F9FAFB] p-8">
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex gap-2 justify-between border-b-2 pb-2">
                        <p className="text-sm font-extrabold text-[#64748B]">Icon</p>
                        <p className="text-sm font-extrabold text-[#64748B]">Name</p>
                        <p className="text-sm font-extrabold text-[#64748B]">Category</p>
                    </div>
                    <div className="flex gap-2 justify-between">
                        <div onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="w-10 cursor-pointer h-10 rounded-full bg-slate-500">
                            <img className="w-full h-full rounded-full" src={icon} alt="" />
                        </div>
                        <h3 onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="text-sm cursor-pointer font-semibold">{service?.name}</h3>
                        <p className="text-sm font-semibold">{service?.categoryId?.name}</p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 w-full">
                    <div className="flex gap-2 border-b-2 pb-2 justify-between">
                        <p className="text-sm font-extrabold text-[#64748B]">Description</p>
                        <p className="text-sm font-extrabold text-[#64748B]">Actions</p>
                    </div>
                    <div className="flex gap-2 justify-between">
                        <p className="text-sm cursor-pointer font-semibold line-clamp-2">{service.description && parse(service.description)}</p>
                        {/* <p className="text-sm cursor-pointer pl-6 pr-6 font-semibold line-clamp-2">{service.description && parse(service.description)}</p> */}
                        <div>
                            {service.status === "pending" &&
                                <button className="bg-[#1A56DB] hover:bg-[#0b47c7] rounded-md text-white px-4 py-1 max-[560px]:py-[2px] max-[560px]:px-2 max-[560px]:text-sm max-[560px]:rounded-none disabled:bg-[#1a57dbaa]" disabled={true}>Awaiting Quotation...</button>
                            }
                            {service.status === "quotation-sent" &&
                                <button className="bg-[#1A56DB] hover:bg-[#0b47c7] rounded-md text-white px-4 py-1 max-[560px]:py-[2px] max-[560px]:px-2 max-[560px]:text-sm max-[560px]:rounded-none" onClick={() => navigate("/client/chats", { state: { serviceId: service?._id } })}>Accept</button>
                            }
                            {/* {service.status === "accepted" &&
                                <Button disabled={true} onClick={() => navigate("/client/chats", { state: { serviceId: service?._id } })}>Pay Now</Button>
                            } */}
                            {(service.status === "available" || service.status === "accepted") &&
                                <button className="bg-[#1A56DB] hover:bg-[#0b47c7] rounded-md text-white px-4 py-1 max-[560px]:py-[2px] max-[560px]:px-2 max-[560px]:text-sm max-[560px]:rounded-none" onClick={() => setIsBuyNowServiceModalOpen(true)}>Buy Now</button>
                            }
                        </div>
                    </div>
                </div>
            </div>

            <BuyNowService
                setIsBuyNowServiceModalOpen={setIsBuyNowServiceModalOpen}
                isBuyNowServiceModalOpen={isBuyNowServiceModalOpen}
                serviceId={service._id}
                getServices={getServices}
            />
        </>
    )
}

export default Service