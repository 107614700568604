import Chatlist from '@/client/components/chatlist/Chatlist'
import MessageListComp from '@/client/components/message-list-comp/MessageListComp'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import Wrapper from '@/components/wrapper/Wrapper'
import useGetApiReq from '@/hooks/useGetApiReq'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useCallback, useEffect, useState } from 'react'
import { MessageBox } from 'react-chat-elements'
import "react-chat-elements/dist/main.css"
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import parse from "html-react-parser";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { format } from 'date-fns'

const ClosedChats = () => {

    const { state } = useLocation();
    const serviceId = state.serviceId;
    const request = state.request;

    const [selected, setSelected] = useState(state?.serviceId || "");

    const { res, fetchData, isLoading } = useGetApiReq();

    const [messageList, setMessageList] = useState([]);
    const [service, setService] = useState("");

    const params = useParams();
    const navigate = useNavigate();

    const getChats = useCallback(async () => {
        setMessageList([]);
        fetchData(`/client/get-closed-chats?requestId=${params?.reqId}`);
    }, [fetchData, serviceId]);

    useEffect(() => {
        // if (serviceId || type) {
        getChats();
        // }
    }, [serviceId])


    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setService(res?.data?.service);
            console.log("messageList", res?.data);
            const data = res?.data?.dataSource.map((item, i) => {
                if (item?.buttonFlag === "accept-btn") {
                    return {
                        ...item, type: "meetingLink", actionButtons:
                            [
                                {
                                    Component: () => <button className='bg-green-400 text-white px-2 py-[2px] rounded-md'>Accept</button>,
                                },
                            ]
                    }
                }
                return item;
            })
            setMessageList(data);
        }
    }, [res])

    return (
        <Wrapper>
            <div className="flex flex-col gap-5 w-full relative">
                <h2 className='text-xl font-semibold'>Request Details</h2>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg max-[560px]:text-xs">
                            <TableHead>Date</TableHead>
                            <TableHead>Service Name</TableHead>
                            <TableHead>Status</TableHead>
                            {/* <TableHead>Chat</TableHead> */}
                            <TableHead>Type</TableHead>
                            <TableHead className="w-60">Note</TableHead>
                            <TableHead className="w-60">Requirement</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow className="max-[560px]:text-xs">
                            <TableCell>{request?.createdAt && format(new Date(request?.createdAt), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{request?.serviceId?.name}</TableCell>
                            <TableCell className="w-40">
                                <span className={`px-2 py-[2px] rounded-md ${request?.status === "pending" ? "bg-orange-400 text-white" : request?.status === "quotation-sent" ? "bg-yellow-500 text-white" : request?.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request?.status}</span>
                            </TableCell>
                            {/* <TableCell>
                                <button onClick={() => navigate(`/client/requests/${params?.reqId}/chats`, { state: { serviceId: request?.serviceId?._id } })} className="hover:underline">Chat</button>
                            </TableCell> */}
                            <TableCell>{request?.type}</TableCell>
                            <TableCell>{request?.note}</TableCell>
                            <TableCell>{request?.requirement && parse(request?.requirement)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <div className='flex flex-col gap-2 h-[50vh] overflow-y-auto w-full border-2'>
                    {/* <div className='w-full bg-gray-100 p-2 sticky top-0 z-10 flex justify-between gap-1'>
                        {serviceId &&
                            <div className='flex flex-col gap-1'>
                                <div><span className='font-bold'>Service Id : </span>{service?._id}</div>
                                <div><span className='font-bold'>Service Name: </span>{service?.name}</div>
                            </div>}
                    </div> */}
                    {messageList.length === 0 && isLoading &&
                        <Spinner size={30} />
                    }

                    {serviceId && messageList.length === 0 && !isLoading &&
                        <DataNotFound name={"Messages"} />
                    }

                    {!serviceId && messageList.length === 0 && !isLoading && (
                        <p className="mt-2 text-center">Select chat to view messages</p>
                    )}
                    <div className='w-full overflow-y-auto mt-2 h-full'>
                        {messageList.length > 0 && messageList?.map((item, i) => (
                            <MessageBox
                                key={i}
                                position={item.position}
                                title={item.title}
                                type={item.type}
                                text={parse(item.text)}
                                actionButtons={item?.actionButtons}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default ClosedChats