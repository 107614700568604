import { useEffect, useState } from "react";
import DocumentView from "../document-view/DocumentView";
import useGetApiReq from "@/hooks/useGetApiReq";
import axios from "axios";
import docImg from "../../../assets/doc-placeholder.jpeg";
import { saveDoc } from "@/utils/download";
import { FaArrowAltCircleDown } from "react-icons/fa";

const DocumentComp = ({ myDocument = "" }) => {
    console.log("myDocument", myDocument);
    const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);

    const { res, fetchData, isLoading } = useGetApiReq();

    const getDoc = () => {
        fetchData(`/partner/get-doc?url=${myDocument?.path}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("Document", res);
            saveDoc(res?.data?.preSignedUrl);
        }
    }, [res])

    return (
        <>
            <div className="max-w-[400px] w-full">
                <div className="border border-gray-200 p-6 rounded-lg">
                    <div className="inline-flex w-full items-center justify-center mb-4">
                        <img className="w-full h-40 object-contain" src={docImg} />
                    </div>
                    <div className="flex justify-between items-center">
                        <h2 className="text-lg text-gray-900 font-medium title-font mb-2">
                            {myDocument?.name}
                        </h2>
                        <FaArrowAltCircleDown onClick={() => getDoc()} size={22} cursor={"pointer"} />
                    </div>
                </div>
            </div>

            <DocumentView
                document={myDocument.path}
                setIsDocumentViewModalOpen={setIsDocumentViewModalOpen}
                isDocumentViewModalOpen={isDocumentViewModalOpen}
            />
        </>
    )
}

export default DocumentComp