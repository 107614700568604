import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Rectangle17 from "@/assets/Rectangle 17.png";

const Client = ({ client }) => {
const navigate = useNavigate();

    return (
        <div className="border-2 rounded-md p-4 mt-4 flex items-start bg-[#F3F4F6] gap-5">
            <div className="flex cursor-pointer flex-col gap-2">
                <div className="w-32 h-36 rounded-lg">
                    <img className="w-full h-full" src={Rectangle17} alt="Rectangle17" />
                </div>
                <h3 className="text-xl text-center font-bold">{client?.name?.first}</h3>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                    <p className="text-[#6B7280] font-bold">Full Name</p>
                    <p>{`${client?.name?.first} ${client?.name?.last}`}</p>
                </div>
                <div className="flex flex-col">
                    <p className="text-[#6B7280] font-bold">Phone No</p>
                    <p>{client?.phone}</p>
                </div>
                <div className="flex flex-col">
                    <p className="text-[#6B7280] font-bold">Email</p>
                    <p>{client?.email}</p>
                </div>
            </div>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                    <p className="text-[#6B7280] font-bold">Address</p>
                    <p className="max-w-[200px]">{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</p>
                </div>
                <button onClick={() => navigate(`/admin/clients/${client?._id}`, { state: client })} className="mt-10 text-[#1A56DB] flex gap-2 items-center">
                    Learn more
                    <FaArrowRight />
                </button>
            </div>
        </div>
    )
}

export default Client