import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import useGetApiReq from '@/hooks/useGetApiReq'


const AddSubFolderModal = ({ isAddSubFolderModalOpen, setIsAddSubFolderModalOpen, getSubFolders, id }) => {
    console.log("id", id);
    const { res, fetchData, isLoading } = usePostApiReq();

    const [subFolderInfo, setSubFolderInfo] = useState({
        name: "",
        fId: id,
        type: ""
    });


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setSubFolderInfo({ ...subFolderInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!subFolderInfo.name || !subFolderInfo.fId || !subFolderInfo.type) {
            toast.error("All fields are required");
            return;
        }

        fetchData("/admin/add-subfolder", { name: subFolderInfo.name, type: subFolderInfo.type, fId: id });
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddSubFolderModalOpen(false)
            getSubFolders();
        }
    }, [res])


    return (
        <Dialog open={isAddSubFolderModalOpen} onOpenChange={() => setIsAddSubFolderModalOpen(!isAddSubFolderModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Add Sub Folder</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="name">
                            Folder Name
                        </Label>
                        <Input placeholder="Enter Folder Name" onChange={handleOnChange} value={subFolderInfo.name} name="name" id="name" className="col-span-3" />
                    </div>

                    <div className="input-container">
                        <Label htmlFor="name">
                            Type
                        </Label>
                        <Select className="col-span-3" name='fId' id="fId" onValueChange={(value) => setSubFolderInfo({ ...subFolderInfo, type: value })} value={subFolderInfo.type} >
                            <SelectTrigger>
                                <SelectValue placeholder="Select" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectItem value="folder">Folder</SelectItem>
                                <SelectItem value="sub-folder">Sub Folder</SelectItem>
                            </SelectContent>
                        </Select>
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddSubFolderModal