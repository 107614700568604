import Wrapper from '@/components/wrapper/Wrapper'
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Tooltip,
    TooltipContent,
    TooltipProvider,
    TooltipTrigger,
} from "@/components/ui/tooltip"


import { useNavigate, useParams } from 'react-router-dom'
import { FaCloudUploadAlt } from "react-icons/fa";
import DocumentComp from '@/client/components/document/DocumentComp';
import { useEffect, useState } from 'react';
import AddDocumentModal from '@/client/components/add-documentModal/AddDocumentModal';
import useGetApiReq from '@/hooks/useGetApiReq';
import Spinner from '@/components/spinner/Spinner';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import ServiceUpdateComp from '@/client/components/service-update-comp/ServiceUpdateComp';
import InvoiceModal from '@/client/components/invoice-modal/InvoiceModal';
import usePostApiReq from '@/hooks/usePostApiReq';
import { readCookie } from '@/utils/readCookie';

const ServiceUpdateDetails = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: paymentRes, fetchData: paymentFetchData, isLoading: paymentIsLoading } = usePostApiReq();

    const [isAddDocumentModalOpen, setIsAddDocumentModalOpen] = useState(false);
    const [allDocuments, setAllDocument] = useState([]);
    const [serviceUpdates, setServiceUpdates] = useState([]);
    const [service, setService] = useState("");
    const [serviceClientLinkDoc, setServiceClientLinkDoc] = useState("");
    const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
    const navigate = useNavigate();
    const params = useParams();

    const getMyServicesDetails = async () => {
        fetchData(`/client/get-my-services-details/${params?.myServiceId}`);
    };

    useEffect(() => {
        getMyServicesDetails();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("res", res);
            setAllDocument(res?.data?.myservice?.docs);
            setServiceUpdates(res?.data?.myservice?.updates);
            setService(res?.data?.myservice?.serviceInfo);
            setServiceClientLinkDoc(res?.data?.myservice?.serviceClientLinkDoc);
        }
    }, [res]);

    const user = readCookie("user");
    const data = {
        name: `${user?.name?.first} ${user?.name?.last}`,
        // amount: serviceClientLinkDoc?.cost,
        transactionId: `T${Date.now()}`,
        MUID: `MUID${Date.now()}`,
        number: user?.phone
    }

    const handlePayment = () => {
        paymentFetchData("/client/accept-payment", { ...data, serviceClientLinkId: params?.myServiceId });
    };

    useEffect(() => {
        if (paymentRes?.status === 200 || paymentRes?.status === 201) {
            console.log("pay res", paymentRes);
            window.location.href = paymentRes?.data?.redirectUrl;
            getMyServicesDetails();
        }
    }, [paymentRes])

    return (
        <Wrapper>
            <div>
                {serviceClientLinkDoc?.paymentStatus === "due" &&
                    <div className='mt-1 text-white flex justify-between items-center bg-black px-2 py-2'>
                        The payment of this service is due. (₹{serviceClientLinkDoc?.cost})
                        <button onClick={handlePayment} className='bg-white text-black px-3 py-1 rounded-md'>Pay Now</button>
                    </div>
                }
                <Table className="mt-4 max-[560px]:w-full mb-5">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg max-[560px]:text-xs">
                            <TableHead>Icon</TableHead>
                            <TableHead className="w-56">Service Id</TableHead>
                            <TableHead>Name</TableHead>
                            {/* <TableHead>Status</TableHead> */}
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow className="max-[560px]:text-xs">
                            <TableCell className="font-medium">
                                <div onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="w-12 cursor-pointer h-12 max-[560px]:w-10 max-[560px]:h-10 rounded-full bg-slate-500">
                                    <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.icon}`} alt="" />
                                </div>
                            </TableCell>
                            <TableCell onClick={() => navigate(`/client/service/${service?._id}`, { state: service })} className="font-medium cursor-pointer w-56">{service?._id}</TableCell>
                            <TableCell className="font-medium">{service?.name}</TableCell>
                            {/* <TableCell><span className={`px-2 py-[2px] rounded-md ${service.status === "pending" ? "bg-orange-400 text-white" : service.status === "quotation-sent" ? "bg-yellow-500 text-white" : service.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{service.status}</span></TableCell> */}
                            <TableCell className="font-medium">
                                <button onClick={() => setIsInvoiceModalOpen(true)} className='bg-black ml-2 hover:bg-gray-700 text-white rounded-md px-2 py-[3px]'>View Invoice</button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <div className="flex justify-between items-center gap-2">
                    <div className='flex items-center gap-3'>
                        <h2 className='text-xl font-semibold my-5'>Documents</h2>
                        {allDocuments.length > 0 &&
                            <button onClick={() => navigate(`/client/my-services/${params?.myServiceId}/documents`, { state: allDocuments })} className='bg-[#1A56DB] ml-2 flex gap-3 items-center hover:bg-[#0b45c2] text-white rounded-md px-4 py-1 max-[560px]:text-xs'>
                                View All
                            </button>
                        }
                    </div>
                    <TooltipProvider>
                        <Tooltip>
                            <TooltipTrigger>
                                <button onClick={() => setIsAddDocumentModalOpen(true)} className='bg-[#1A56DB] hover:bg-[#0b45c2] ml-2 flex gap-3 items-center text-white rounded-md px-4 py-1 max-[560px]:text-xs'>
                                    Add Document
                                    <FaCloudUploadAlt size={24} />
                                </button>
                            </TooltipTrigger>
                            <TooltipContent>
                                <p>Upload Document</p>
                            </TooltipContent>
                        </Tooltip>
                    </TooltipProvider>

                </div>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6'>
                    {allDocuments.length === 0 && isLoading &&
                        <Spinner size={30} />
                    }

                    {allDocuments.length === 0 && !isLoading &&
                        <DataNotFound name={"Documents"} />
                    }
                    {allDocuments?.map((document, index) => {
                        if (index <= 4) {
                            return (
                                <DocumentComp
                                    key={document._id}
                                    isParse={false}
                                    name={document?.name}
                                    path={document?.path}
                                />
                            )
                        }

                    })}
                </div>

                <div className="flex justify-between items-center gap-2 mt-5">
                    <h2 className='text-xl font-semibold'>Updates</h2>
                    {serviceUpdates.length > 0 &&
                        <button onClick={() => navigate(`/client/my-services/${params?.myServiceId}/updates`, { state: serviceUpdates })} className='bg-[#1A56DB] ml-2 flex gap-3 items-center hover:bg-[#0b45c2] text-white max-[560px]:text-xs rounded-md px-4 py-1'>
                            View All
                        </button>
                    }
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg max-[560px]:text-xs">
                            {/* <TableHead>Client Name</TableHead> */}
                            {/* <TableHead>Status</TableHead> */}
                            <TableHead>Date</TableHead>
                            <TableHead>Document</TableHead>
                            <TableHead>Description</TableHead>
                            {/* <TableHead>Actions</TableHead> */}
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {serviceUpdates?.map((update, index) => {
                            if (index <= 4) {
                                return (
                                    <ServiceUpdateComp
                                        key={update?._id}
                                        update={update}
                                    />

                                )
                            }
                        })}

                    </TableBody>
                </Table>

                {serviceUpdates.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {serviceUpdates.length === 0 && !isLoading &&
                    <DataNotFound name={"Service Updates"} />
                }



                {isAddDocumentModalOpen &&
                    <AddDocumentModal
                        isAddDocumentModalOpen={isAddDocumentModalOpen}
                        setIsAddDocumentModalOpen={setIsAddDocumentModalOpen}
                        getDocuments={getMyServicesDetails}
                        serviceId={service?._id}
                    />}

                {isInvoiceModalOpen &&
                    <InvoiceModal
                        isInvoiceModalOpen={isInvoiceModalOpen}
                        setIsInvoiceModalOpen={setIsInvoiceModalOpen}
                        invoiceId={serviceClientLinkDoc?._id}
                        invoiceDate={serviceClientLinkDoc?.createdAt}
                        name={serviceClientLinkDoc?.clientId?.name}
                        phone={serviceClientLinkDoc?.clientId?.phone}
                        email={serviceClientLinkDoc?.clientId?.email}
                        address={serviceClientLinkDoc?.clientId?.address}
                        serviceCost={serviceClientLinkDoc?.cost}
                        service={service}
                    />
                }

            </div>
        </Wrapper>
    )
}

export default ServiceUpdateDetails