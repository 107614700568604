import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Link, useNavigate, useParams } from 'react-router-dom';

import TestimonialCarousel from '@/components/testimonial-carousel/TestimonialCarousel';
import useGetApiReq from '@/hooks/useGetApiReq';
import { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import parse from "html-react-parser"
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper';
import { FaEye } from "react-icons/fa";
import { Button } from "@/components/ui/button";
import PayLaterModal from "@/admin/components/pay-laterModal/PayLaterModal";

const RequestDetail = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const params = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState([]);
  const [service, setService] = useState("");
  const [client, setClient] = useState("");
  const [isPayLaterModalOpen, setIsPayLaterModalOpen] = useState(false);

  const getRequestDetail = useCallback(async () => {
    fetchData(`/admin/get-request-details/${params?.reqId}`);
  }, [params.reqId, fetchData]);

  useEffect(() => {
    getRequestDetail();
  }, []);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("request detail", res?.data)
      setRequest(res?.data?.request);
      setService(res?.data?.request?.serviceId);
      setClient(res?.data?.request?.clientId);
    }
  }, [res]);



  return (
    <AdminWrapper>
      <div>
        <div className="section-heading-container">
          <h1 className='section-heading'>Request Details</h1>
          {request?.status === "quotation-sent" &&
            <Button onClick={() => setIsPayLaterModalOpen(true)}>Pay Later</Button>
          }
        </div>
        <Table className="mt-4">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Date</TableHead>
              <TableHead>Service Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Chat</TableHead>
              <TableHead>Type</TableHead>
              {/* <TableHead className="w-60">Note</TableHead> */}
              <TableHead className="w-60">Requirement</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>{request?.createdAt && format(new Date(request?.createdAt), "dd/MM/yyyy")}</TableCell>
              <TableCell>{request?.serviceId?.name}</TableCell>
              <TableCell className="w-40">
                <span className={`px-2 py-[2px] rounded-md ${request?.status === "pending" ? "bg-orange-400 text-white" : request?.status === "quotation-sent" ? "bg-yellow-500 text-white" : request?.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request?.status}</span>
              </TableCell>
              {/* <TableCell>
                <Link className="hover:underline" to={`/admin/serviceRequests/${params?.reqId}/chats`}>Chat</Link>
              </TableCell> */}
              <TableCell>
                <button onClick={() => navigate(`/admin/serviceRequests/${params?.reqId}/chats`, { state: { serviceId: request?.serviceId?._id, clientId: request?.clientId?._id, request } })} className="hover:underline">Chat</button>
              </TableCell>
              <TableCell>{request?.type}</TableCell>
              {/* <TableCell>{request?.note}</TableCell> */}
              <TableCell>{request?.requirement && parse(request?.requirement)}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <h2 className='text-xl font-semibold mt-5 mb-3'>Service Details</h2>
        <Table className="mt-4 mb-5">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Icon</TableHead>
              <TableHead className="w-56">Service Id</TableHead>
              <TableHead className="">Name</TableHead>
              <TableHead>Description</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                <div className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                  <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.icon}`} alt="" />
                </div>
              </TableCell>
              <TableCell className="font-medium w-56">{service?._id}</TableCell>
              <TableCell className="font-medium">{service?.name}</TableCell>
              <TableCell className="">{service?.description && parse(service.description)}</TableCell>
              {/* <span className={`px-2 py-[2px] rounded-md ${request.status === "pending" ? "bg-orange-400 text-white" : request.status === "quotation-sent" ? "bg-yellow-500 text-white" : request.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request.status}</span> */}
            </TableRow>
          </TableBody>
        </Table>


        <h2 className='text-xl font-semibold mt-5 mb-3'>Client Details</h2>

        <Table className="mt-4 mb-5">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Name</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow key={client?._id} className="">
              <TableCell>{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
              <TableCell>{client?.phone}</TableCell>
              <TableCell>{client?.email}</TableCell>
              <TableCell>{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</TableCell>
              <TableCell>
                <FaEye onClick={() => navigate(`/admin/clients/${client?._id}`)} size={24} cursor={"pointer"} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <TestimonialCarousel />


        <PayLaterModal
          isPayLaterModalOpen={isPayLaterModalOpen}
          setIsPayLaterModalOpen={setIsPayLaterModalOpen}
          getRequestDetail={getRequestDetail}
        />


      </div>
    </AdminWrapper>
  )
}

export default RequestDetail