import { ChatList, MessageBox, MessageList } from 'react-chat-elements'
import ChatInput from '../chat-input/ChatInput'
import useGetApiReq from '@/hooks/useGetApiReq';
import { useCallback, useEffect, useRef, useState } from 'react';
import usePostApiReq from '@/hooks/usePostApiReq';
import Spinner from '@/components/spinner/Spinner';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import { MdClose, MdMenu } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import parse from "html-react-parser";
import { readCookie } from '@/utils/readCookie';
import noMessages from "@/assets/no-messages.jpg"


const MessageListComp = ({ serviceId, isOpen, setIsOpen, getChatList, type }) => {
  const { res, fetchData, isLoading } = useGetApiReq();
  const { res: acceptRes, fetchData: acceptFetchData, isLoading: acceptIsLoading } = usePostApiReq();

  const user = readCookie("user");

  const [messageList, setMessageList] = useState([]);
  const [service, setService] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOpen = () => {
    setIsOpen(!isOpen);
  }

  const getChats = useCallback(async () => {
    setMessageList([]);
    if (type === "internal") {
      fetchData(`/client/get-my-chats?type=${type}`);
    }
    else if (type === "non-internal" && serviceId) {
      fetchData(`/client/get-my-chats?serviceId=${serviceId}&type=${type}`);
    }
  }, [fetchData, serviceId, type]);

  useEffect(() => {
    // if (serviceId || type) {
    getChats();
    // }
  }, [serviceId, type])

  const data = {
    name: `${user?.name?.first} ${user?.name?.last}`,
    // amount:1,
    transactionId: `T${Date.now()}`,
    MUID: `MUID${Date.now()}`,
    number: user?.phone
  }

  const handleAcceptPrice = useCallback(() => {
    // acceptFetchData("/client/accept-quotation", { serviceId });
    acceptFetchData("/client/accept-payment", { ...data, serviceId });
  }, [acceptFetchData, serviceId]);

  useEffect(() => {
    if (acceptRes?.status === 200 || acceptRes?.status === 201) {
      console.log("pay res", acceptRes);
      window.location.href = acceptRes?.data?.redirectUrl;
      getChatList();
      // navigate("/client/dashboard");
    }
  }, [acceptRes])

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      getChatList();
      setService(res?.data?.service);
      console.log("messageList", res?.data);
      const data = res?.data?.dataSource.map((item, i) => {
        if (item?.buttonFlag === "accept-btn") {
          return {
            ...item, type: "meetingLink", actionButtons:
              [
                {
                  onClickButton: handleAcceptPrice,
                  Component: () => <button className='bg-green-400 text-white px-2 py-[2px] rounded-md'>Pay Now</button>,
                },
              ]
          }
        }
        return item;
      })
      console.log("data", data);
      setMessageList(data);
    }
  }, [res])



  return (
    <div className={`w-full max-[770px]:z-10 max-[770px]:bottom-0 bg-white block ${isOpen ? "block" : "max-[770px]:hidden"} border-2 sticky top-[72px] overflow-y-auto message`}>
      <div className='w-full border-b-2 p-2 sticky top-0 z-10 flex justify-between gap-1 bg-white'>
        {serviceId &&
          <div className='flex flex-col gap-1'>
            <div><span className='font-bold'>Service Id : </span>{service?._id}</div>
            <div><span className='font-bold'>Service Name: </span>{service?.name}</div>
          </div>}
        <div className='min-[770px]:hidden ml-auto'>
          {isOpen ? <MdClose onClick={handleOpen} size={25} className="min-[770px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />
            : <MdMenu onClick={handleOpen} size={25} className="min-[770px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />}
        </div>
      </div>
      {messageList.length === 0 && isLoading &&
        <Spinner size={30} />
      }

      {serviceId && messageList.length === 0 && !isLoading &&
        <DataNotFound name={"Messages"} />
      }

      <div className='w-full overflow-y-auto mt-2 h-full'>
        {!serviceId && messageList.length === 0 && !isLoading && (
          <div className='flex flex-col max-[770px]:justify-center items-center'>
            <img className='w-[50%]' src={noMessages} alt="noMessages" />
            <p className='mt-2'>Select chat to view messages</p>
          </div>
        )}
        {messageList.length > 0 && messageList?.map((item, i) => (
          <MessageBox
            key={i}
            position={item.position}
            title={item.title}
            type={item.type}
            text={parse(item.text)}
            actionButtons={item?.actionButtons}
          />
        ))}
      </div>
      {isOpen && <ChatInput type={type} serviceId={serviceId} getChats={getChats} />}
    </div>
  )
}

export default MessageListComp