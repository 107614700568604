import PartnerWrapper from '@/partner/components/wrapper/Wrapper';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import ServiceRequestComp from '@/partner/components/service-request-comp/ServiceRequestComp';
import DataNotFound from '@/components/data-not-found/DataNotFound';
import useGetApiReq from '@/hooks/useGetApiReq';
import ReactPaginate from 'react-paginate';
import Spinner from '@/components/spinner/Spinner';

const Requests = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const { state } = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    const [requests, setRequests] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);

    const limit = import.meta.env.VITE_LIMIT;

    const getClientDetail = async () => {
        fetchData(`/partner/get-client-details/${params?.clientId}?page=${page}&limit=${limit}`);
    };

    useEffect(() => {
        getClientDetail();
    }, [page]);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("client details", res?.data);
            setRequests(res?.data?.clientInfo?.requests);
            const count = Math.ceil(res?.data?.clientInfo.reqCount / limit)
            setPageCount(count);
        }
    }, [res]);


    return (
        <PartnerWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Requests</h1>
                </div>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead className="w-56">ServiceId</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {requests?.map((request) => (
                            <ServiceRequestComp
                                key={request._id}
                                serviceRequest={request}
                                getServiceRequests={getClientDetail}
                            />
                        ))}
                    </TableBody>
                </Table>

                {requests.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {requests.length === 0 && !isLoading &&
                    <DataNotFound name={"Requests"} />
                }


                {pageCount > 0 &&
                    <div className="mt-10 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}
            </div>
        </PartnerWrapper>
    )
}

export default Requests