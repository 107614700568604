import "./wrapper.css";
import AdminSidebar from "../admin-sidebar/AdminSidebar";
import Header from "../header/Header";

const AdminWrapper = ({ children }) => {

  return (
    <div className="admin">
      <Header />
      <div className="home-container">
        <AdminSidebar />
        <main className="main w-[calc(100%-210px)] h-[calc(100vh-96px)] p-4 pb-5 relative overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default AdminWrapper;