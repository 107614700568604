import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import React from 'react'
import DocumentComp from '../document/DocumentComp'

const MyDocumentsComp = ({ allDocuments, isLoading }) => {
    return (
        <div>
            {allDocuments?.length === 0 && !isLoading &&
                <DataNotFound name={"Documents"} />
            }

            {allDocuments?.map((item, i) => (
                <div key={i} className="mt-10">
                    <h2 className="text-xl font-bold max-[560px]:text-sm">{item?.serviceClientLink?.serviceId?.name} <span className='font-normal text-sm'>{item?._id}</span></h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
                        {/* //singlecomponet */}
                        {item?.documents?.length === 0 && isLoading &&
                            <Spinner size={30} />
                        }

                        {item?.documents?.length === 0 && !isLoading &&
                            <DataNotFound name={"Documents"} />
                        }
                        {item?.documents?.map((document) => (
                            <DocumentComp
                                key={document._id}
                                isParse={false}
                                name={document?.name}
                                path={document?.path}
                            />
                        ))}
                    </div>
                </div>
            ))
            }
        </div>
    )
}

export default MyDocumentsComp