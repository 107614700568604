import TestimonalCard from '@/client/components/testimonal-card/TestimonalCard';

import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"
import testimonals from "@/data/testimonals"


const TestimonialCarousel = () => {
console.log("testimonals",testimonals);
    return (
        <>
            <h2 className="text-xl font-semibold mt-12 mb-8">Testimonials</h2>
            <div className="w-full">
                <Carousel
                    opts={{
                        align: "start",
                    }}
                    className="max-w-[1350px] mx-10"
                >
                    <CarouselPrevious />
                    <CarouselContent>
                        {testimonals?.map((testimonial,i) =>(
                            <CarouselItem key={i} className="basis-80">
                            <TestimonalCard testimonial={testimonial} />
                        </CarouselItem>
                        ))}
                    </CarouselContent>
                    <CarouselNext />
                </Carousel>


            </div>
        </>
    )
}

export default TestimonialCarousel