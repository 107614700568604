import DocumentComp from '@/client/components/document/DocumentComp'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Wrapper from '@/components/wrapper/Wrapper'
import { useLocation, useNavigate } from 'react-router-dom';

const Documents = () => {
    const { state: allDocuments } = useLocation();

    const navigate = useNavigate();

    if (!allDocuments) {
        navigate(-1);
    }

    return (
        <Wrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Documents</h1>
                </div>

                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4'>
                    {allDocuments?.length === 0 &&
                        <DataNotFound name={"Documents"} />
                    }
                    {allDocuments?.map((document) => (
                        <DocumentComp
                            key={document._id}
                            isParse={false}
                            name={document?.name}
                            path={document?.path}
                        />
                    ))}
                </div>
            </div>
        </Wrapper>
    )
}

export default Documents