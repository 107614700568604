import { Link, useLocation } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AiFillDashboard } from "react-icons/ai";
import { MdFeedback } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { IoDocumentsSharp } from "react-icons/io5";
import { BsChatSquareDotsFill } from "react-icons/bs";
import "./bottom-nav.css";

const BottomNav = () => {
    const { isOpen } = useSelector((state) => state.sidebar);
    const {flag} = useSelector((state) => state.chat);
    const {pathname} = useLocation();

    return (
        <ul className="flex justify-between items-center gap-3 min-[770px]:hidden w-full sticky bottom-0 left-0 bg-[#F9FAFB] -mx-6 -mb-6 py-2 px-4">
            <Link className={`item ${pathname.includes("dashboard") && "text-[#1A56DB]"}`} to={"/client/dashboard"}>
                <span>Dashboard</span>
                <AiFillDashboard size={20} />
            </Link>

            {/* <Link className="link" to={"/admin/dashboard"}>Dashboard</Link> */}

            <Link className={`item ${pathname.includes("requests") && "text-[#1A56DB]"}`} to={"/client/requests"}>
                <span>Pending Requests</span>
                <MdFeedback size={20} />
            </Link>
            <Link className={`item ${pathname.includes("my-services") && "text-[#1A56DB]"}`} to={"/client/my-services"}>
                <span>My Services</span>
                <GrServices size={20} />
            </Link>
            <Link className={`item ${pathname.includes("mydocuments") && "text-[#1A56DB]"}`} to={"/client/mydocuments"}>
                <span>My Documents</span>
                <IoDocumentsSharp size={20} />
            </Link>
            <Link className={`item ${pathname.includes("chats") && "text-[#1A56DB]"}`} to={"/client/chats"}>
               <span>My Chats</span>
                <BsChatSquareDotsFill size={20} />
            </Link>
        </ul>
    )
}

export default BottomNav