import DataNotFound from '@/components/data-not-found/DataNotFound';
import useGetApiReq from '@/hooks/useGetApiReq';
import Folder from '@/partner/components/folder/Folder';
import PartnerWrapper from '@/partner/components/wrapper/Wrapper';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

const Drive = () => {
    const { res: folderRes, fetchData: fetchFolderData, isLoading: isFolderLoading } = useGetApiReq();

    const params = useParams();
    const [allFolders, setAllFolders] = useState([]);

    const [folderIdAndType, setFolderIdAndType] = useState({
        folderType: "",
        fId: "",
        folderName: ""
    });

    const getFolders = () => {
        fetchFolderData(`/partner/get-folders/${params?.clientId}`);
    }

    useEffect(() => {
        if (params?.clientId) {
            getFolders();
        }
    }, [params?.clientId])


    useEffect(() => {
        if (folderRes?.status === 200 || folderRes?.status === 201) {
            console.log("folders", folderRes);
            setAllFolders(folderRes?.data?.folders);
        }
    }, [folderRes])

    return (
        <PartnerWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Drive</h1>
                </div>
                
                {allFolders.length === 0 && !isFolderLoading &&
                    <DataNotFound name={"Folders"} />
                }

                <div className='w-[450px]'>
                    {allFolders?.map((folder) => (
                        <Folder
                            key={folder?._id}
                            folder={folder}
                            folderIdAndType={folderIdAndType}
                            setFolderIdAndType={setFolderIdAndType}
                            isDownload={true}
                        />
                    ))}
                </div>
            </div>
        </PartnerWrapper>
    )
}

export default Drive