import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"


const DocumentView = ({ document, setIsDocumentViewModalOpen, isDocumentViewModalOpen }) => {
    return (
        <Dialog open={isDocumentViewModalOpen} onOpenChange={() => setIsDocumentViewModalOpen(!isDocumentViewModalOpen)}>
            <DialogContent className="sm:max-w-[825px]">
                <iframe className="w-full h-[80vh] m-2" src={`${import.meta.env.VITE_IMG_URL}/${document}`} />
            </DialogContent>
        </Dialog>

    )
}

export default DocumentView