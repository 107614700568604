import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper';
import Service from '@/admin/components/service/Service';
import TestimonialCarousel from '@/components/testimonial-carousel/TestimonialCarousel';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import useGetApiReq from '@/hooks/useGetApiReq';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import parse from "html-react-parser";

const ServiceDetail = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const params = useParams();
  const [service, setService] = useState("");
  const icon = `${import.meta.env.VITE_IMG_URL}/${service?.icon}`;

  const getServiceDetail = useCallback(async () => {
    fetchData(`/admin/get-service-details/${params?.serviceId}`);
  }, [params.serviceId, fetchData]);

  useEffect(() => {
    getServiceDetail();
  }, []);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("service detail", res?.data)
      setService(res?.data?.service);
    }
  }, [res]);

  return (
    <AdminWrapper>
      <div>
        <Table className="mt-4">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Icon</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Category</TableHead>
              <TableHead>Actions</TableHead>
              {/* <TableHead>Description</TableHead> */}
            </TableRow>
          </TableHeader>
          <TableBody>
            <Service
              getServices={getServiceDetail}
              service={service}
              showDesc={false}
            />
          </TableBody>
        </Table>


        <p className="max-[560px]:text-sm">{service?.description && parse(service?.description)}</p>

        <TestimonialCarousel />
      </div>
    </AdminWrapper>
  )
}

export default ServiceDetail