import { TableCell, TableRow } from "@/components/ui/table"
import { useEffect, useState } from "react";
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import AddSubAdminModal from "../add-subAdminModal/AddSubAdminModal";
import DeleteAlert from "../delete-alert/DeleteAlert";
import useDeleteApiReq from "@/hooks/useDeleteApiReq";

const Admin = ({ admin, getSubAdmins }) => {
    const { res, fetchData, isLoading } = useDeleteApiReq();

    const [isUpdateSubAdminModalOpen, setIsUpdateSubAdminModalOpen] = useState(false);
    const [isDeleteSubAdminModalOpen, setIsDeleteSubAdminModalOpen] = useState(false);

    const handleDelete = async () => {
        fetchData(`/admin/delete-subadmin/${admin?._id}`);
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getSubAdmins();
        }
    }, [res]);

    return (
        <>
            <TableRow>
                <TableCell>{admin?.name}</TableCell>
                <TableCell>{admin?.email}</TableCell>
                <TableCell>{admin?.phone}</TableCell>
                <TableCell className="flex gap-3 items-center">
                    <FaEdit onClick={() => setIsUpdateSubAdminModalOpen(true)} size={22} cursor={"pointer"} />
                    <MdDelete onClick={() => setIsDeleteSubAdminModalOpen(true)} className="text-red-400" size={22} cursor={"pointer"} />
                </TableCell>
            </TableRow>

            {isUpdateSubAdminModalOpen &&
                <AddSubAdminModal
                    isAddSubAdminModalOpen={isUpdateSubAdminModalOpen}
                    setIsAddSubAdminModalOpen={setIsUpdateSubAdminModalOpen}
                    getSubAdmins={getSubAdmins}
                    subAdmin={admin}
                />
            }

            {isDeleteSubAdminModalOpen &&
                <DeleteAlert
                    isDeleteModalOpen={isDeleteSubAdminModalOpen}
                    setIsDeleteModalOpen={setIsDeleteSubAdminModalOpen}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Admin