import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'


const AddFolderModal = ({ isAddFolderModalOpen, setIsAddFolderModalOpen, getFolders, clientId }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [folderName, setFolderName] = useState("");

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFolderName(value);
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!folderName) {
            toast.error("Folder name is required");
            return;
        }


        fetchData("/admin/add-folder", { name: folderName, clientId });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddFolderModalOpen(false)
            getFolders();
        }
    }, [res])


    return (
        <Dialog open={isAddFolderModalOpen} onOpenChange={() => setIsAddFolderModalOpen(!isAddFolderModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Add Folder</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="folderName">
                            Folder Name
                        </Label>
                        <Input placeholder="Enter Folder Name" onChange={handleOnChange} value={folderName} name="folderName" id="folderName" className="col-span-3" />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddFolderModal