import { format } from "date-fns";
import { TableCell, TableRow } from "@/components/ui/table";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";
import { useState } from "react";
import AcceptServiceModal from "../accept-serviceModal/AcceptServiceModal";

const ServiceRequestComp = ({ serviceRequest, getServiceRequests }) => {
    const navigate = useNavigate();

    const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

    return (
        <>
            <TableRow>
                <TableCell className="font-medium w-56 cursor-pointer">{serviceRequest?.serviceId?._id ? serviceRequest?.serviceId?._id : serviceRequest?.serviceId}</TableCell>
                {serviceRequest?.clientId?.name && <TableCell>{`${serviceRequest?.clientId?.name?.first} ${serviceRequest?.clientId?.name?.last}`}</TableCell>}
                <TableCell>{serviceRequest?.createdAt && format(new Date(serviceRequest.createdAt), 'dd/MM/yyyy')}</TableCell>
                <TableCell>{serviceRequest?.type}</TableCell>
                <TableCell>
                    <span className={`px-2 py-[2px] rounded-md ${serviceRequest?.status === "pending" ? "bg-orange-400 text-white" : serviceRequest.status === "quotation-sent" ? "bg-yellow-500 text-white" : serviceRequest.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{serviceRequest.status}</span>
                </TableCell>
                <TableCell className="flex gap-6 items-center">
                    <FaEye onClick={() => navigate(`/partner/requests/${serviceRequest?._id}`)} size={22} cursor={"pointer"} />
                    {serviceRequest?.status === "quotation-sent" && <button onClick={() => setIsAcceptModalOpen(true)} className="px-2 rounded-md disabled:bg-gray-600 py-[2px] text-white bg-black">Accept</button>}
                </TableCell>
            </TableRow >

            <AcceptServiceModal
                isAcceptModalOpen={isAcceptModalOpen}
                setIsAcceptModalOpen={setIsAcceptModalOpen}
                requestId={serviceRequest?._id}
                getRequestDetail={getServiceRequests}
                price={serviceRequest?.cost}
                note={serviceRequest?.note}
            />
        </>
    )
}

export default ServiceRequestComp