import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Textarea } from '@/components/ui/textarea'
import usePatchApiReq from '@/hooks/usePatchApiReq'
import usePostApiReq from '@/hooks/usePostApiReq'
import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaEye, FaEyeSlash } from 'react-icons/fa'


const AddSubAdminModal = ({ isAddSubAdminModalOpen, setIsAddSubAdminModalOpen, getSubAdmins, subAdmin }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePatchApiReq();

    const [subAdminInfo, setSubAdminInfo] = useState({
        name: subAdmin?.name || "",
        phone: subAdmin?.phone || "",
        email: subAdmin?.email || "",
        password: "",
    })
    const [isShowPassword, setIsShowPassword] = useState(false);

    const [permissions, setPermissions] = useState({
        dashboard: subAdmin?.permissions?.dashboard || "none",
        categories: subAdmin?.permissions?.categories || "none",
        serviceRequests: subAdmin?.permissions?.serviceRequests || "none",
        partners: subAdmin?.permissions?.partners || "none",
        clients: subAdmin?.permissions?.clients || "none",
        serviceUpdates: subAdmin?.permissions?.serviceUpdates || "none",
        chats: subAdmin?.permissions?.chats || "none",
        subAdmins: subAdmin?.permissions?.subAdmins || "none",
        purchasedServices: subAdmin?.permissions?.purchasedServices || "none",
    });


    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setSubAdminInfo({ ...subAdminInfo, [name]: value });
    };

    const handlePermissionChange = (key, value) => {
        setPermissions({ ...permissions, [key]: value });
    };

    console.log("subadmin", subAdminInfo);

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!subAdminInfo.name
            || !subAdminInfo.email
            || !subAdminInfo.phone
            || (!subAdmin && !subAdminInfo.password)
            || !permissions.categories
            || !permissions.chats
            || !permissions.clients
            || !permissions.dashboard
            || !permissions.partners
            || !permissions.serviceRequests
            || !permissions.serviceUpdates
            || !permissions.subAdmins
        ) {
            toast.error("All fields are required");
            return;
        }

        if (subAdmin) {
            fetchUpdateData("/admin/update-subadmin", { ...subAdminInfo, permissions, adminId: subAdmin?._id });
        } else {
            fetchData("/admin/add-subadmin", { ...subAdminInfo, permissions });
        }

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddSubAdminModalOpen(false);
            getSubAdmins();
        }
    }, [res])

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            setIsAddSubAdminModalOpen(false);
            getSubAdmins();
        }
    }, [updateRes])


    return (
        <Dialog open={isAddSubAdminModalOpen} onOpenChange={() => setIsAddSubAdminModalOpen(!isAddSubAdminModalOpen)}>
            <DialogContent className="sm:max-w-[625px] h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add SubAdmin</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input placeholder="Enter Name" onChange={handleOnChange} value={subAdminInfo.name} name="name" id="name" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="email">
                            Email
                        </Label>
                        <Input type="email" placeholder="Enter Email" onChange={handleOnChange} value={subAdminInfo.email} name="email" id="email" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="phone">
                            Phone
                        </Label>
                        <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={subAdminInfo.phone} name="phone" id="phone" className="col-span-3" />
                    </div>
                    {!subAdmin &&
                        <div className="input-container relative">
                            <Label htmlFor="password">
                                Password
                            </Label>
                            <Input type={isShowPassword ? "text" : "password"} placeholder="Enter Password" onChange={handleOnChange} value={subAdminInfo.password} name="password" id="password" className="col-span-3" />
                            {isShowPassword ?
                            <FaEyeSlash onClick={() => setIsShowPassword(false)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                            : <FaEye onClick={() => setIsShowPassword(true)} className='absolute text-gray-600 right-3 bottom-3 cursor-pointer text-lg' />
                        }
                        </div>}

                    <div className="input-container">
                        <Label htmlFor="permissions">
                            Permissions
                        </Label>
                        {Object.keys(permissions).map((key, index) => (
                            <div key={key} className="flex justify-between gap-3 items-center">
                                <p className='uppercase'>{key}</p>
                                <div className="flex gap-3">
                                    <div className="flex gap-2 items-center">
                                        <input
                                            onChange={(e) => handlePermissionChange(key, "read")}
                                            type="radio"
                                            name={`${key}-read`}
                                            id={`${key}-read`}
                                            value={permissions[key]}
                                            checked={permissions[key] === "read"}
                                            className="w-4 h-4"
                                        />
                                        <label htmlFor={`${key}-read`}>read</label>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <input
                                            onChange={(e) => handlePermissionChange(key, "write")}
                                            type="radio"
                                            name={`${key}-write`}
                                            id={`${key}-write`}
                                            value={permissions[key]}
                                            checked={permissions[key] === "write"}
                                            className="w-4 h-4"
                                        />
                                        <label htmlFor={`${key}-write`}>write</label>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <input
                                            onChange={(e) => handlePermissionChange(key, "none")}
                                            type="radio"
                                            name={`${key}-none`}
                                            id={`${key}-none`}
                                            value={permissions[key]}
                                            checked={permissions[key] === "none"}
                                            className="w-4 h-4"
                                        />
                                        <label htmlFor={`${key}-none`}>none</label>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <DialogFooter>
                        <Button type="submit">
                            {(isLoading || isUpdateLoading) ?
                                <Spinner />
                                : subAdmin ? "Update" : "Add"
                            }
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddSubAdminModal