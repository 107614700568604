import DataNotFound from '@/components/data-not-found/DataNotFound';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import parse from "html-react-parser"
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper';

const PurchasedServices = () => {
    const { state: services } = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    if (!services) {
        navigate(-1);
    }
    return (
        <AdminWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Purchased Services</h1>
                </div>

                <Table className="mt-5">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead>Icon</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Category</TableHead>
                            <TableHead className="w-40">Payment Status</TableHead>
                            <TableHead>Description</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {services?.map((service) => (
                            <TableRow key={service?.serviceId?._id}>
                                <TableCell>
                                    <div onClick={() => navigate(`/admin/clients/${params.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                                        <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} alt="service" />
                                    </div>
                                </TableCell>
                                <TableCell>
                                    <h3 onClick={() => navigate(`/admin/clients/${params.clientId}/service/${service?._id}`, { state: service?.serviceId })} className="text-lg cursor-pointer font-semibold">{service?.serviceId?.name}</h3>
                                </TableCell>
                                <TableCell>{service?.serviceId?.categoryId?.name}</TableCell>
                                <TableCell className="w-40 text-center">
                                    <span className={`px-4 py-[2px] rounded-md ${service?.paymentStatus === "due" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{service?.paymentStatus}</span>
                                </TableCell>
                                <TableCell className="w-80 line-clamp-2">{service?.serviceId?.description && parse(service?.serviceId?.description)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>

                {services.length === 0 &&
                    <DataNotFound name={"Services"} />
                }
            </div>
        </AdminWrapper>
    )
}

export default PurchasedServices