import Header from "../header/Header";
import Sidebar from "../sidebar/Sidebar";
import "./wrapper.css";

const PartnerWrapper = ({ children }) => {

  return (
    <div className="partner">
      <Header />
      <div className="home-container">
        <Sidebar />
        <main className="main w-[calc(100%-210px)] h-[calc(100vh-96px)] p-4 pb-5 relative overflow-y-auto">
          {children}
        </main>
      </div>
    </div>
  );
};

export default PartnerWrapper;