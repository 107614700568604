import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import usePatchApiReq from '@/hooks/usePatchApiReq'
import usePostApiReq from '@/hooks/usePostApiReq'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom'

const AddServiceModal = ({ isAddServiceModalOpen, setIsAddServiceModalOpen, getServices, service }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const { res: updateServiceRes, fetchData: fetchUpdateService, isLoading: isUpdateServiceLoading } = usePostApiReq();
    const params = useParams();

    const [serviceInfo, setServiceInfo] = useState({
        name: service?.name || "",
        icon: service?.icon || "",
        previewIcon: `${import.meta.env.VITE_IMG_URL}/${service?.icon}` || "",
    })
    const [description, setDescription] = useState(service?.description || "");
    const [isPreview, setIsPreview] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setServiceInfo({ ...serviceInfo, [name]: value });
    };

    const getImage = (event) => {
        event.preventDefault();
        console.log("event: ", event);
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setIsPreview(true);
                setServiceInfo({
                    ...serviceInfo,
                    previewIcon: this.result,
                    icon: uploadedImage,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!description || !serviceInfo.icon || !serviceInfo.name) {
            toast.error("All fields are required");
            return;
        }

        const formdata = new FormData();
        formdata.append("name", serviceInfo.name);
        formdata.append("icon", serviceInfo.icon);
        formdata.append("description", description);
        if (params?.categoryId) {
            formdata.append("categoryId", params?.categoryId);
        }

        if (service) {
            fetchUpdateService(`/admin/update-service/${service?._id}`, formdata);
        }
        else {
            fetchData("/admin/add-service", formdata);
        }

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddServiceModalOpen(false)
            getServices();
        }
    }, [res])

    useEffect(() => {
        if (updateServiceRes?.status === 200 || updateServiceRes?.status === 201) {
            setIsAddServiceModalOpen(false)
            getServices();
        }
    }, [updateServiceRes])


    return (
        <Dialog open={isAddServiceModalOpen} onOpenChange={() => setIsAddServiceModalOpen(!isAddServiceModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>{service ? "Update" : "Add"} Service</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="icon">
                            Icon
                        </Label>
                        {serviceInfo.previewIcon &&
                            <Avatar>
                                <AvatarImage src={serviceInfo.previewIcon} alt="service" />
                                <AvatarFallback>service</AvatarFallback>
                            </Avatar>
                        }
                        <Input type="file" onChange={(e) => getImage(e)} name="icon" id="icon" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input onChange={handleOnChange} value={serviceInfo.name} name="name" id="name" className="col-span-3" />
                    </div>
                    <div className="input-container">
                        <Label htmlFor="description">
                            Description
                        </Label>
                        <ReactQuill theme="snow" value={description} onChange={setDescription} />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : service ? "Update" : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddServiceModal