import DataNotFound from "@/components/data-not-found/DataNotFound"
import Spinner from "@/components/spinner/Spinner"
import { ChatItem } from "react-chat-elements"
import ChatItemComp from "../chatItem/ChatItem"
import { MdClose, MdMenu } from "react-icons/md"

const Chatlist = ({ setSelected, selected, isOpen, chatList, isLoading, setType, setIsOpen }) => {
    const handleOpen = () => {
        setIsOpen(true);
    }

    const handleClick = (event) => {
        event.preventDefault();
        const serviceId = event.currentTarget.getAttribute("data-serviceid");
        const type = event.currentTarget.getAttribute("data-type");
        setSelected(serviceId)
        setType(type);
        // setIsOpen(false);
        handleOpen();
    }



    return (
        <div className={`w-96 max-[770px]:w-full flex flex-col gap-2 sticky top-[72px] h-full border-2 ${!isOpen?  "block" : "max-[770px]:hidden"}`}>
            <div className="flex justify-end items-center px-4 py-2 min-[770px]:hidden">
                {isOpen && <MdClose onClick={handleOpen} size={25} className="min-[770px]:hidden h-[100vh-100px] ml-4 cursor-pointer" />}
            </div>
            {chatList?.length === 0 && isLoading &&
                <Spinner size={30} />
            }

            {chatList?.length === 0 && !isLoading &&
                <DataNotFound name={"Chats"} />
            }
            {chatList.length > 0 &&
                chatList.map((item, i) => (
                    <ChatItemComp
                        key={i}
                        item={item}
                        selected={selected}
                        handleClick={handleClick}
                        i={i}
                    />

                ))}
        </div>
    )
}

export default Chatlist