import './App.css'
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
import ErrorModal from './components/ErrorModal'
import { handleErrorModal } from "./store/slices/errorSlice";
import { Dialog } from './components/ui/dialog';
import NotFound from './pages/not-found/NotFound';
import Home from './pages/home/Home';
import { Toaster } from 'react-hot-toast';

import ClientLogin from './client/pages/login/Login';
import ClientSignup from './client/pages/signup/Signup';
import ClientDashboard from './client/pages/dashboard/Dashboard';
import ClientServiceDetail from './client/pages/service-detail/ServiceDetail';
import ClientServices from './client/pages/services/Services';
import MyServices from './client/pages/myservices/MyServices';
import MyDocuments from './client/pages/mydocuments/MyDocuments';
import Chats from './client/pages/chats/Chats';
import ServiceUpdateDetails from './client/pages/service-update-details/ServiceUpdateDetails';
import PendingReqs from './client/pages/pendingReqs/PendingReqs';
import RequestDetail from './client/pages/request-detail/RequestDetail';
import ForgotPassword from './client/pages/forgot-password/ForgotPassword';
import Updates from './client/pages/updates/Updates';
import Documents from './client/pages/documents/Documents';
import ClientProtectedRoute from './client/components/client-protected-route/ClientProtectedRoute';
import ClosedChats from './client/pages/closed-chats/ClosedChats';
import Success from './client/pages/success/Success';
import Failure from './client/pages/failure/Failure';

import PartnerLogin from './partner/pages/login/Login';
import PartnerDashboard from './partner/pages/dashboard/Dashboard';
import PartnerClients from './partner/pages/clients/Clients';
import PartnerClientsDetail from './partner/pages/client-details/ClientDetails';
import PartnerServiceRequest from './partner/pages/service-request/ServiceRequest';
import PartnerRequestDetail from './partner/pages/request-detail/RequestDetail';
import PartnerForgotPassword from './partner/pages/forgot-password/ForgotPassword';
import PartnerClientServiceDetails from './partner/pages/client-service-details/ClientServiceDetails';
import Requests from './partner/pages/requests/Requests';
import PurchasedServices from './partner/pages/purchased-services/PurchasedServices';
import PartnerDocuments from './partner/pages/documents/Documents';
import PartnerUpdates from './partner/pages/updates/Updates';
import PartnerProtectedRoute from './partner/components/partner-protected-route/PartnerProtectedRoute';
import PartnerDrive from './partner/pages/drive/Drive';
import PartnerSuccess from './partner/pages/success/Success';
import PartnerFailure from './partner/pages/failure/Failure';

import AdminLogin from './admin/pages/login/Login';
import AdminDashboard from './admin/pages/dashboard/Dashboard';
import Services from './admin/pages/services/Services';
import ServiceRequest from './admin/pages/service-request/ServiceRequest';
import Categories from './admin/pages/categories/Categories';
import Partners from './admin/pages/partners/Partners';
import Clients from './admin/pages/clients/Clients';
import ClientDetails from './admin/pages/client-details/ClientDetails';
import ServiceUpdate from './admin/pages/service-update/ServiceUpdate';
import AdminChats from './admin/pages/chats/Chats';
import ServiceDetail from './admin/pages/service-detail/ServiceDetail';
import AdminRequestDetail from './admin/pages/request-detail/RequestDetail';
import PartnerDetails from './admin/pages/partner-details/PartnerDetails';
import SubAdmins from './admin/pages/sub-admins/SubAdmins';
import AdminProtectedRoute from './admin/components/admin-protected-route/AdminProtectedRoute';
import UnAuthorizationAlert from './admin/components/unauthorization-alert/UnAuthorizationAlert';
import ClientServiceDetails from './admin/pages/client-service-details/ClientServiceDetails';
import BackdropLoader from './components/backdrop-loader/BackdropLoader';
import AdminRequests from './admin/pages/requests/Requests';
import AdminPurchasedServices from './admin/pages/purchased-services/PurchasedServices';
import AdminDocuments from './admin/pages/documents/Documents';
import AdminUpdates from './admin/pages/updates/Updates';
import AdminPartnerRequests from './admin/pages/admin-partner-requests/AdminPartnerRequests';
import AdminClients from './admin/pages/admin-clients/AdminClients';
import AdminClosedChats from './admin/pages/closed-chats/ClosedChats';
import PurchasedServicesPage from './admin/pages/purchased-services-page/PurchasedServicesPage';
import Drive from './admin/pages/drive/Drive';
import { readCookie } from './utils/readCookie';
import { useEffect } from 'react';

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation();

  const { isErrorModalOpen, message, isUnautorizedModalOpen } = useSelector((state) => state.error);
  const { isLoading } = useSelector((state) => state.loading);

  const user = readCookie("user");
  console.log("user", user);

  useEffect(() => {
    if (user) {
      if (user?.abc === "client" && (pathname.includes("/admin") || pathname.includes("/partner"))) {
        navigate("/client/dashboard");
      } else if (user?.abc === "partner" && (pathname.includes("/admin") || pathname.includes("/client"))) {
        navigate("/partner/dashboard");
      } else if (user?.abc === "admin" && (pathname.includes("/client") || pathname.includes("/partner"))) {
        navigate("/admin/dashboard");
      }
    }
  }, [])
  


  return (
    <>
      <Dialog open={isErrorModalOpen} onOpenChange={() => dispatch(handleErrorModal({ isOpen: false, message: "" }))}>
        <ErrorModal message={message} />
      </Dialog>

      <UnAuthorizationAlert
        authorizationAlertModalOpen={isUnautorizedModalOpen}
      />

      {isLoading && <BackdropLoader />}


      <Routes>
        {/* <Route exact path="/" element={<Home />} /> */}
        {/* <Route path="/signup" element={<Signup />} /> */}

        {/* Client Routes */}

        <Route exact path="/" element={<ClientLogin />} />

        <Route path="/client/signup" element={<ClientSignup />} />
        <Route element={<ClientProtectedRoute />}>

          <Route path="/client/dashboard" element={<ClientDashboard />} />
          <Route path="/client/dashboard/:categoryId" element={<ClientServices />} />
          <Route path="/client/service/:serviceId" element={<ClientServiceDetail />} />

          <Route path="/client/my-services" element={<MyServices />} />
          <Route path="/client/my-services/:myServiceId" element={<ServiceUpdateDetails />} />
          <Route path="/client/my-services/:myServiceId/updates" element={<Updates />} />
          <Route path="/client/my-services/:myServiceId/documents" element={<Documents />} />

          <Route path="/client/requests" element={<PendingReqs />} />
          <Route path="/client/requests/:reqId" element={<RequestDetail />} />
          <Route path="/client/requests/:reqId/chats" element={<ClosedChats />} />

          <Route path="/client/mydocuments" element={<MyDocuments />} />

          <Route path="/client/chats" element={<Chats />} />

          <Route path="/client/success" element={<Success />} />
          <Route path="/client/failure" element={<Failure />} />
        </Route>

        <Route path="/client/forgot-password" element={<ForgotPassword />} />



        {/* Partner Routes */}
        <Route path="/partner/login" element={<PartnerLogin />} />

        <Route element={<PartnerProtectedRoute />}>
          <Route path="/partner/dashboard" element={<PartnerDashboard />} />

          <Route path="/partner/clients" element={<PartnerClients />} />
          <Route path="/partner/clients/:clientId" element={<PartnerClientsDetail />} />
          <Route path="/partner/clients/:clientId/drive" element={<PartnerDrive />} />
          <Route path="/partner/clients/:clientId/requests" element={<Requests />} />
          <Route path="/partner/clients/:clientId/purchased-services" element={<PurchasedServices />} />
          <Route path="/partner/clients/:clientId/service/:serviceId" element={<PartnerClientServiceDetails />} />
          <Route path="/partner/clients/:clientId/service/:serviceId/documents" element={<PartnerDocuments />} />
          <Route path="/partner/clients/:clientId/service/:serviceId/updates" element={<PartnerUpdates />} />

          <Route path="/partner/requests" element={<PartnerServiceRequest />} />
          <Route path="/partner/requests/:reqId" element={<PartnerRequestDetail />} />

          <Route path="/partner/success" element={<PartnerSuccess />} />
          <Route path="/partner/failure" element={<PartnerFailure />} />
        </Route>

        <Route path="/partner/forgot-password" element={<PartnerForgotPassword />} />


        {/* Admin Routes */}

        <Route path="/admin/login" element={<AdminLogin />} />

        <Route element={<AdminProtectedRoute />} >
          <Route path="/admin/dashboard" element={<AdminDashboard />} />

          <Route path="/admin/categories" element={<Categories />} />
          <Route path="/admin/dashboard/category/:categoryId" element={<Services />} />

          <Route path="/admin/categories/:serviceId" element={<ServiceDetail />} />

          <Route path="/admin/serviceRequests" element={<ServiceRequest />} />
          <Route path="/admin/serviceRequests/:reqId" element={<AdminRequestDetail />} />
          <Route path="/admin/serviceRequests/:reqId/chats" element={<AdminClosedChats />} />

          <Route path="/admin/partners" element={<Partners />} />
          <Route path="/admin/partners/:partnerId" element={<PartnerDetails />} />
          <Route path="/admin/partners/:partnerId/clients" element={<AdminClients />} />
          <Route path="/admin/partners/:partnerId/requests" element={<AdminPartnerRequests />} />

          <Route path="/admin/clients" element={<Clients />} />
          <Route path="/admin/clients/:clientId" element={<ClientDetails />} />
          <Route path="/admin/clients/:clientId/drive" element={<Drive />} />
          <Route path="/admin/clients/:clientId/requests" element={<AdminRequests />} />
          <Route path="/admin/clients/:clientId/purchased-services" element={<AdminPurchasedServices />} />
          <Route path="/admin/clients/:clientId/service/:serviceId" element={<ClientServiceDetails />} />
          <Route path="/admin/clients/:clientId/service/:serviceId/documents" element={<AdminDocuments />} />
          <Route path="/admin/clients/:clientId/service/:serviceId/updates" element={<AdminUpdates />} />

          <Route path="/admin/serviceUpdates" element={<ServiceUpdate />} />

          <Route path="/admin/purchased-services" element={<PurchasedServicesPage />} />

          <Route path="/admin/chats" element={<AdminChats />} />

          <Route path="/admin/subAdmins" element={<SubAdmins />} />
        </Route>


        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Toaster />
    </>
  )
}

export default App


