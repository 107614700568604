import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { useRef } from "react";
import { useDownloadExcel } from 'react-export-table-to-excel';
import parse from "html-react-parser";

const ServiceClientLinkDataModal = ({ isDataModalOpen, setIsDataModalOpen, data }) => {
  const tableRef = useRef(null);

  const { onDownload } = useDownloadExcel({
    filename: "Purchased Services Data table",
    sheet: "Purchased Services Data",
    currentTableRef: tableRef?.current
  })

  return (
    <Dialog open={isDataModalOpen} onOpenChange={() => setIsDataModalOpen(!isDataModalOpen)}>
      <DialogContent className="sm:max-w-[825px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Purchased Services Data</DialogTitle>
        </DialogHeader>
        {data?.length === 0 &&
          <p className="">No purchased services found.</p>
        }
        {data?.length > 0 &&
          <div className="grid gap-4 py-4">
            <Table ref={tableRef}>
              <TableHeader>
                <TableRow className="text-lg">
                  <TableHead className="w-28">Client Name</TableHead>
                  <TableHead className="w-28">Service Name</TableHead>
                  <TableHead>Cost</TableHead>
                  <TableHead>Payment Status</TableHead>
                  <TableHead>Type</TableHead>
                  <TableHead>Status</TableHead>
                  <TableHead>Note</TableHead>
                  <TableHead>requirement</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {data?.map((info) => (
                  <TableRow key={info?._id}>
                    <TableCell className="w-28">{`${info?.clientId?.name?.first} ${info?.clientId?.name?.last}`}</TableCell>
                    <TableCell className="w-28">{info?.serviceId?.name}</TableCell>
                    <TableCell>{info?.cost}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-md text-white ${info?.paymentStatus === "paid" ? "bg-green-500" : "bg-yellow-400"}`}>{info?.paymentStatus}</span>
                    </TableCell>
                    <TableCell>{info?.type}</TableCell>
                    <TableCell>
                      <span className={`px-2 py-1 rounded-md text-white ${info?.status === "pending" ? "bg-orange-400" : "bg-green-500"}`}>{info?.status}</span>
                    </TableCell>
                    <TableCell>{info?.note}</TableCell>
                    <TableCell>{parse(info?.requirement)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>

            <DialogFooter>
              <Button onClick={onDownload} type="button">Export to Excel</Button>
            </DialogFooter>
          </div>}
      </DialogContent>
    </Dialog>
  )
}

export default ServiceClientLinkDataModal