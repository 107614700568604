import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { Textarea } from "@/components/ui/textarea"
import usePostApiReq from '@/hooks/usePostApiReq'
import { readCookie } from '@/utils/readCookie'


const AcceptServiceModal = ({ isAcceptModalOpen, setIsAcceptModalOpen, requestId, getRequestDetail,price,note }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const user = readCookie("user");

    const data = {
        name: `${user?.name?.first} ${user?.name?.last}`,
        // amount:1,
        transactionId: `T${Date.now()}`,
        MUID: `MUID${Date.now()}`,
        number: user?.phone
      }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        // fetchData("/partner/accept-quotation", { requestId });
        fetchData("/partner/accept-payment", {...data, requestId });

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAcceptModalOpen(false);
            window.location.href = res?.data?.redirectUrl;
            getRequestDetail();
            console.log("payment response",res);
        }
    }, [res])


    return (
        <Dialog open={isAcceptModalOpen} onOpenChange={() => setIsAcceptModalOpen(!isAcceptModalOpen)}>
            <DialogContent className="sm:max-w-[525px]">
                <DialogHeader>
                    <DialogTitle>Accept Service</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="price">
                            Price
                        </Label>
                        <p>₹{price}</p>
                    </div>
                    <div className="input-container">
                        <Label htmlFor="note">
                            Note
                        </Label>
                        <p>{note}</p>
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Accept"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AcceptServiceModal