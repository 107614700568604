import Sidebar from "@/components/sidebar/Sidebar";
import "./wrapper.css";
import Header from "@/components/header/Header";
import BottomNav from "@/client/components/bottom-nav/BottomNav";

const Wrapper = ({ children }) => {

  return (
    <div className="client">
      <Header />
      <div className="home-container">
        <Sidebar />
        <main className={`main w-[calc(100%-210px)] h-[calc(100vh-96px)] p-4 pb-5 relative overflow-y-auto max-[770px]:w-full`}>
          {children}
        </main>
          <BottomNav />
      </div>
    </div>
  );
};

export default Wrapper;