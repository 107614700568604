import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { MdFeedback, MdMenu } from "react-icons/md";
import { useState } from "react";
import { useSelector } from "react-redux";
import { AiFillDashboard } from "react-icons/ai";
import { FaUser } from "react-icons/fa";

const Sidebar = () => {
    const { isOpen } = useSelector((state) => state.sidebar);
    const { flag } = useSelector((state) => state.chat);
    const { pathname } = useLocation();

    return (
        <>

            <div className={`sidebar h-[calc(100vh-96px)] overflow-y-auto z-20 sticky top-[96px]`}>
                <ul className="ul">
                    <Link className={`link ${pathname.includes("dashboard") && "bg-[#1A56DB]"}`} to={"/partner/dashboard"}>
                        Dashboard
                        <AiFillDashboard size={28} />
                    </Link>

                    {/* <Link className="link" to={"/admin/dashboard"}>Dashboard</Link> */}

                    <Link className={`link ${pathname.includes("/partner/requests") && "bg-[#1A56DB]"}`} to={"/partner/requests"}>
                        Requests
                        <MdFeedback size={28} />
                    </Link>
                    <Link className={`link ${pathname.includes("/partner/clients") && "bg-[#1A56DB]"}`} to={"/partner/clients"}>
                        Clients
                        <FaUser size={28} />
                    </Link>
                    {/* <Link className="link" to={"/partner/dashboard"}>Dashboard</Link>
                    <Link className="link" to={"/partner/requests"}>Requests</Link>
                    <Link className="link" to={"/partner/clients"}>Clients</Link> */}
                </ul>
            </div>
        </>
    );
};

export default Sidebar;