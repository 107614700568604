import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import DocumentComp from '../document/DocumentComp'
import Folder from '../folder/Folder'
import { useEffect, useState } from 'react'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useParams } from 'react-router-dom'

const MyUpdate = ({ allDocuments, isLoading }) => {
    const { res: folderRes, fetchData: fetchFolderData, isLoading: isFolderLoading } = useGetApiReq();

    const params = useParams();
    const [allFolders, setAllFolders] = useState([]);

    const [folderIdAndType, setFolderIdAndType] = useState({
        folderType: "",
        fId: "",
        folderName: ""
    });

    const getFolders = () => {
        fetchFolderData(`/client/get-folders`);
    }

    useEffect(() => {
            getFolders();
    }, [])


    useEffect(() => {
        if (folderRes?.status === 200 || folderRes?.status === 201) {
            console.log("folders", folderRes);
            setAllFolders(folderRes?.data?.folders);
        }
    }, [folderRes])

    return (
        <div>
            {allDocuments?.length === 0 && !isLoading &&
                <DataNotFound name={"Documents"} />
            }

            <div className='w-[450px] mt-4'>
                {allFolders?.map((folder) => (
                    <Folder
                        key={folder?._id}
                        folder={folder}
                        folderIdAndType={folderIdAndType}
                        setFolderIdAndType={setFolderIdAndType}
                        isDownload={true}
                    />
                ))}
            </div>
        </div>
    )
}

export default MyUpdate