import Wrapper from "@/components/wrapper/Wrapper";
import { useState, useEffect } from "react";
import useGetApiReq from "@/hooks/useGetApiReq";
import DocumentComp from "@/client/components/document/DocumentComp";
import Spinner from "@/components/spinner/Spinner";
import DataNotFound from "@/components/data-not-found/DataNotFound";
import MyDocumentsComp from "@/client/components/myDocumentsComp/MyDocumentsComp";
import MyUpdate from "@/client/components/myUpdate/MyUpdate";
import { IoIosSearch } from "react-icons/io";
import { Input } from "@/components/ui/input";
const MyDocuments = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const [allDocuments, setAllDocuments] = useState([]);
  const [status, setStatus] = useState("my-document");
  const [searchInput, setSearchInput] = useState("");

  const getDocuments = async () => {
    if (status === "my-document") {
      fetchData(`/client/get-my-documents?search=${searchInput}`);
    }
    else {
      fetchData("/client/get-update-documents");
    }
  };

  useEffect(() => {
    getDocuments();
  }, [status,searchInput]);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("document res", res.data);
      setAllDocuments(res?.data?.documents);
    }
  }, [res]);
  return (
    <Wrapper>
      <section className="text-gray-600 body-font">
        <div className='section-heading-container'>
          <h1 className="section-heading mb-2">My Documents</h1>
          <div className='flex items-center max-[560px]:w-full'>
            <IoIosSearch className='-mr-6 z-10' />
            <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Document" className="pl-8 max-[560px]:w-full" />
          </div>
        </div>
        <div className='flex gap-3 items-center mt-3'>
          <button onClick={() => setStatus("my-document")} className={`px-2 py-1 rounded-md ${status !== "my-document" ? "bg-transparent border-2" : "bg-black text-white"}`}>My Documents</button>
          <button onClick={() => setStatus("service-update")} className={`px-2 py-1 rounded-md ${status !== "service-update" ? "bg-transparent border-2" : "bg-black text-white"}`}>Service Update</button>
        </div>
        {status === "my-document" ?
          <MyDocumentsComp
            allDocuments={allDocuments}
            isLoading={isLoading}
          /> :
          <MyUpdate
            allDocuments={allDocuments}
            isLoading={isLoading}
          />
        }
      </section>
    </Wrapper>
  );
};

export default MyDocuments;
