import useGetApiReq from '@/hooks/useGetApiReq';
import PartnerWrapper from '@/partner/components/wrapper/Wrapper';
import { useEffect, useState } from 'react'

import {
    Table,
    TableBody,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import ServiceRequestComp from '@/partner/components/service-request-comp/ServiceRequestComp';
import Spinner from '@/components/spinner/Spinner';
import DataNotFound from '@/components/data-not-found/DataNotFound';

const Dashboard = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [allRequests, setAllRequests] = useState([]);
    const limit = import.meta.env.VITE_LIMIT;

    const getRecentRequests = async () => {
        fetchData(`/partner/get-all-requests?page=1&limit=${limit}`);
    };

    useEffect(() => {
        getRecentRequests();
    }, []);

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("requests", res?.data);
            setAllRequests(res?.data?.requests);
        }
    }, [res]);

    return (
        <PartnerWrapper>
            <div>
                <h1 className='section-heading'>Recent Requests</h1>
                <Table className="mt-4">
                    <TableHeader className="bg-[#E5E7EB]">
                        <TableRow className="text-lg">
                            <TableHead className="w-56">ServiceId</TableHead>
                            <TableHead>Client Name</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Actions</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {allRequests?.map((request) => (
                            <ServiceRequestComp
                                key={request._id}
                                serviceRequest={request}
                                getServiceRequests={getRecentRequests}
                            />
                        ))}
                    </TableBody>
                </Table>
                {allRequests.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allRequests.length === 0 && !isLoading &&
                    <DataNotFound name={"Requests"} />
                }
            </div>
        </PartnerWrapper>
    )
}

export default Dashboard