import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import Client from '@/admin/components/client/Client'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useCallback, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { HiOutlineViewList } from "react-icons/hi";
import { IoGridSharp } from "react-icons/io5";

import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { Input } from '@/components/ui/input'
import { IoIosSearch } from "react-icons/io";
import AddClientModal from '@/admin/components/add-clientModal/AddClientModal'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import LimitComp from '@/components/limit-comp/LimitComp'
import { useNavigate } from 'react-router-dom'
import ConversationModal from '@/admin/components/conversation-modal/ConversationModal'
import SmsModal from '@/admin/components/sms-modal/SmsModal'
import ClientFilterModal from '@/admin/components/client-filterModal/ClientFilterModal'

const Clients = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [allClients, setAllClients] = useState([]);
    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [type, setType] = useState("all");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [isGridView, setIsGridView] = useState(false);
    const [checkedItems, setCheckedItems] = useState([]);
    const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);
    const [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleClick = (e) => {
        if (e.target.checked) {
            const clients = allClients.map((client) => ({ clientId: client?._id, checked: true }))
            setCheckedItems(clients);
        }
        else {
            setCheckedItems([]);
        }
    }

    const handleChecked = (e, clientId) => {
        // e.stopPropogation();
        if (e.target.checked) {
            setCheckedItems([...checkedItems, { clientId, checked: true }]);
        }
        else {
            const clients = [...checkedItems].filter((item) => item?.clientId !== clientId)
            setCheckedItems(clients);
        }
    }


    const getClients = useCallback(async () => {
        fetchData(`/admin/get-clients?page=${page}&limit=${limit}&searchQuery=${searchInput}&type=${type === "all" ? "" : type}`);
    }, [page, limit, type, searchInput, fetchData]);

    useEffect(() => {
        getClients();
    }, [page, limit, type, searchInput])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllClients(res?.data?.clients);
            const count = Math.ceil(res?.data?.count / limit)
            setPageCount(count);
        }
    }, [res])


    return (
        <AdminWrapper>
            <section className="text-gray-600 body-font">
                <div className="section-heading-container">
                    <div className='flex gap-3 items-center'>
                        <h1 className='section-heading'>Clients</h1>
                        <LimitComp
                            setLimit={setLimit}
                            limit={limit}
                        />
                    </div>
                    <div className='flex gap-3 items-center'>
                        {checkedItems.length > 0 && <div className='flex gap-3 mr-4'>
                            <Button onClick={() => setIsConversationModalOpen(true)}>Start Internal Conversation</Button>
                            <Button onClick={() => setIsSmsModalOpen(true)}>Send SMS</Button>
                        </div>}
                        <div className='flex gap-2 items-center mr-3'>
                            <HiOutlineViewList onClick={() => setIsGridView(false)} className={`${isGridView ? "text-gray-500" : "text-black"}`} cursor={"pointer"} size={30} />
                            <IoGridSharp onClick={() => setIsGridView(true)} className={`${!isGridView ? "text-gray-500" : "text-black"}`} cursor={"pointer"} size={23} />
                        </div>

                        <div className='flex items-center gap-5'>
                            <div className='flex items-center'>
                                <IoIosSearch className='-mr-6 z-10' />
                                <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Client" className="pl-8" />
                            </div>
                            <Select onValueChange={(value) => setType(value)} value={type}>
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Select" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="all">All</SelectItem>
                                    <SelectItem value="self">Self</SelectItem>
                                    <SelectItem value="partner">Partner</SelectItem>
                                    <SelectItem value="admin">Admin</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <Button onClick={() => setIsAddClientModalOpen(true)}>Add Client</Button>
                        <Button onClick={() => setIsFilterModalOpen(true)}>Download</Button>
                    </div>
                </div>

                {!isGridView &&
                    <div className=''>
                        <Table className="mt-4">
                            <TableHeader className="bg-[#E5E7EB]">
                                <TableRow className="text-lg">
                                    <TableHead className="flex gap-2 items-center">
                                        {/* Select All */}
                                        <Input type="checkbox" className="w-5 h-5" checked={checkedItems.length > 0} onChange={(e) => handleClick(e)} />
                                    </TableHead>
                                    <TableHead>Name</TableHead>
                                    <TableHead>Email</TableHead>
                                    <TableHead>Phone</TableHead>
                                    <TableHead>Address</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {allClients.length === 0 && isLoading &&
                                    <Spinner size={30} />
                                }

                                {allClients.length === 0 && !isLoading &&
                                    <DataNotFound name={"Clients"} />
                                }

                                {allClients?.map((client) => (
                                    <TableRow key={client?._id}>
                                        <TableCell>
                                            <Input checked={checkedItems.find((item) => item?.clientId === client?._id)} type="checkbox" className="w-5 h-5" onChange={(e) => handleChecked(e, client?._id)} />
                                        </TableCell>
                                        <TableCell onClick={() => navigate(`/admin/clients/${client?._id}`)} className="cursor-pointer">{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
                                        <TableCell>{client?.email || "-"}</TableCell>
                                        <TableCell>{client?.phone || "-"}</TableCell>
                                        <TableCell className="line-clamp-1 max-w-[450px] w-full">{`${client?.address?.addressLine || "-"}, ${client?.address?.city || "-"}, ${client?.address?.state || "-"}, ${client?.address?.pinCode || "-"}`}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </div>
                }

                {isGridView &&
                    <>
                        <div className='mt-4 gap-10'>
                            <div className="px-5 py-5 mx-auto grid grid-cols-1 md:grid-cols-2 place-items-center gap-y-20 gap-x-5">
                                {allClients?.map((client) => (
                                    <Client
                                        key={client._id}
                                        client={client}
                                    />
                                ))}
                            </div>
                        </div>
                        {allClients.length === 0 && isLoading &&
                            <Spinner size={30} />
                        }

                        {allClients.length === 0 && !isLoading &&
                            <DataNotFound name={"Clients"} />
                        }
                    </>

                }
                {pageCount > 0 &&
                    <div className="mt-0 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}

                {isAddClientModalOpen &&
                    <AddClientModal
                        setIsAddClientModalOpen={setIsAddClientModalOpen}
                        isAddClientModalOpen={isAddClientModalOpen}
                        getClients={getClients}
                    />
                }

                {isConversationModalOpen &&
                    <ConversationModal
                        isConversationModalOpen={isConversationModalOpen}
                        setIsConversationModalOpen={setIsConversationModalOpen}
                        clientIds={checkedItems}
                    />
                }

                {isSmsModalOpen &&
                    <SmsModal
                        isSmsModalOpen={isSmsModalOpen}
                        setIsSmsModalOpen={setIsSmsModalOpen}
                        clients={checkedItems}
                    />
                }

                {isFilterModalOpen &&
                    <ClientFilterModal
                        isFilterModalOpen={isFilterModalOpen}
                        setIsFilterModalOpen={setIsFilterModalOpen}
                    />
                }

            </section>
        </AdminWrapper>
    )
}

export default Clients