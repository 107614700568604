import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";
import SuccessModal from "../successmodal/SuccessModal";

const BuyNowService = ({ setIsBuyNowServiceModalOpen, isBuyNowServiceModalOpen, serviceId, getServices }) => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

  const navigate = useNavigate();
  const [requirment, setRequirment] = useState("");

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!requirment) {
      toast.error("All fields are required");
      return;
    }

    fetchData("/client/request-service", { requirement: requirment, serviceId });

  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsBuyNowServiceModalOpen(false);
      getServices();
      setIsSuccessModalOpen(true);
      navigate("/client/chats", { state: { serviceId } });
    }
  }, [res])

  return (
    <>
      <Dialog open={isBuyNowServiceModalOpen} onOpenChange={() => setIsBuyNowServiceModalOpen(!isBuyNowServiceModalOpen)}>
        <DialogContent className="sm:max-w-[550px]">
          <DialogHeader>
            <DialogTitle>Buy Service</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
            <div className="grid grid-cols-1 items-center gap-4">
              <Label htmlFor="name">
                Requirment
              </Label>
              <ReactQuill theme="snow" value={requirment} onChange={setRequirment} />
            </div>
            <DialogFooter>
              <Button type="submit">Buy</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
      
      <SuccessModal
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
      />
    </>
  )
}

export default BuyNowService