import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useEffect, useState } from 'react'
import ServiceClientLinkDataModal from '../serviceClientLink-dataModal/ServiceClientLinkDataModal'

const PurchasedServiceFilterModal = ({ isFilterModalOpen, setIsFilterModalOpen }) => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: categoryRes, fetchData: fetchCategoryData, isLoading: isCategoryLoading } = useGetApiReq();
    const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();

    const [filterInfo, setFilterInfo] = useState({
        startDate: "",
        endDate: "",
        paymentStatus: "",
        serviceStatus: "",
        categoryId: "",
        serviceId: "",
    })

    const [allCategories, setAllCategories] = useState([]);
    const [allServices, setAllServices] = useState([]);
    const [serviceClientLinkData, setServiceClientLinkData] = useState([]);
    const [isDataModalOpen, setIsDataModalOpen] = useState(false);


    const handleReset = async () => {
        setFilterInfo({
            startDate: "",
            endDate: "",
            paymentStatus: "",
            serviceStatus: "",
            categoryId: "",
            serviceId: "",
        })
    };


    const getCategories = async () => {
        fetchCategoryData("/admin/get-categories?flag=no-paginate");
    };

    useEffect(() => {
        getCategories();
    }, [])

    useEffect(() => {
        if (categoryRes?.status === 200 || categoryRes?.status === 201) {
            console.log("category res", categoryRes);
            setAllCategories(categoryRes?.data?.categories);
        }
    }, [categoryRes])

    const getServices = async () => {
        fetchServiceData(`/admin/get-services?categoryId=${filterInfo.categoryId}&flag=no-paginate`);
    };

    useEffect(() => {
        if (filterInfo.categoryId) {
            getServices();
        }
    }, [filterInfo.categoryId])

    useEffect(() => {
        if (serviceRes?.status === 200 || serviceRes?.status === 201) {
            console.log("service res", serviceRes);
            setAllServices(serviceRes?.data?.services);
        }
    }, [serviceRes])


    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setFilterInfo({ ...filterInfo, [name]: value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        fetchData(`/admin/get-service-client-link-data?startDate=${filterInfo.startDate}&endDate=${filterInfo.endDate}&paymentStatus=${filterInfo.paymentStatus}&serviceStatus=${filterInfo.serviceStatus}&serviceId=${filterInfo.serviceId}`);
    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("res", res);
            if (res?.data?.serviceClientLinkDocs) {
                setIsDataModalOpen(true);
                setServiceClientLinkData(res?.data?.serviceClientLinkDocs);
            }
        }
    }, [res])

    return (
        <>
            <Dialog open={isFilterModalOpen} onOpenChange={() => setIsFilterModalOpen(!isFilterModalOpen)}>
                <DialogContent className="sm:max-w-[525px] h-[90vh] overflow-y-auto">
                    <DialogHeader>
                        <DialogTitle>Filter Purchased Services Data</DialogTitle>
                    </DialogHeader>
                    <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                        <div className="input-container">
                            <Label htmlFor="startDate">
                                Purchased Services Start Date
                            </Label>
                            <Input type="date" onChange={handleOnChange} value={filterInfo.startDate} name="startDate" id="startDate" className="col-span-3" />
                        </div>
                        <div className="input-container">
                            <Label htmlFor="endDate">
                                Purchased Services End Date
                            </Label>
                            <Input type="date" onChange={handleOnChange} value={filterInfo.endDate} name="endDate" id="endDate" className="col-span-3" />
                        </div>

                        <div className="input-container">
                            <Label htmlFor="categoryId">
                                Category
                            </Label>
                            <Select className="col-span-3" name='categoryId' id="categoryId" onValueChange={(value) => setFilterInfo({ ...filterInfo, categoryId: value })} value={filterInfo.categoryId} >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Category" />
                                </SelectTrigger>
                                <SelectContent>
                                    {allCategories?.length === 0 && <p>No categories found.</p>}

                                    {allCategories?.map((category) => (
                                        <SelectItem
                                            key={category?._id}
                                            value={category?._id}
                                        >
                                            {category?.name}
                                        </SelectItem>
                                    ))}
                                </SelectContent>
                            </Select>
                        </div>
                        {filterInfo.categoryId &&
                            <div className="input-container">
                                <Label htmlFor="serviceId">
                                    Service
                                </Label>
                                <Select className="col-span-3" name='serviceId' id="serviceId" onValueChange={(value) => setFilterInfo({ ...filterInfo, serviceId: value })} value={filterInfo.serviceId} >
                                    <SelectTrigger>
                                        <SelectValue placeholder="Select Service" />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {allServices?.length === 0 && <p>No services found.</p>}

                                        {allServices?.map((service) => (
                                            <SelectItem
                                                key={service?._id}
                                                value={service?._id}
                                            >
                                                {service?.name}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                            </div>
                        }

                        <div className="input-container">
                            <Label htmlFor="paymentStatus">
                                Payment Status
                            </Label>
                            <Select className="col-span-3" name='paymentStatus' id="paymentStatus" onValueChange={(value) => setFilterInfo({ ...filterInfo, paymentStatus: value })} value={filterInfo.paymentStatus} >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Payment Status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="due">Due</SelectItem>
                                    <SelectItem value="paid">Paid</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Label htmlFor="serviceStatus">
                                Service Status
                            </Label>
                            <Select className="col-span-3" name='serviceStatus' id="serviceStatus" onValueChange={(value) => setFilterInfo({ ...filterInfo, serviceStatus: value })} value={filterInfo.serviceStatus} >
                                <SelectTrigger>
                                    <SelectValue placeholder="Select Service Status" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectItem value="pending">Pending</SelectItem>
                                    <SelectItem value="completed">Completed</SelectItem>
                                </SelectContent>
                            </Select>
                        </div>

                        <DialogFooter>
                            <Button onClick={handleReset} type="button">
                                Reset
                            </Button>
                            <Button type="submit">
                                {isLoading ? <Spinner /> : "Get Data"}
                            </Button>
                        </DialogFooter>
                    </form>
                </DialogContent>
            </Dialog>

            {isDataModalOpen &&
                <ServiceClientLinkDataModal
                    isDataModalOpen={isDataModalOpen}
                    setIsDataModalOpen={setIsDataModalOpen}
                    data={serviceClientLinkData}
                />
            }
        </>
    )
}

export default PurchasedServiceFilterModal