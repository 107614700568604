import { Link } from "react-router-dom";

const Client = ({ client }) => {

    return (
        <div className="max-w-[600px] w-full">
            <div className="flex flex-col sm:flex-row items-start">
                <div className="sm:w-[40%] flex flex-col items-start w-full text-center sm:pr-2 sm:py-4 sm:border-r border-gray-200">
                    <div className="w-40 h-40 rounded-lg inline-flex items-center justify-center bg-gray-200 text-gray-400">
                        <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-10 h-10" viewBox="0 0 24 24">
                            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>
                    </div>
                    <div className="flex flex-col items-center text-center justify-center">
                        <div className="w-12 h-1 bg-[#1C64F2] rounded mt-2 mb-4"></div>
                        <p className="text-base w-36 break-words">{client?.email}</p>
                    </div>
                </div>
                <div className="sm:w-[60%] w-full sm:pl-8 sm:py-3 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                    <h2 className="font-semibold title-font mt-4 text-[#4B5563] text-2xl">{`${client?.name?.first} ${client?.name?.last}`}</h2>
                    <p className="leading-relaxed text-[#374151] text-lg max-w-80 mb-4">{`${client?.address?.addressLine}, ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</p>
                    <Link to={`/partner/clients/${client?._id}`} className="text-[#1A56DB] border px-3 py-1 rounded-md border-[#1A56DB] hover:bg-[#1A56DB] hover:text-white transition-all inline-flex items-center">
                        View full profile
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Client