import Wrapper from "@/components/wrapper/Wrapper";
import { useState, useEffect } from "react";
import useGetApiReq from "@/hooks/useGetApiReq";
import MyService from "@/client/components/myservice/MyService";
import DataNotFound from "@/components/data-not-found/DataNotFound";
import Spinner from "@/components/spinner/Spinner";
const MyServices = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const [allServices, setAllServices] = useState([]);

  const getServiceRequests = async () => {
    fetchData("/client/get-my-services");
  };

  useEffect(() => {
    getServiceRequests();
  }, []);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("my services", res.data.services)
      setAllServices(res?.data?.services);
    }
  }, [res]);

  return (
    <Wrapper>
      <section>
        <h1 className='section-heading'>My Services</h1>
        {allServices.length === 0 && isLoading &&
          <Spinner size={30} />
        }

        {allServices.length === 0 && !isLoading &&
          <DataNotFound name={"Services"} />
        }
        <div className="py-10 grid grid-cols-1 md:grid-cols-2 gap-10">
          {/* <div className="-my-8 divide-y-2 divide-gray-100"> */}
          {allServices?.map((service) => (
            <MyService
              key={service?._id}
              service={service}
            />
          ))}
          {/* </div> */}
        </div>
      </section>
    </Wrapper>
  );
};

export default MyServices;
