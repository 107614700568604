import DataNotFound from "@/components/data-not-found/DataNotFound";
import Spinner from "@/components/spinner/Spinner";
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input";
import useGetApiReq from "@/hooks/useGetApiReq";
import AddClientModal from "@/partner/components/add-clientModal/AddClientModal";
import Client from "@/partner/components/client/Client";
import PartnerWrapper from "@/partner/components/wrapper/Wrapper";
import { useCallback, useEffect, useState } from "react"
import { IoIosSearch } from "react-icons/io";
import ReactPaginate from "react-paginate";

const Clients = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [allClients, setAllClients] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const limit = import.meta.env.VITE_LIMIT;


    const getClients = useCallback(async () => {
        fetchData(`/partner/get-clients?page=${page}&limit=${limit}&searchQuery=${searchInput}`);
    }, [page, fetchData, searchInput]);

    useEffect(() => {
        getClients();
    }, [page, searchInput])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllClients(res?.data?.clients);
            const count = Math.ceil(res?.data?.count / limit)
            setPageCount(count);
        }
    }, [res])


    const handleSearch = (value) => {
        setSearchInput(value);
    }

    return (
        <PartnerWrapper>
            <div>
                <div className="section-heading-container">
                    <h1 className='section-heading'>Clients</h1>
                    <div className='flex items-center gap-5'>
                        <div className='flex items-center'>
                            <IoIosSearch className='-mr-6 z-10' />
                            <Input onChange={(e) => handleSearch(e.target.value)} placeholder="Search Client" className="pl-8" />
                        </div>
                        <Button className="bg-[#1A56DB] hover:bg-[#104ac6]" onClick={() => setIsAddClientModalOpen(true)}>Add Client</Button>
                    </div>
                </div>

                {allClients.length === 0 && isLoading &&
                    <Spinner size={30} />
                }

                {allClients.length === 0 && !isLoading &&
                    <DataNotFound name={"Clients"} />
                }

                <div className="py-10 mx-auto grid grid-cols-1 md:grid-cols-2 place-items-center gap-y-20 gap-x-10">
                    {allClients?.map((client) => (
                        <Client
                            key={client._id}
                            client={client}
                        />
                    ))}
                </div>


                {pageCount > 0 &&
                    <div className="mt-0 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}


                <AddClientModal
                    setIsAddClientModalOpen={setIsAddClientModalOpen}
                    isAddClientModalOpen={isAddClientModalOpen}
                    getClients={getClients}
                />
            </div>
        </PartnerWrapper>
    )
}

export default Clients