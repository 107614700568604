import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogDescription, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle } from '@/components/ui/alert-dialog'
import usePostApiReq from '@/hooks/usePostApiReq';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const PayLaterModal = ({ setIsPayLaterModalOpen, isPayLaterModalOpen, getRequestDetail }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const params = useParams();

    const handlePayLater = () => {
        fetchData("/admin/pay-later", { requestId: params?.reqId })
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("pay later", res?.data)
            getRequestDetail();
        }
    }, [res]);

    return (
        <AlertDialog open={isPayLaterModalOpen} onOpenChange={() => setIsPayLaterModalOpen(!isPayLaterModalOpen)}>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Pay Later</AlertDialogTitle>
                    <AlertDialogDescription>
                        The Client will pay the amount later, Are you sure to continue?
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    <AlertDialogCancel>Cancel</AlertDialogCancel>
                    <AlertDialogAction onClick={handlePayLater} className="bg-red-400">Continue</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default PayLaterModal