import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Link, useNavigate, useParams } from 'react-router-dom';

import useGetApiReq from '@/hooks/useGetApiReq';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import parse from "html-react-parser"
import PartnerWrapper from "@/partner/components/wrapper/Wrapper";
import { FaEye } from "react-icons/fa";
import Spinner from "@/components/spinner/Spinner";
import DataNotFound from "@/components/data-not-found/DataNotFound";
import AcceptServiceModal from "@/partner/components/accept-serviceModal/AcceptServiceModal";
import Rectangle17 from "@/assets/Rectangle 17.png";

const RequestDetail = () => {
  const { res, fetchData, isLoading } = useGetApiReq();

  const params = useParams();
  const navigate = useNavigate();

  const [request, setRequest] = useState([]);
  const [service, setService] = useState("");
  const [client, setClient] = useState("");
  const [isAcceptModalOpen, setIsAcceptModalOpen] = useState(false);

  const getRequestDetail = async () => {
    fetchData(`/partner/get-request-details/${params?.reqId}`);
  };

  useEffect(() => {
    getRequestDetail();
  }, []);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("request detail", res?.data)
      setRequest(res?.data?.request);
      setService(res?.data?.request?.serviceId);
      setClient(res?.data?.request?.clientId);
    }
  }, [res]);



  return (
    <PartnerWrapper>
      <div>
        <h2 className='text-xl font-semibold mt-5 mb-3'>Service Details</h2>
        <Table className="mt-4 mb-5">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Icon</TableHead>
              <TableHead className="w-56">Service Id</TableHead>
              <TableHead>Name</TableHead>
              <TableHead>Description</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="font-medium">
                <div className="w-12 cursor-pointer h-12 rounded-full bg-slate-500">
                  <img className="w-full h-full rounded-full" src={`${import.meta.env.VITE_IMG_URL}/${service?.icon}`} alt="" />
                </div>
              </TableCell>
              <TableCell className="font-medium w-56">{service?._id}</TableCell>
              <TableCell className="font-medium">{service?.name}</TableCell>
              <TableCell className="">{service?.description && parse(service.description)}</TableCell>
              {/* <span className={`px-2 py-[2px] rounded-md ${request.status === "pending" ? "bg-orange-400 text-white" : request.status === "quotation-sent" ? "bg-yellow-500 text-white" : request.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request.status}</span> */}
            </TableRow>
          </TableBody>
        </Table>


        <div className="grid grid-cols-[45%_1fr] gap-4 items-start">
          <div>
            <h2 className='text-xl font-semibold'>Request Details</h2>
            <div className="border-2 rounded-md p-4 mt-4 flex justify-between">
              <div className="">
                <div className="flex flex-col">
                  <p className="text-[#6B7280] font-bold">Service Name</p>
                  <p>{request?.serviceId?.name}</p>
                </div>
                <div className="flex flex-col mt-4">
                  <p className="text-[#6B7280] font-bold">Client Name</p>
                  <p>{`${client?.name?.first} ${client?.name?.last}`}</p>
                </div>
                <div className="flex flex-col mt-4">
                  <p className="text-[#6B7280] w-28 font-bold">Date</p>
                  <div>{request?.createdAt && format(new Date(request?.createdAt), "dd/MM/yyyy")}</div>
                </div>
              </div>
              <div className="">
                <div className="flex flex-col gap-2">
                  <p className="text-[#6B7280] w-28 font-bold">Status</p>
                  <div className="w-28">
                    <span className={`px-2 py-[2px] rounded-md ${request?.status === "pending" ? "bg-orange-400 text-white" : request?.status === "quotation-sent" ? "bg-yellow-500 text-white" : request?.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request?.status}</span>
                  </div>
                </div>
                <div className="flex flex-col mt-4">
                  <p className="text-[#6B7280] w-28 font-bold">Requirement</p>
                  <div className="">{request?.requirement && parse(request?.requirement)}</div>
                </div>
              </div>

            </div>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-3">Client Details</h2>
            <div className="border-2 rounded-md p-4 mt-4 flex items-start gap-5">
              <div onClick={() => navigate(`/partner/clients/${client?._id}`)} className="flex cursor-pointer flex-col gap-2">
                <div className="w-32 h-36 rounded-lg">
                  <img className="w-full h-full" src={Rectangle17} alt="Rectangle17" />
                </div>
                <h3 className="text-xl text-center font-bold">{client?.name?.first}</h3>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <p className="text-[#6B7280] font-bold">Full Name</p>
                  <p>{`${client?.name?.first} ${client?.name?.last}`}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#6B7280] font-bold">Phone No</p>
                  <p>{client?.phone}</p>
                </div>
                <div className="flex flex-col">
                  <p className="text-[#6B7280] font-bold">Email</p>
                  <p>{client?.email}</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-col">
                  <p className="text-[#6B7280] font-bold">Address</p>
                  <p className="max-w-[200px]">{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Table className="mt-4">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Date</TableHead>
              <TableHead>Service Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Type</TableHead>
              <TableHead className="w-96">Requirement</TableHead>
              {request?.status === "quotation-sent" && <TableHead>Actions</TableHead>}
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>{request?.createdAt && format(new Date(request?.createdAt), "dd/MM/yyyy")}</TableCell>
              <TableCell>{request?.serviceId?.name}</TableCell>
              <TableCell className="w-40">
                <span className={`px-2 py-[2px] rounded-md ${request?.status === "pending" ? "bg-orange-400 text-white" : request?.status === "quotation-sent" ? "bg-yellow-500 text-white" : request?.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request?.status}</span>
              </TableCell>
              <TableCell>{request?.type}</TableCell>
              <TableCell className="w-96">{request?.requirement && parse(request?.requirement)}</TableCell>
              {request?.status === "quotation-sent" && <TableCell><button onClick={() => setIsAcceptModalOpen(true)} className="px-2 rounded-md disabled:bg-gray-600 py-[2px] text-white bg-black">Accept</button></TableCell>}
            </TableRow>
          </TableBody>
        </Table> */}

        {/* <Table className="">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg">
              <TableHead>Name</TableHead>
              <TableHead>Phone</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Address</TableHead>
              <TableHead>Action</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell>{`${client?.name?.first} ${client?.name?.last}`}</TableCell>
              <TableCell>{client?.phone}</TableCell>
              <TableCell>{client?.email}</TableCell>
              <TableCell>{`${client?.address?.addressLine} ${client?.address?.city}, ${client?.address?.state}, ${client?.address?.pinCode}`}</TableCell>
              <TableCell>
                <FaEye onClick={() => navigate(`/partner/clients/${client?._id}`)} size={24} cursor={"pointer"} />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table> */}


        <AcceptServiceModal
          isAcceptModalOpen={isAcceptModalOpen}
          setIsAcceptModalOpen={setIsAcceptModalOpen}
          requestId={request?._id}
          getRequestDetail={getRequestDetail}
          price={request?.cost}
          note={request?.note}
        />

      </div>
    </PartnerWrapper>
  )
}

export default RequestDetail