import { Button } from "@/components/ui/button";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuTrigger
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import useGetApiReq from "@/hooks/useGetApiReq";
import usePostApiReq from "@/hooks/usePostApiReq";
import { CitySelect, StateSelect } from "@davzon/react-country-state-city/dist/cjs";
import { useEffect } from "react";
import { useState } from "react";
import toast from "react-hot-toast";
import { FaEdit } from "react-icons/fa";

const Profile = ({ children, isProfileOpen, setIsProfileOpen }) => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const { res: updateRes, fetchData: fetchUpdateData, isLoading: isUpdateLoading } = usePostApiReq();

    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [signupInfo, setSignupInfo] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        // password: "",
    });

    const [addressInfo, setAddressInfo] = useState({
        state: "",
        city: "",
        pinCode: "",
        addressLine: "",
    });
    const [stateid, setstateid] = useState(0);

    const handleOpenChange = ()=>{
        setIsEditProfileOpen(false);
    }

    const handleOnChange = (e) => {
        let { name, value } = e.target;
        if (name === "email") {
            value = value.toLowerCase();
        }
        setSignupInfo({ ...signupInfo, [name]: value });
    };

    const handleAddressOnChange = (e) => {
        const { name, value } = e.target;
        setAddressInfo({ ...addressInfo, [name]: value });
    };

    const getProfile = () => {
        // get-profile
        fetchData("/client/get-profile");
    }

    useEffect(() => {
        getProfile()
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            console.log("profiil", res);
            const { name, email, phone, address } = res.data.client;
            setSignupInfo({ firstName: name.first, lastName: name.last, email, phone });
            setAddressInfo(address);
        }
    }, [res])

    const handleOnSubmit = async (e) => {
        // e.preventDefault();

        if (!signupInfo.firstName || !signupInfo.lastName || !signupInfo.phone) {
            toast.error("All fields are required");
            return;
        }

        if (signupInfo.phone.length !== 10) {
            toast.error("Phone length must be equal to 10 digits");
            return;
        }

        if (addressInfo.pinCode.length !== 6) {
            toast.error("PinCode length must be equal to 6 digits");
            return;
        }

        fetchUpdateData(`/client/upate-profile`, { ...signupInfo, ...addressInfo })

    };

    useEffect(() => {
        if (updateRes?.status === 200 || updateRes?.status === 201) {
            console.log("profiil update Res", updateRes);
            setIsEditProfileOpen(false);
            getProfile();
        }
    }, [updateRes])

    return (
        <DropdownMenu className="relative" onOpenChange={handleOpenChange}>
            <DropdownMenuTrigger dir="right" className="relative w-full">
                {children}
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-[380px] max-h-[300px] overflow-y-auto p-3">
                <DropdownMenuLabel className="flex justify-between items-center gap-2">
                    <p>My Account</p>
                    <Button variant="ghost" size="sm">
                        <FaEdit onClick={() => setIsEditProfileOpen(true)} size={20} className="cursor-pointer" />
                    </Button>
                </DropdownMenuLabel>
                <DropdownMenuSeparator />
                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">First Name</h2>
                    {isEditProfileOpen ?
                        <Input placeholder="Enter First Name" onChange={handleOnChange} value={signupInfo.firstName} name="firstName" id="firstName" className="" />
                        : <p>{signupInfo.firstName}</p>}
                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Last Name</h2>
                    {isEditProfileOpen ?
                        <Input placeholder="Enter Last Name" onChange={handleOnChange} value={signupInfo.lastName} name="lastName" id="lastName" className="" />
                        : <p>{signupInfo.lastName}</p>}
                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Email</h2>
                    {isEditProfileOpen ?
                        <Input type="email" placeholder="Enter Email" onChange={handleOnChange} value={signupInfo.email} name="email" id="email" className="" />
                        : <p>{signupInfo?.email || "-"}</p>}

                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Phone</h2>
                    {isEditProfileOpen ?
                        <Input type="number" placeholder="Enter Phone" onChange={handleOnChange} value={signupInfo.phone} name="phone" id="phone" className="" />
                        : <p>{signupInfo?.phone}</p>}
                </div>

                {/* <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Password</h2>
                    <p>12345</p>
                </div> */}

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">State</h2>
                    {isEditProfileOpen ?
                        <Input placeholder="Enter State" onChange={handleAddressOnChange} value={addressInfo?.state} name="state" id="state" className="" />
                        : <p>{addressInfo?.state || "-"}</p>}
                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">City</h2>
                    {isEditProfileOpen ?
                        <Input placeholder="Enter City" onChange={handleAddressOnChange} value={addressInfo?.city} name="city" id="city" className="" />
                        : <p>{addressInfo?.city || "-"}</p>}
                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Pincode</h2>
                    {isEditProfileOpen ?
                        <Input type="number" placeholder="Enter Pincode" onChange={handleAddressOnChange} value={addressInfo?.pinCode} name="pinCode" id="pinCode" className="" />
                        : <p>{addressInfo?.pinCode || "-"}</p>}
                </div>

                <div className="grid grid-cols-[30%_70%] items-center px-2 text-sm py-1">
                    <h2 className="font-semibold">Address Line</h2>
                    {isEditProfileOpen ?
                        <Input placeholder="Enter Address Line" onChange={handleAddressOnChange} value={addressInfo?.addressLine} name="addressLine" id="addressLine" className="" />
                        : <p>{addressInfo?.addressLine || "-"}</p>}
                </div>

                {isEditProfileOpen &&
                    <div className="flex justify-end mt-2">
                        <Button onClick={handleOnSubmit} className="ml-auto">Update</Button>
                    </div>}
            </DropdownMenuContent>
        </DropdownMenu>
    )
}

export default Profile