import Spinner from '@/components/spinner/Spinner'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import usePostApiReq from '@/hooks/usePostApiReq'
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom'

const AddDocumentModal = ({ isAddDocumentModalOpen, setIsAddDocumentModalOpen, getDocuments,serviceId }) => {
    const { res, fetchData, isLoading } = usePostApiReq();
    const params = useParams();

    const [documentInfo, setDocumentInfo] = useState({
        name: "",
        document: "",
        preview: "",
    })
    const [isPreview, setIsPreview] = useState(false);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setDocumentInfo({ ...documentInfo, [name]: value });
    };

    const getImage = (event) => {
        event.preventDefault();
        console.log("event: ", event);
        // getting the image
        const uploadedImage = event.target.files[0];

        // if image exists then getting the url link of it
        if (uploadedImage) {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(uploadedImage);
            fileReader.addEventListener("load", function () {
                setIsPreview(true);
                setDocumentInfo({
                    ...documentInfo,
                    preview: this.result,
                    document: uploadedImage,
                });
            });
        }
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        if (!documentInfo.document || !documentInfo.name) {
            toast.error("All fields are required");
            return;
        }

        const formdata = new FormData();
        formdata.append("name", documentInfo.name);
        formdata.append("document", documentInfo.document);
        formdata.append("serviceClientLinkId", params.myServiceId);

        fetchData("/client/upload-document", formdata);

    };

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setIsAddDocumentModalOpen(false)
            getDocuments();
        }
    }, [res])


    return (
        <Dialog open={isAddDocumentModalOpen} onOpenChange={() => setIsAddDocumentModalOpen(!isAddDocumentModalOpen)}>
            <DialogContent className="sm:max-w-[625px] max-h-[90vh] overflow-y-auto">
                <DialogHeader>
                    <DialogTitle>Add DocumentModal</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
                    <div className="input-container">
                        <Label htmlFor="name">
                            Name
                        </Label>
                        <Input onChange={handleOnChange} value={documentInfo.name} name="name" id="name" className="col-span-3" />
                    </div>
                    <div className="input-container flex-col">
                        <Label htmlFor="icon">
                            Document
                        </Label>
                        {documentInfo.preview &&
                            <div className='w-full'>
                                <iframe className='w-full h-[350px] object-contain' src={documentInfo.preview} alt="" />
                            </div>
                        }
                        <Input type="file" onChange={(e) => getImage(e)} name="icon" id="icon" className="" />
                    </div>
                    <DialogFooter>
                        <Button type="submit">
                            {isLoading ? <Spinner /> : "Add"}
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default AddDocumentModal