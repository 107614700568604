import { Link, useLocation } from "react-router-dom";
import "./sidebar.css";
import { useSelector } from "react-redux";
import { AiFillDashboard } from "react-icons/ai";
import { MdFeedback } from "react-icons/md";
import { GrServices } from "react-icons/gr";
import { IoDocumentsSharp } from "react-icons/io5";
import { BsChatSquareDotsFill } from "react-icons/bs";

const Sidebar = () => {
    const { isOpen } = useSelector((state) => state.sidebar);
    const { pathname } = useLocation();

    return (
        <>

            <div className={`sidebar h-[calc(100vh-96px)] overflow-y-auto z-20 max-[770px]:hidden sticky top-[96px] ${!isOpen ? "" : "-translate-x-0"}`}>
                <ul className="ul">
                    <Link className={`link ${pathname.includes("dashboard") && "bg-[#1A56DB]"}`} to={"/client/dashboard"}>
                        Dashboard
                        <AiFillDashboard size={28} />
                    </Link>

                    {/* <Link className="link" to={"/admin/dashboard"}>Dashboard</Link> */}

                    <Link className={`link ${pathname.includes("requests") && "bg-[#1A56DB]"}`} to={"/client/requests"}>
                        Pending Requests
                        <MdFeedback size={28} />
                    </Link>
                    <Link className={`link ${pathname.includes("my-services") && "bg-[#1A56DB]"}`} to={"/client/my-services"}>
                        My Services
                        <GrServices size={28} />
                    </Link>
                    <Link className={`link ${pathname.includes("mydocuments") && "bg-[#1A56DB]"}`} to={"/client/mydocuments"}>
                        My Documents
                        <IoDocumentsSharp size={28} />
                    </Link>
                    <Link className={`link ${pathname.includes("chats") && "bg-[#1A56DB]"}`} to={"/client/chats"}>
                        My Chats
                        <BsChatSquareDotsFill size={24} />
                    </Link>
                </ul>
            </div>
        </>
    );
};

export default Sidebar;