import DocumentView from '@/client/components/document-view/DocumentView'
import { TableCell, TableRow } from '@/components/ui/table'
import { format } from 'date-fns'
import parse from 'html-react-parser'
import { useEffect, useState } from 'react'
import { FaArrowAltCircleDown, FaEdit } from 'react-icons/fa'
import { MdDelete } from 'react-icons/md'
import AddServiceUpdateModal from '../add-serviceUpdateModal/AddServiceUpdateModal'
import DeleteAlert from '../delete-alert/DeleteAlert'
import useDeleteApiReq from '@/hooks/useDeleteApiReq'
import useGetApiReq from '@/hooks/useGetApiReq'
import axios from 'axios'
import { saveDoc } from '@/utils/download'

const ServiceUpdateComp = ({ update, getServiceUpdates = () => { } }) => {
    const { res, fetchData, isLoading } = useDeleteApiReq();

    const [isDocumentViewModalOpen, setIsDocumentViewModalOpen] = useState(false);
    const [isUpdateServiceUpdateModalOpen, setIsUpdateServiceUpdateModalOpen] = useState(false);
    const [isDeleteServiceUpdateModalOpen, setIsDeleteServiceUpdateModalOpen] = useState(false);

    const clientName = update?.clientId ?
        `${update?.clientId?.name?.first} ${update?.clientId?.name?.last}`
        : `${update?.client?.name?.first} ${update?.client?.name?.last}`;

    console.log("update", update);

    const handleDelete = () => {
        fetchData(`/admin/delete-service-update/${update?._id}`);
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            getServiceUpdates();
        }
    }, [res]);


    const { res: docRes, fetchData: fetchDocData, isLoading: isDocLoading } = useGetApiReq();

    const getDoc = () => {
        fetchDocData(`/admin/get-doc?url=${update?.doc}`);
    }

    useEffect(() => {
        if (docRes?.status === 200 || docRes?.status === 201) {
            console.log("Document", docRes);
            saveDoc(docRes?.data?.preSignedUrl);
        }
    }, [docRes])

    return (
        <>
            <TableRow>
                <TableCell>{clientName}</TableCell>
                {/* <TableCell className="w-40">
                    <span className={`px-2 py-[2px] rounded-md ${update?.status === "pending" ? "bg-orange-400 text-white" : "bg-green-500 text-white"}`}>{update?.status}</span>
                </TableCell> */}
                <TableCell>{update?.createdAt && format(new Date(update?.createdAt), "dd/MM/yyyy")}</TableCell>
                <TableCell>
                    <button disabled={update?.doc ? false : true} className="cursor-pointer disabled:cursor-not-allowed">Doc</button>
                </TableCell>
                <TableCell>{update?.description && parse(update?.description)}</TableCell>
                <TableCell className="flex gap-3 items-center">
                    <FaEdit onClick={() => setIsUpdateServiceUpdateModalOpen(true)} size={22} cursor={"pointer"} />
                    <MdDelete onClick={() => setIsDeleteServiceUpdateModalOpen(true)} size={22} cursor={"pointer"} />
                    <FaArrowAltCircleDown onClick={() => getDoc()} size={22} cursor={"pointer"} />
                </TableCell>
            </TableRow>

            {isDocumentViewModalOpen &&
                <DocumentView
                    document={update?.doc}
                    setIsDocumentViewModalOpen={setIsDocumentViewModalOpen}
                    isDocumentViewModalOpen={isDocumentViewModalOpen}
                />}

            {isUpdateServiceUpdateModalOpen &&
                <AddServiceUpdateModal
                    isAddServiceUpdateModalOpen={isUpdateServiceUpdateModalOpen}
                    setIsAddServiceUpdateModalOpen={setIsUpdateServiceUpdateModalOpen}
                    getServiceUpdates={getServiceUpdates}
                    update={update}
                />
            }

            {isDeleteServiceUpdateModalOpen &&
                <DeleteAlert
                    handleDelete={handleDelete}
                    isDeleteModalOpen={isDeleteServiceUpdateModalOpen}
                    setIsDeleteModalOpen={setIsDeleteServiceUpdateModalOpen}
                />
            }
        </>
    )
}

export default ServiceUpdateComp