import Spinner from '@/components/spinner/Spinner';
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import usePostApiReq from '@/hooks/usePostApiReq';
import { useEffect, useState } from 'react'
import AddPriceModal from '../add-priceModal/AddPriceModal';

const ChatInput = ({ getChats, selected, serviceId,type }) => {
    const { res, fetchData, isLoading } = usePostApiReq();

    const [message, setMessage] = useState("");
    const [isAddPriceModalOpen, setIsAddPriceModalOpen] = useState(false);


    const sendMessage = async () => {
        fetchData("/admin/send-message", { msg: message, clientId: selected, serviceId,type });
    }

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setMessage("");
            getChats();
        }
    }, [res])

    const handleOnChange = (e) => {
        const { value } = e.target;
        console.log("message", value);
        setMessage(value);
    }


    return (
        <>
            <div className='flex items-center gap-2 px-2 py-2 z-10 sticky bottom-0 w-full'>
                <Input placeholder="Enter Message" onChange={handleOnChange} value={message} name="message" id="message" className="col-span-3" />
                <Button onClick={sendMessage}>
                    {isLoading ? <Spinner /> : "Send"}
                </Button>
                {type === "non-internal" && <Button onClick={() => setIsAddPriceModalOpen(true)} >Select Price</Button>}
            </div>
            {isAddPriceModalOpen &&
                <AddPriceModal
                    isAddPriceModalOpen={isAddPriceModalOpen}
                    setIsAddPriceModalOpen={setIsAddPriceModalOpen}
                    id={selected}
                    serviceId={serviceId}
                    getChats={getChats}
                />
            }
        </>
    )
}

export default ChatInput