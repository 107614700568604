import success from '../../../assets/success.jpg'
import { Link } from 'react-router-dom'

const Success = () => {
  return (
    <div className='flex flex-col h-screen justify-center items-center bg-gradient-to-tr from-purple-400 to-sky-400'>
      <div className="rounded-md bg-white p-5 max-w-[400px] w-full flex flex-col items-center">
        <img className='w-[80%]' src={success} alt="" />
        <h1 className='text-2xl font-bold'>Payment Success</h1>
        <p className='text-center text-gray-400 mt-2'>Thank you! Your payment has been successfully processed.</p>
        <Link className='px-8 py-2 rounded-sm text-white bg-gradient-to-tr to-purple-500 from-sky-500 mt-4' to={"/partner/dashboard"}>Home</Link>
      </div>
    </div>
  )
}

export default Success