import { useEffect } from 'react';
import { ChatItem } from 'react-chat-elements'
import parse from "html-react-parser";

const ChatItemComp = ({ item, selected, handleClick, i }) => {
    useEffect(() => {
        const unreadElementParent = document.querySelector(`.chatItem${i}`);
        // console.log("unreadElementParent", unreadElementParent);

        if (unreadElementParent) {
            const unreadElement = unreadElementParent?.querySelector(".rce-citem-body--bottom-status")
            // console.log("unreadElement", unreadElement);

            if (item?.unread > 0) {
                unreadElement?.classList?.add("active");
            }
            else {
                unreadElement?.classList?.remove("active");
            }
        }
    }, [item])


    return (
        <div
            key={i}
            data-serviceid={item?.service?._id}
            data-type={item?.type}
            onClick={handleClick}
            className="hover:bg-[#F9F9F9]"
        >
            {/* {item.type === "internal" && <div className='hover:bg-white'>
                <div className='w-[65px] text-center py-[2px] text-xs mt-1 mr-1 text-white bg-blue-400 ml-auto rounded-md'>Internal</div>
            </div>} */}
            <ChatItem
                title={item?.service?.name ?item?.service?.name :`Admin (Internal)`}
                subtitle={parse(item.subtitle)}
                unread={item?.unread}
                date={new Date(item?.date)}
                className={`chatItem${i} ${item?.clientId === selected ? "bg-gray-400" : "bg-gray-300"}`}
            />
        </div>
    )
}

export default ChatItemComp