import { Link, useNavigate } from 'react-router-dom'
import serviceImg from "@/assets/service.png"
import { format } from 'date-fns';

const MyService = ({ service }) => {
    const navigate = useNavigate();

    return (
        <div className="py-1 grid grid-cols-2 gap-5 max-[560px]:gap-2">
            <div className="">
                <img src={`${import.meta.env.VITE_IMG_URL}/${service?.serviceId?.icon}`} />
                <span className="mt-1 text-gray-500 text-sm">
                    {format(new Date(service?.createdAt),"dd MMM yyyy")}
                </span>
            </div>
            <div className="">
                <h2 className="text-2xl max-[560px]:text-base font-medium text-gray-900 title-font mb-2">
                    {service?.serviceId?.name}
                </h2>

                <button onClick={() => navigate(`/client/my-services/${service?._id}`, { state: service })} className="text-indigo-500 inline-flex max-[560px]:text-sm items-center mt-4">
                    Learn More
                    <svg
                        className="w-4 h-4 ml-2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth="2"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    >
                        <path d="M5 12h14"></path>
                        <path d="M12 5l7 7-7 7"></path>
                    </svg>
                </button>
            </div>
        </div>
    )
}

export default MyService