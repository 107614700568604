import SuccessModal from "@/client/components/successmodal/SuccessModal";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import useGetApiReq from "@/hooks/useGetApiReq";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router-dom";

import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select"
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";

const RequestService = ({ setIsRequestServiceModalOpen, isRequestServiceModalOpen, clientId, getClientDetail }) => {
  const { res, fetchData, isLoading } = usePostApiReq();
  const { res: serviceRes, fetchData: fetchServiceData, isLoading: isServiceLoading } = useGetApiReq();

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [allServices, setAllServices] = useState([]);
  const [serviceId, setServiceId] = useState("");

  const navigate = useNavigate();
  const [requirment, setRequirment] = useState("");

  const [serviceInfo, setServiceInfo] = useState({
    cost: "",
    note: "",
    clientId,
    paymentStatus: ""
  })

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setServiceInfo({ ...serviceInfo, [name]: value });
  };

  console.log("service id",serviceId);


  const getServices = async () => {
    fetchServiceData(`/admin/get-client-unpucharesed-services?clientId=${clientId}`);
  }

  useEffect(() => {
    getServices();
  }, [])


  useEffect(() => {
    if (serviceRes?.status === 200 || serviceRes?.status === 201) {
      console.log("services", serviceRes);
      setAllServices(serviceRes?.data?.services);
    }
  }, [serviceRes])

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!requirment || !serviceInfo.cost || !serviceInfo.note || !serviceInfo.paymentStatus) {
      toast.error("All fields are required");
      return;
    }

    fetchData("/admin/purchase-service-for-client", { requirement: requirment, ...serviceInfo,serviceId });

  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsRequestServiceModalOpen(false);
      getClientDetail();
      setIsSuccessModalOpen(true);
      // navigate("/client/chats", { state: { serviceId } });
    }
  }, [res])

  console.log("servceinfo",serviceInfo);


  return (
    <>
      <Dialog open={isRequestServiceModalOpen} onOpenChange={() => setIsRequestServiceModalOpen(!isRequestServiceModalOpen)}>
        <DialogContent className="sm:max-w-[550px]">
          <DialogHeader>
            <DialogTitle>Add Service</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
            <div className="input-container">
              <Label htmlFor="serviceId">
                Services
              </Label>
              <Select className="col-span-3" name='serviceId' id="serviceId" onValueChange={(value) => setServiceId(value)} value={serviceId}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Service" />
                </SelectTrigger>
                <SelectContent className="max-h-[200px] overflow-y-auto">
                  {allServices?.map((service) => (
                    <SelectItem key={service?._id} value={service?._id}>{service?.name}</SelectItem>
                  ))}
                  {allServices.length === 0 && <p>No services available.</p>}
                </SelectContent>
              </Select>
            </div>
            <div className="input-container">
              <Label htmlFor="cost">
                Cost
              </Label>
              <Input type="number" onChange={handleOnChange} value={serviceInfo.cost} name="cost" id="cost" className="col-span-3" />
            </div>
            <div className="input-container">
              <Label htmlFor="note">
                Note
              </Label>
              <Textarea onChange={handleOnChange} value={serviceInfo.note} name="note" id="note" className="col-span-3" />
            </div>

            <div className="input-container">
              <Label htmlFor="paymentStatus">
                Payment Status
              </Label>
              <Select className="col-span-3" name='paymentStatus' id="paymentStatus" onValueChange={(value) => setServiceInfo({ ...serviceInfo, paymentStatus: value })} value={serviceInfo.paymentStatus}>
                <SelectTrigger>
                  <SelectValue placeholder="Select Payment Status" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="paid">Paid</SelectItem>
                  <SelectItem value="due">Due</SelectItem>
                </SelectContent>
              </Select>
            </div>

            <div className="grid grid-cols-1 items-center gap-4">
              <Label htmlFor="name">
                Requirement
              </Label>
              <ReactQuill theme="snow" value={requirment} onChange={setRequirment} />
            </div>
            <DialogFooter>
              <Button type="submit">Add</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>

      <SuccessModal
        isOpen={isSuccessModalOpen}
        setIsOpen={setIsSuccessModalOpen}
      />
    </>
  )
}

export default RequestService