import { format } from "date-fns";
import { useState } from "react";
import ApproveServiceModal from "../approve-serviceModal/ApproveServiceModal";
import { TableCell, TableRow } from "@/components/ui/table";
import { useNavigate } from "react-router-dom";
import { FaEye } from "react-icons/fa";

const ServiceRequestComp = ({ serviceRequest, getServiceRequests }) => {
    const [isApproveServiceModalOpen, setIsApproveServiceModalOpen] = useState(false);
    const navigate = useNavigate();

    return (
        <>
            <TableRow>
                {serviceRequest?.clientId?.name && <TableCell>{`${serviceRequest?.clientId?.name?.first} ${serviceRequest?.clientId?.name?.last}`}</TableCell>}
                {serviceRequest?.clientId?.phone && <TableCell>{serviceRequest?.clientId?.phone}</TableCell>}
                {serviceRequest?.serviceId?._id && <TableCell onClick={() => navigate(`/admin/categories/${serviceRequest?.serviceId?._id ? serviceRequest?.serviceId?._id : serviceRequest?.serviceId}`)} className="font-medium w-56 cursor-pointer">{serviceRequest?.serviceId?.name}</TableCell>}
                <TableCell>{serviceRequest?.cost ? `₹${serviceRequest?.cost}` : "No Cost Provided"}</TableCell>
                <TableCell>
                    <span className={`px-2 py-[2px] rounded-md ${serviceRequest?.status === "pending" ? "bg-orange-400 text-white" : serviceRequest.status === "quotation-sent" ? "bg-yellow-500 text-white" : serviceRequest.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{serviceRequest.status}</span>
                </TableCell>
                <TableCell>{serviceRequest?.createdAt && format(new Date(serviceRequest.createdAt), 'dd/MM/yyyy')}</TableCell>
                <TableCell className="flex gap-6 items-center">
                    <button disabled={serviceRequest?.status !== "pending"} onClick={() => setIsApproveServiceModalOpen(true)} className="px-2 rounded-md disabled:bg-gray-600 py-[2px] text-white bg-black">Approve</button>
                    <FaEye onClick={() => navigate(`/admin/serviceRequests/${serviceRequest?._id}`)} size={22} cursor={"pointer"} />
                </TableCell>
            </TableRow>

            {isApproveServiceModalOpen &&
                <ApproveServiceModal
                    id={serviceRequest?.clientId?._id ? serviceRequest?.clientId?._id : serviceRequest?.clientId}
                    serviceId={serviceRequest?.serviceId?._id ? serviceRequest?.serviceId?._id : serviceRequest?.serviceId}
                    isApproveServiceModalOpen={isApproveServiceModalOpen}
                    setIsApproveServiceModalOpen={setIsApproveServiceModalOpen}
                    getServiceRequests={getServiceRequests}
                    type={serviceRequest?.type}
                />
            }
        </>
    )
}

export default ServiceRequestComp