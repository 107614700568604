import Chatlist from '@/client/components/chatlist/Chatlist'
import MessageListComp from '@/client/components/message-list-comp/MessageListComp'
import Wrapper from '@/components/wrapper/Wrapper'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useEffect, useState } from 'react'
import "react-chat-elements/dist/main.css"
import { useLocation, useNavigate } from 'react-router-dom'
import noChats from "@/assets/no-chats.jpg"

const Chats = () => {
    const { res, fetchData, isLoading } = useGetApiReq();
    const navigate = useNavigate();

    const { state } = useLocation();
    const [selected, setSelected] = useState(state?.serviceId || "");
    const [isOpen, setIsOpen] = useState(false);

    const [chatList, setChatList] = useState([]);
    const [type, setType] = useState(state?.type || "non-internal");


    const getChats = async () => {
        fetchData("/client/get-chats-list");
    }

    useEffect(() => {
        getChats();
    }, [])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setChatList(res?.data?.chats);
            // setSelected(res?.data?.chats[0]?.service?._id)
            console.log("chat list", res?.data);
        }
    }, [res])

    return (
        <Wrapper>
            <div className="flex w-full h-full relative">
                {chatList.length === 0 ?
                    <div className='flex flex-col max-[770px]:justify-center items-center'>
                        <img className='w-[60%]' src={noChats} alt="noChats" />
                        <p className='mt-2'>Choose a service to chat or enquire</p>
                    </div>
                    :
                    <>
                        <Chatlist
                            setType={setType}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            selected={selected}
                            setSelected={setSelected}
                            chatList={chatList}
                            isLoading={isLoading}
                        />
                        <MessageListComp
                            type={type}
                            isOpen={isOpen}
                            setIsOpen={setIsOpen}
                            serviceId={selected}
                            getChatList={getChats}
                        />
                    </>
                }
            </div>
        </Wrapper>
    )
}

export default Chats