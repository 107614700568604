import AddPartnerModal from '@/admin/components/add-partnerModal/AddPartnerModal'
import AdminWrapper from '@/admin/components/addmin-wrapper/AdminWrapper'
import Partner from '@/admin/components/partner/Partner'
import DataNotFound from '@/components/data-not-found/DataNotFound'
import Spinner from '@/components/spinner/Spinner'
import { Button } from '@/components/ui/button'
import useGetApiReq from '@/hooks/useGetApiReq'
import { useCallback, useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { Input } from '@/components/ui/input'
import { IoIosSearch } from "react-icons/io";
import PartnerFilterModal from '@/admin/components/partner-filterModal/PartnerFilterModal'

const Partners = () => {
    const { res, fetchData, isLoading } = useGetApiReq();

    const [isAddPartnerModalOpen, setIsAddPartnerModalOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [allPartners, setAllPartners] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(1);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);

    const limit = import.meta.env.VITE_LIMIT;

    const getPartners = useCallback(async () => {
        fetchData(`/admin/get-partners?page=${page}&limit=${limit}&searchQuery=${searchInput}`);
    }, [page, fetchData, searchInput])

    useEffect(() => {
        getPartners();
    }, [page, searchInput])

    useEffect(() => {
        if (res?.status === 200 || res?.status === 201) {
            setAllPartners(res?.data?.partners);
            const count = Math.ceil(res?.data?.count / limit)
            setPageCount(count);
        }
    }, [res])


    return (
        <AdminWrapper>
            <section className="text-gray-600 w-full">
                <div className="section-heading-container">
                    <h1 className='section-heading'>Partners</h1>
                    <div className='flex gap-5 items-center'>
                        <div className='flex items-center'>
                            <IoIosSearch className='-mr-6 z-10' />
                            <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Partners" className="pl-8" />
                        </div>
                        <Button onClick={() => setIsAddPartnerModalOpen(true)}>Add Partner</Button>
                        <Button onClick={() => setIsFilterModalOpen(true)}>Download</Button>
                    </div>
                </div>
                <div className='mt-10 gap-10'>
                    <div className="grid grid-cols-2 gap-10">
                        {allPartners.length === 0 && isLoading &&
                            <Spinner size={30} />
                        }

                        {allPartners.length === 0 && !isLoading &&
                            <DataNotFound name={"Partners"} />
                        }
                        {allPartners?.map((partner) => (
                            <Partner
                                key={partner._id}
                                partner={partner}
                            />
                        ))}
                    </div>
                </div>

                {pageCount > 0 &&
                    <div className="mt-10 mb-5">
                        <ReactPaginate
                            previousLabel={'<'}
                            nextLabel={'>'}
                            pageCount={pageCount}
                            onPageChange={(e) => setPage(e.selected + 1)}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    </div>}

            </section>
            {isAddPartnerModalOpen &&
                <AddPartnerModal
                    isAddPartnerModalOpen={isAddPartnerModalOpen}
                    setIsAddPartnerModalOpen={setIsAddPartnerModalOpen}
                    getPartners={getPartners}
                />
            }

            {isFilterModalOpen &&
                <PartnerFilterModal
                    isFilterModalOpen={isFilterModalOpen}
                    setIsFilterModalOpen={setIsFilterModalOpen}
                />
            }
        </AdminWrapper>
    )
}

export default Partners