import Wrapper from "@/components/wrapper/Wrapper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useGetApiReq from "@/hooks/useGetApiReq";
import DataNotFound from "@/components/data-not-found/DataNotFound";
import Spinner from "@/components/spinner/Spinner";
import { format } from "date-fns";
import { IoIosSearch } from "react-icons/io";
import { Input } from "@/components/ui/input";

const PendingReqs = () => {
  const navigate = useNavigate();

  const { res, fetchData, isLoading } = useGetApiReq();

  const [allRequests, setAllRequests] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const getRequests = async () => {
    fetchData(`/client/get-my-requests?search=${searchInput}`);
  };

  useEffect(() => {
    getRequests();
  }, [searchInput]);

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      console.log("my requests", res.data)
      setAllRequests(res?.data?.requests);
    }
  }, [res]);

  return (
    <Wrapper>
      <section className="text-gray-600 body-font">
        <div className='section-heading-container'>
          <h1 className='section-heading'>Requests</h1>
          <div className='flex items-center max-[560px]:w-full'>
            <IoIosSearch className='-mr-6 z-10' />
            <Input onChange={(e) => setSearchInput(e.target.value)} placeholder="Search Request" className="pl-8 max-[560px]:w-full" />
          </div>
        </div>
        <Table className="mt-4">
          <TableHeader className="bg-[#E5E7EB]">
            <TableRow className="text-lg max-[560px]:text-xs">
              <TableHead className="w-48">Service Name</TableHead>
              <TableHead className="w-28">Date</TableHead>
              <TableHead className="w-40">Status</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {allRequests?.map((request) => (
              <TableRow key={request?._id} className="max-[560px]:text-xs">
                <TableCell className="w-48">{request?.serviceId?.name}</TableCell>
                <TableCell className="w-28">{request?.createdAt && format(new Date(request?.createdAt), "dd/MM/yyyy")}</TableCell>
                <TableCell className="w-40">
                  <span className={`px-2 py-[2px] rounded-md ${request?.status === "pending" ? "bg-orange-400 text-white" : request.status === "quotation-sent" ? "bg-yellow-500 text-white" : request.status === "accepted" ? "bg-green-500 text-white" : "bg-blue-400 text-white"}`}>{request.status}</span>
                </TableCell>
                <TableCell>
                  <FaEye onClick={() => navigate(`/client/requests/${request?._id}`)} size={22} cursor={"pointer"} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {allRequests.length === 0 && isLoading &&
          <Spinner size={30} />
        }

        {allRequests.length === 0 && !isLoading &&
          <DataNotFound name={"Requests"} />
        }
      </section>
    </Wrapper>
  );
};

export default PendingReqs;
