import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import usePostApiReq from "@/hooks/usePostApiReq";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

const ConversationModal = ({ setIsConversationModalOpen, isConversationModalOpen, clientIds }) => {
  const { res, fetchData, isLoading } = usePostApiReq();

  const [message, setMessage] = useState("");

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setMessage(value);
  };

  clientIds = clientIds.map((item)=> item.clientId)
  console.log("clientIds", clientIds);

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (!message) {
      toast.error("All fields are required");
      return;
    }

    fetchData("/admin/start-internal-conversation", { message, clientIds });

  };

  useEffect(() => {
    if (res?.status === 200 || res?.status === 201) {
      setIsConversationModalOpen(false);
    }
  }, [res])


  return (
    <Dialog open={isConversationModalOpen} onOpenChange={() => setIsConversationModalOpen(!isConversationModalOpen)}>
      <DialogContent className="sm:max-w-[550px] max-h-[90vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle>Start Internal Conversation</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleOnSubmit} className="grid gap-4 py-4">
          <div className="input-container">
            <Label htmlFor="message">
              Message
            </Label>
            <Textarea placeholder="Enter Message" onChange={handleOnChange} value={message} name="message" id="message" className="col-span-3" />
          </div>
          <DialogFooter>
            <Button type="submit">Send</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ConversationModal